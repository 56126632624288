import {fabric} from 'fabric';
import * as Constants from './Constants';
const SHARED_FABRIC_OPTIONS = {
    borderColor: Constants.LIBERTY_YELLOW,
    cornerColor: Constants.LIBERTY_YELLOW,
    cornerSize: 8,
    hoverCursor: 'default',
    transparentCorners: false
};

/**
 * Create a neighborhood polygon and textbox on a Fabric canvas
 * @param canvas The Fabric canvas that will contain the neighborhood
 * @param neighborhoodName The neighborhood name
 * @param numberOfPoints The number of points in a new neighborhood
 * @param neighborhoodId The database ID of an existing neighborhood
 * @param neighborhoodMapJson The map JSON for an existing neighborhood
 */
function createFabricNeighborhood(canvas, neighborhoodName, numberOfPoints, neighborhoodId, neighborhoodMapJson) {
    const isNew = ! !!neighborhoodId;

    // Use default set of points for new 8-point neighborhoods
    const points = isNew ?
        +numberOfPoints === 8 ?
            [
                {x: 100, y: 0},
                {x: 200, y: 0},
                {x: 300, y: 100},
                {x: 300, y: 200},
                {x: 200, y: 300},
                {x: 100, y: 300},
                {x: 0, y: 200},
                {x: 0, y: 100}
            ] :
            null :
        neighborhoodMapJson.polygon.points;
    let neighborhoodPolygon;
    const polygonOptions = {...SHARED_FABRIC_OPTIONS,
        angle: isNew ? null : neighborhoodMapJson.polygon.angle,
        fill: 'rgba(120, 225, 225, 0.1)', // Liberty teal at 10% opacity
        id: isNew ? 0 : neighborhoodId,
        left: isNew ? null : neighborhoodMapJson.polygon.left,
        neighborhoodName: neighborhoodName,
        objectCaching: false, // Required to keep display current during edits
        scaleX: isNew ? 1 : neighborhoodMapJson.polygon.scaleX,
        scaleY: isNew ? 1 : neighborhoodMapJson.polygon.scaleY,
        selectable: false,
        stroke: Constants.LIBERTY_DARK_TEAL,
        strokeWidth: 3,
        top: isNew ? null : neighborhoodMapJson.polygon.top
    };

    if (points?.length === 8) {
        neighborhoodPolygon = new fabric.Polygon(points, polygonOptions);
    } else {
        neighborhoodPolygon = new fabric.Rect({...polygonOptions,
            height: isNew ? 200 : neighborhoodMapJson.polygon.height,
            width: isNew ? 300 : neighborhoodMapJson.polygon.width
        });
    }

    const textMapJsonOptions = isNew ? null : neighborhoodMapJson.textbox;
    const neighborhoodText = new fabric.Textbox(neighborhoodName, {...SHARED_FABRIC_OPTIONS, ...textMapJsonOptions,
        editable: false,
        editingBorderColor: Constants.LIBERTY_DARK_TEAL,
        fill: Constants.LIBERTY_DARK_TEAL,
        fontFamily: 'Guardian Sans',
        fontSize: 24,
        fontWeight: 'bold',
        id: isNew ? 0 : neighborhoodId,
        neighborhoodName: neighborhoodName,
        originX: 'center',
        originY: 'bottom',
        selectable: false,
        selectionColor: 'rgba(255, 208, 0, 0.2)', // Liberty yellow at 20% opacity
        textAlign: 'center'
    });

    // Intentionally not creating a group so that the textbox can be independently placed
    canvas.add(neighborhoodPolygon);
    if (isNew) canvas.centerObject(neighborhoodPolygon);
    canvas.add(neighborhoodText);
    if (isNew) {
        neighborhoodText.top = neighborhoodPolygon.top;
        neighborhoodText.centerH();
    };

    // Send both behind all seats so they are in the "background"
    canvas.sendToBack(neighborhoodText);
    canvas.sendToBack(neighborhoodPolygon);
}

/**
 * Convert the list of points in a polygon to controls that can be used to change the shape of the polygon
 * @param polygonPoints The list of points (x and y coordinates)
 * @return polygonControls A list of Fabric controls
 */
function createPolygonControls(polygonPoints) {
    let polygonControls;
    const lastPoint = polygonPoints.length - 1;

    // The reducer function takes the arguments: Accumulator, Current Value, Current Index
    // The returned value is assigned to the Accumulator, whose value is remembered across
    // each iteration and ultimately becomes the final resulting value
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reduce
    polygonControls = polygonPoints.reduce((controlList, point, index) => {
        controlList['p' + index] = new fabric.Control({
            actionHandler: polygonControlAnchorWrapper(index > 0 ? index - 1 : lastPoint),
            actionName: 'modifyPolygon',
            pointIndex: index, // Indicates which point the Control is tied to
            positionHandler: polygonControlPositionHandler
        });
        return controlList;
    }, []); // Initial value is empty

    return polygonControls;
}

/**
 * The handler function for when a Control is being moved. This swaps in the new location of the Control
 * in the points list so when we are done modifying the polygon, the points match where the Controls were.
 * There is no Fabric documentation for the actionHandler method of a Control object
 * Other documentation of relevance:
 *    http://fabricjs.com/custom-controls-polygon
 *    http://fabricjs.com/docs/fabric.Polygon.html#toLocalPoint
 * @param {Event} eventData the native mouse event
 * @param {Object} transformData properties of the current transform
 * @param {Number} x x position of the cursor on the canvas
 * @param {Number} y y position of the cursor on the canvas
 * @return {Boolean} true if the action modified the object successfully
 */
function polygonControlActionHandler(eventData, transformData, x, y) {
    const polygon = transformData.target;
    const currentControl = polygon.controls[polygon.__corner];
    const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
    const polygonBaseSize = polygon._getNonTransformedDimensions(); // Calculate object dimensions from its properties (no documentation)
    const size = polygon._getTransformedDimensions(0, 0); // Calculate object bounding box dimensions from its properties scale, skew (no documentation)
    polygon.points[currentControl.pointIndex] = {
        x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
        y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
    };
    return true;
}

/**
 * This wrapper function keeps the polygon in the same position when we change its dimensions
 * by using an anchor point (any of the points that is not the one we are dragging)
 * Other documentation of relevance:
 *    http://fabricjs.com/custom-controls-polygon
 *    http://fabricjs.com/docs/fabric.Polygon.html#setPositionByOrigin
 * @param anchorIndex The index of the anchor point
 * @return {function(*=, *=, *=, *=): *} The action handler function used by the Control
 */
function polygonControlAnchorWrapper(anchorIndex) {
    return function(eventData, transformData, x, y) {
        const fabricObject = transformData.target;
        const anchorAbsolutePoint = fabric.util.transformPoint(
            {
                x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
                y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y
            },
            fabricObject.calcTransformMatrix()
        );

        const isActionSuccessful = polygonControlActionHandler(eventData, transformData, x, y);

        fabricObject._setPositionDimensions({}); // No documentation
        const polygonBaseSize = fabricObject._getNonTransformedDimensions(); // Calculate object dimensions from its properties (no documentation)
        const newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
        const newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;

        // From demo text:
        //    Now newX and newY represent the point position with a range from -0.5 to 0.5 for X and Y.
        //    Fabric supports numeric origins for objects with a range from 0 to 1.
        //    This let us use the relative position as an origin to translate the old absolutePoint we find before.
        fabricObject.setPositionByOrigin(anchorAbsolutePoint, newX + 0.5, newY + 0.5);

        return isActionSuccessful;
    }
}

/**
 * This function locates the Control for both rendering and interaction
 * There is no Fabric documentation for the positionHandler method of a Control object
 * Other documentation of relevance:
 *    http://fabricjs.com/custom-controls-polygon
 *    http://fabricjs.com/docs/fabric.util.html#.transformPoint
 *    http://fabricjs.com/docs/fabric.util.html#.multiplyTransformMatrices
 *    http://fabricjs.com/docs/fabric.Polygon.html#calcTransformMatrix
 * @return {fabric.Point} The canvas position of the Control
 */
function polygonControlPositionHandler(dimensions, finalMatrix, fabricObject) {
    return fabric.util.transformPoint(
        {
            x: (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
            y: (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y)
        },
        fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix(),
            false
        )
    );
}

/**
 * Remove neighborhood polygon and textbox from the canvas given a neighborhood ID
 * @param canvas The Fabric canvas that contains the neighborhood
 * @param neighborhoodId The database ID of the neighborhood
 */
function removeNeighborhoodFromCanvas(canvas, neighborhoodId) {
    const fabricNeighborhoods = canvas.getObjects('polygon').concat(canvas.getObjects('rect'));
    for (let fabricNeighborhood of fabricNeighborhoods) {
        if (fabricNeighborhood.id === neighborhoodId) {
            canvas.remove(fabricNeighborhood);
            canvas.remove(canvas.getObjects('textbox').find(tb => tb.id === neighborhoodId));
            break;
        }
    }
}

export {
    createFabricNeighborhood,
    createPolygonControls,
    removeNeighborhoodFromCanvas
};