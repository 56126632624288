import React, { Fragment, useState, useRef } from 'react';
import AxiosRequest from '../../../utilities/AxiosRequest';

function MapUpload({ floor }) {
    const url = '/api/v1/floors/' + floor.id + '/maps';
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('Choose image to upload');
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const [uploadURL, setUploadURL] = useState('');
    let btnRef = useRef();

    const onChange = e => {
        if (e.target.files.length > 0) {
            setFile(e.target.files[0]);
            setFilename(e.target.files[0].name);
        } else {
            setFilename('Choose file');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await AxiosRequest.post(url, formData);
            setUploadSuccess(true);
            setUploadURL(response.data.url);
            floor.hasMap = true;
        } catch(error) {
            setUploadSuccess(false);
            setUploadURL('');
        }
    };

    return (
        <Fragment>
            <div className='mb-4'><h4>Floor: {floor.floorNickname ? floor.floorNickname : floor.floorNumber}</h4></div>
            <div>
                <form>
                    <div className='custom-file'>
                        <input type='file' className='custom-file-input' id='customFile' onChange={onChange} accept='.jpg,.jpeg,.png,.gif,.bmp'/>
                        <label className='custom-file-label' htmlFor='customFile'>{ filename }</label>
                    </div>
                    <button ref={btnRef} className={'btn btn--primary btn-block mb-4 mt-3 active'} onClick={handleSubmit}>Upload</button>
                </form>

                { uploadSuccess === null ? null : uploadSuccess ?
                    <div>
                        <div className='alert alert-success fade show' role='alert'>{ filename } uploaded successfully.</div>
                        <div className='row mt-5'>
                            <div className='col-md-10 m-auto'>
                                <img style={{width: '100%'}} src={uploadURL} alt={'Map URL'}/>
                            </div>
                        </div>
                    </div>
                    : <div className='alert alert-warning' role='alert'>{ filename } was not uploaded.</div> }
            </div>
        </Fragment>
    )
}

export default MapUpload;
