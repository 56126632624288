export const AVAILABLE_INTERNATIONAL_COUNTRIES =
    [
        { name: 'Australia', code: 'AU' },
        { name: 'Belgium', code: 'BE' },
        { name: 'Bermuda', code: 'BM' },
        { name: 'Brazil', code: 'BR' },
        { name: 'Canada', code: 'CA' },
        { name: 'Chile', code: 'CL' },
        { name: 'China', code: 'AN' },
        { name: 'Colombia', code: 'CO' },
        { name: 'Ecuador', code: 'EC' },
        { name: 'France', code: 'FR' },
        { name: 'Germany', code: 'DE' },
        { name: 'Hong Kong', code: 'HK' },
        { name: 'India', code: 'IN' },
        { name: 'Ireland', code: 'IE' },
        { name: 'Italy', code: 'IT' },
        { name: 'Luxembourg', code: 'LU' },
        { name: 'Malaysia', code: 'MY' },
        { name: 'Mexico', code: 'MX' },
        { name: 'Netherlands', code: 'NL' },
        { name: 'Peru', code: 'PE' },
        { name: 'Portugal', code: 'PT' },
        { name: 'Singapore', code: 'SG' },
        { name: 'Spain', code: 'ES' },
        { name: 'Switzerland', code: 'CH' },
        { name: 'Thailand', code: 'TH' },
        { name: 'United Arab Emirates', code: 'AE' },
        { name: 'United Kingdom', code: 'UK' },
        { name: 'Viet Nam', code: 'VN' }
    ];

// Floor map constants
export const MAP_SCALE_FACTOR = 1.25; // for zooming, modify canvas zoom by 25%
export const MAP_MAX_ZOOM = 4; // 400% max zoom
export const MAP_MIN_ZOOM = 0.3; // 30% min zoom

// Color constants
export const LIBERTY_DARK_GRAY = '#343741';
export const LIBERTY_TEAL = '#78E1E1';
export const LIBERTY_YELLOW = '#FFD000';
export const LIBERTY_YELLOW_ACCESSIBILITY = '#FFFFFF';
export const LIBERTY_LIGHT_GRAY = '#C0BFC0';
export const LIBERTY_LIGHT_GRAY_ACCESSIBILITY = '#000000';
export const LIBERTY_DARK_TEAL = '#06748C';
export const LIBERTY_TEAL_ACCESSIBILITY = '#C0BFC0';
export const LIBERTY_ATMOSPHERIC_WHITE = '#F5F5F5';
export const LIBERTY_BLUE = '#1A1446';
export const LIBERTY_GREEN = '#03AC63';

// Miscellaneous constants
export const FLOOR_MAX_CAPACITY_WARNING = 'This floor is at maximum capacity for the requested range.';
export const HOURS_IN_A_DAY = [
        { name: '00:00', code: 0 },
        { name: '01:00', code: 1 },
        { name: '02:00', code: 2 },
        { name: '03:00', code: 3 },
        { name: '04:00', code: 4 },
        { name: '05:00', code: 5 },
        { name: '06:00', code: 6 },
        { name: '07:00', code: 7 },
        { name: '08:00', code: 8 },
        { name: '09:00', code: 9 },
        { name: '10:00', code: 10 },
        { name: '11:00', code: 11 },
        { name: '12:00', code: 12 },
        { name: '13:00', code: 13 },
        { name: '14:00', code: 14 },
        { name: '15:00', code: 15 },
        { name: '16:00', code: 16 },
        { name: '17:00', code: 17 },
        { name: '18:00', code: 18 },
        { name: '19:00', code: 19 },
        { name: '20:00', code: 20 },
        { name: '21:00', code: 21 },
        { name: '22:00', code: 22 },
        { name: '23:00', code: 23 }
];

export const RESERVATION_TYPE = {
        SPACE   : 'SPACE',
        PARKING : 'PARKING'
};

// Expand as necessary (reference: https://www.w3schools.com/html/html_form_input_types.asp)
export const INPUT_TYPES = {
        EMAIL: 'email',
        PHONE: 'tel',
        TEXT: 'text'
};

export const EXCEPTION_TYPE = {
        PARKING: 'PARKING',
        SPACE: 'SPACE'
};