import React from 'react';
import { Route } from 'react-router-dom';
import { Error } from './Error';

const PrivateRoute = ({ allowed, component: Component, ...rest }) => {

    return (
        <Route {...rest} render={(props) => (
            allowed
                ? <Component {...props} />
                : <Error message='Oops!  That page could not be found or requires Administrator access to view.' />
        )} />
    );
};

export default PrivateRoute;