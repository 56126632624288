import React, {useState, useEffect} from 'react';
import AddAdmin from './AddAdmin';
import AxiosRequest from '../../../utilities/AxiosRequest';
import {Link} from 'react-router-dom';
import Notification from '../../standard/Notification';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import trash_logo from '../../../images/trash_logo.png';

function Admins() {
    const [admins, setAdmins] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const url = '/api/v1/users/admins';

    useEffect(() => {
        resetNotifications();

        const getAdmins = async () => {
            try {
                const response = await AxiosRequest.get(url);
                if (response.status === 200) {
                    setAdmins(response.data);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving admin list');
                }
            } catch (err) {
                setError(true);
                setErrorMessage('An error occurred retrieving admin list');
            }
        };
        getAdmins();
    }, []);
    
    const addAdmin = async (admin) => {
        try {
            resetNotifications();
            const existingAdminIndex = admins.findIndex((a) => a.employeeId.toUpperCase() === admin.employeeId.toUpperCase());
            if (existingAdminIndex < 0) {
                if (admin.name !== '') {
                    const response = await AxiosRequest.post(url, admin);
                    if (response.status === 201) {
                        const newAdmins = [...admins, response.data];
                        setAdmins(newAdmins);
                    } else {
                        setError(true);
                        setErrorMessage('An error occurred adding an administrator');
                    }
                }
            } else {
                if (admins[existingAdminIndex].isOfficeAdmin !== admin.isOfficeAdmin) editAdmin(admins[existingAdminIndex].id, 'office', admin.isOfficeAdmin);
                if (admins[existingAdminIndex].isSuperAdmin !== admin.isSuperAdmin) editAdmin(admins[existingAdminIndex].id, 'super', admin.isSuperAdmin);
                if (admins[existingAdminIndex].isParkingAdmin !== admin.isParkingAdmin) editAdmin(admins[existingAdminIndex].id, 'parking', admin.isParkingAdmin);
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred adding an administrator');
        }
    };

    const removeAdmin = async (id) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(url + '/' + id);
            if (response.status === 204) {
                const newAdmins = admins.filter((admin) => admin.id !== id);
                setAdmins(newAdmins);
            } else {
                setError(true);
                setErrorMessage('An error occurred removing an administrator');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred removing an administrator');
        }
    };
    
    const editAdmin = async (id, type, value) => {
        try {
            resetNotifications();
            const newAdmins = [...admins];
            const editIndex = newAdmins.findIndex(admin => admin.id === id);
            switch (type) {
                case 'super':
                    newAdmins[editIndex].isSuperAdmin = value;
                    break;
                case 'office':
                    newAdmins[editIndex].isOfficeAdmin = value;
                    break;
                case 'parking':
                    newAdmins[editIndex].isParkingAdmin = value;
                    break;
                default:
                    setError(true);
                    setErrorMessage('Administrator type not found');
            }
            const response = await AxiosRequest.put(url + '/' + id, newAdmins[editIndex]);
            if (response.status === 200) {
                setAdmins(newAdmins);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing an administrator');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing an administrator');
        }
    };

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    };

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}><Link to={'/admin'}>Home</Link> &gt; Administrator Management</div>
            <section>
                <h1>Administrator Management</h1>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true
                        }}
                        columns={[
                            {title: 'Name', field: 'name'},
                            {title: 'Employee ID', field: 'employeeId',
                                customFilterAndSearch: (term, admin) => (admin.employeeId).toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.employeeId).localeCompare((b.employeeId))},
                            {title: 'Office Management', field: 'isAdmin', align: 'center',
                                render: (admin) => <input type='checkbox' checked={admin.isOfficeAdmin} onChange={(e) => editAdmin(admin.id, 'office', e.target.checked)}/>,
                                customSort: (a, b) => a.isOfficeAdmin - b.isOfficeAdmin},
                            {title: 'Super Administrator', field: 'isSuper', align: 'center',
                                render: (admin) => <input type='checkbox' checked={admin.isSuperAdmin} onChange={(e) => editAdmin(admin.id, 'super', e.target.checked)}/>,
                                customSort: (a, b) => a.isSuperAdmin - b.isSuperAdmin},
                            {title: 'Parking Administrator', field: 'isParking', align: 'center',
                                render: (admin) => <input type='checkbox' checked={admin.isParkingAdmin} onChange={(e) => editAdmin(admin.id, 'parking', e.target.checked)}/>,
                                customSort: (a, b) => a.isParkingAdmin - b.isParkingAdmin},
                            {title: 'Actions', sorting: false, searchable: false, align: 'center',
                                render: (admin) => <button className='btn logo-btn' onClick={() => removeAdmin(admin.id)}><img alt={'Trash Logo'} src={trash_logo}/></button>}
                        ]}
                        data={admins}
                        components={{
                            Toolbar: (props) => (
                                  <div className={'row p-0 m-0 mt-1'}>
                                      <div className={'col-9 p-0 m-0'}><AddAdmin addAdmin={addAdmin}/></div>
                                      <div className={'col-3 p-0 m-0'}><MTableToolbar {...props} /></div>
                                  </div>
                            ),
                        }}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default Admins;
