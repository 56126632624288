import axios from 'axios';
import MsalAuthToken from './MsalAuthToken';

const instance = axios.create();

/**
 * Retrieve list matching of users for a given assignedSeat
 * @param assignedUserIds array of userId's (N#) to retrieve user information for
 * @returns {Promise<*[]>} sorted list of matching users
 */
async function getAssignedSeatUsers(assignedUserIds) {
    let assignedSeatUsers = [];
    for (const userId of assignedUserIds) {
        const matches = await searchUserByNameOrId(userId);
        if (matches.length > 0) {
            assignedSeatUsers.push(matches[0]);
        }
    }
    return assignedSeatUsers.sort((u1, u2) => u1.displayName.localeCompare(u2.displayName));
}

/**
 * Request information about the current user from Microsoft GraphQL
 * Save desired items in session so the call is only made once
 * @returns {Promise<*>}
 */
async function loadGraphData() {
    const graphToken = await MsalAuthToken.getGraphToken();
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + graphToken;
    const response = await instance.get('https://graph.microsoft.com/beta/me');
    if (response.status === 200) {
        sessionStorage.setItem('employeeId', Number(response.data.employeeId.replace(/n/i, '')));
        sessionStorage.setItem('employeeWorkEmail', response.data.mail);
    } else {
        console.error('An error occurred loading Graph data');
    }
}

/**
 * Retrieve the user's work email address from Graph data
 * @returns {Promise<*>}
 */
async function getEmployeeWorkEmail() {
    let employeeWorkEmail = sessionStorage.getItem('employeeWorkEmail');
    if (!employeeWorkEmail) {
        await loadGraphData();
        employeeWorkEmail = sessionStorage.getItem('employeeWorkEmail');
    }
    return employeeWorkEmail;
}

/**
 * Retrieve the user's ID from Graph data
 * @returns {Promise<*>}
 */
async function getEmployeeId() {
    let employeeId = sessionStorage.getItem('employeeId');
    if (!employeeId) {
        await loadGraphData();
        employeeId = sessionStorage.getItem('employeeId');
    }
    return employeeId;
}

/**
 * Search Graph for users matching the supplied text
 * @param searchText text to search for
 * @returns {Promise<*>} list of matching users
 */
async function searchUserByNameOrId(searchText) {
    const graphToken = await MsalAuthToken.getGraphToken();
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + graphToken;
    // companyName ne null and usageLocation ne null included in Graph filter to remove invalid results
    const response = await instance.get(`https://graph.microsoft.com/beta/users?$count=true&$filter=(startsWith(displayName,'${searchText}') or startsWith(givenName,'${searchText}') or startsWith(surname,'${searchText}') or startsWith(employeeId,'${searchText}') or startsWith(extension_128b6233d06d4df391d7de26c982b64e_msDS_cloudExtensionAttribute4,'${searchText}')) and (employeeId ne null and jobTitle ne null and mail ne null and companyName ne null and usageLocation ne null)&$select=employeeId,displayName,givenName,extension_128b6233d06d4df391d7de26c982b64e_msDS_cloudExtensionAttribute4,surname,jobTitle,mail&$orderby=displayName&ConsistencyLevel=eventual`);
    if (response.status === 200) {
        return response.data.value;
    }
}

const GraphUtils = {
    getAssignedSeatUsers,
    getEmployeeWorkEmail,
    getEmployeeId,
    searchUserByNameOrId
};

export default GraphUtils;