import React from 'react';

function InformationSVG({width, height, title}) {
    return (
        <svg className={'info-icon'} aria-label={title} width={width} height={height} xmlns={'http://www.w3.org/2000/svg'} focusable={'false'} role={'img'} viewBox={'0 0 16 16'}>
            <path d={'M8.0002,0.0498 C12.3832,0.0498 15.9502,3.6158 15.9502,7.9998 C15.9502,12.3838 12.3832,15.9498 8.0002,15.9498 C3.6162,15.9498 0.0502,12.3838 0.0502,7.9998 C0.0502,3.6158 3.6162,0.0498 8.0002,0.0498 Z M8.0002,1.0498 C4.1672,1.0498 1.0502,4.1678 1.0502,7.9998 C1.0502,11.8328 4.1672,14.9498 8.0002,14.9498 C11.8322,14.9498 14.9502,11.8328 14.9502,7.9998 C14.9502,4.1678 11.8322,1.0498 8.0002,1.0498 Z M6.109,12.69 L6.109,11.69 L7.4992,11.6898 L7.5002,7.0124 L6.2582,7.0124 L6.2582,6.0124 L8.0002,6.0124 C8.2762,6.0124 8.5002,6.2364 8.5002,6.5124 L8.5002,11.6898 L9.89,11.69 L9.89,12.69 L6.109,12.69 Z M7.8327,3.1162 C8.2817,3.1162 8.6447,3.4802 8.6447,3.9282 C8.6447,4.3772 8.2817,4.7402 7.8327,4.7402 C7.3837,4.7402 7.0207,4.3772 7.0207,3.9282 C7.0207,3.4802 7.3837,3.1162 7.8327,3.1162 Z'} fill={'var(--textbox-info-border)'}></path>
            <rect width={'100%'} height={'100%'} style={{opacity: 0}}>
                <title>{title}</title>
            </rect>
        </svg>
    );
}

export default InformationSVG;