import React from 'react';
import './Toggle.css';

function Toggle({ label, defaultChecked, toggleValue, isDisabled, title }) {
    return (
        <React.Fragment>
            <label className={'toggle'} title={title}>
                <input type={'checkbox'} checked={defaultChecked} onChange={(e) => toggleValue(e.target.checked)} disabled={isDisabled} />
                <span className={'slider'} />
            </label>
            {label}
        </React.Fragment>
    );
}

export default Toggle;