import React, {useContext} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';

import ireserve_logo from '../../images/ireserve_logo.png';
import UserContext from '../../context/UserContext';

function Header() {
    const adminMatch = useRouteMatch('/admin');
    const adminText = adminMatch ? ' - Administration' : '';
    const user = useContext(UserContext);

    // Close the menu when clicking anywhere outside menu
    window.onmousedown = (event) => {
        const isMenuOpen = document.getElementById('menu').classList.contains('show');
        if (isMenuOpen && !event.target.classList.contains('nav-link') && !event.target.classList.contains('navbar-toggler-icon')) {
            document.getElementById('menu-button').click();
        }
    };

    return (
        <header>
            <nav className={'navbar px-3 flex-nowrap'}>
                <div className={'navbar-brand'}>
                    <h4 className={'my-0'}>
                        <Link to='/'><img alt={'Logo'} src={ireserve_logo} title={'Navigate to the home page'}/></Link>
                        <span className={'align-middle'}>Reservation System{adminText}</span>
                    </h4>
                </div>
                <button id={'menu-button'} className={'navbar-toggler pr-0'} data-toggle={'collapse'} data-target={'#menu'} aria-controls={'menu'} aria-label={'Toggle navigation menu'} aria-expanded={'false'}>
                    <span className={'navbar-toggler-icon'}></span>
                </button>
            </nav>
            <div className={'collapse navbar-collapse'} id={'menu'}>
                <ul className={'navbar-nav'}>
                    <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu'}>
                        <Link to={'/'} className={'nav-link'}>Home</Link>
                    </li>
                    <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu'}>
                        <Link to={'/reservation-dashboard'} className={'nav-link'}>Reservation Dashboard</Link>
                    </li>
                    { user.isOfficeAdmin || user.isSuperAdmin || user.isParkingAdmin
                    ?   <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu'}>
                            <Link to={'/admin'} className={'nav-link'}>Administration</Link>
                        </li>
                    :   null }
                    { user.isSecurityGuard
                        ?   <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu'}>
                            <Link to={'/parking-reservations'} className={'nav-link'}>Parking Reservations</Link>
                        </li>
                        :   null }
                    <li className={'nav-item pl-3 collapsed'} data-toggle={'collapse'} data-target={'#preferences-menu'}>
                        <div className={'nav-link nav-menu'} style={{cursor: 'pointer'}}>Preferences</div>
                    </li>
                    <div id={'preferences-menu'} className={'collapse'}>
                        <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu, #preferences-menu'}>
                            <Link to={'/notification-preferences'} className={'nav-link pl-3'}>Notifications</Link>
                        </li>
                        <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu, #preferences-menu'}>
                            <Link to={'/group-preferences'} className={'nav-link pl-3'}>Reservation Groups</Link>
                        </li>
                    </div>
                    <li className={'nav-item pl-3 collapsed'} data-toggle={'collapse'} data-target={'#support-menu'}>
                        <div className={'nav-link nav-menu'} style={{cursor: 'pointer'}}>Support</div>
                    </li>
                    <div id={'support-menu'} className={'collapse'}>
                        <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu, #support-menu'}>
                            <a href='https://forge.lmig.com/issues/servicedesk/customer/portal/1228/create/11556' target='_blank' className='nav-link pl-3' rel='noopener noreferrer'>Report System Issue/Provide Feedback</a>
                        </li>
                        <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu, #support-menu'}>
                            <a href='https://libertymutual.sharepoint.com/:w:/s/Hotelr982/EYoChzha-mZAmKE8DLwuGkgBYEUQwI4kz9e58jCSNc7rEQ?e=cVCug4' target='_blank' className='nav-link pl-3' rel='noopener noreferrer'>User Guide</a>
                        </li>
                        <li className={'nav-item pl-3'} data-toggle={'collapse'} data-target={'#menu, #support-menu'}>
                            <a href='https://us.myliberty.lmig.com/work-life/workplace/flex-space' target='_blank' className='nav-link pl-3' rel='noopener noreferrer'>Flex Space Info</a>
                        </li>
                    </div>
                </ul>
            </div>
        </header>
    )
}

export default Header;