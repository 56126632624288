import AxiosRequest from './AxiosRequest';

async function getPreferences() {
    try {
        const response = await AxiosRequest.get(`/api/v1/users/current/preferences`);
        if (response.status === 200) {
            return {
                status: 200,
                personalEmail: response.data.personalEmail,
                notifyWorkEmail: response.data.notifyWorkEmail,
                notifyPersonalEmail: response.data.notifyPersonalEmail,
                userGroups: response.data.userGroups,
                groupAllOptIn: response.data.groupAllOptIn
            };
        } else {
            return {
                status: response.status,
                message: 'An error occurred retrieving preferences'
            };
        }
    } catch (err) {
        return err?.response?.data?.message || 'An unexpected error occurred retrieving preferences';
    }
}

async function setGroupOptIn(group, userId, optedInValue, userGroups) {
    let newUserGroupMember = group.groupMembers.find(m => m.memberId === userId);
    newUserGroupMember.hasOptedIn = optedInValue;
    group.groupMembers.splice(group.groupMembers.findIndex(m => m.memberId === userId), 1, newUserGroupMember);
    let newUserGroups = userGroups;
    newUserGroups.splice(userGroups.findIndex(g => g.groupId === group.groupId), 1, group);
    return newUserGroups;
}

async function savePreferences(preference) {
    try {
        const response = await AxiosRequest.put(`/api/v1/users/current/preferences`, preference);
        if (response.status === 200) {
            return '';
        } else {
            return 'An error occurred saving preferences';
        }
    } catch (err) {
        return err?.response?.data?.message || 'An unexpected error occurred saving preferences';
    }
}

export {
    getPreferences,
    setGroupOptIn,
    savePreferences
}
