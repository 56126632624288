import React, {useEffect, useRef, useState} from 'react';
import trash_logo from '../../images/trash_logo.png';
import pen_logo from '../../images/pen_rgb.png';
import missing_vehicle_logo from '../../images/caution.png';
import AxiosRequest from '../../utilities/AxiosRequest';
import {useLocation} from 'react-router-dom';
import Notification from '../standard/Notification';
import * as DateUtils from '../../utilities/DateUtils';
import {RESERVATION_TYPE} from '../../utilities/Constants'
import {Link} from 'react-router-dom';
import {createParkingCalendarInvite, createSpaceCalendarInvite} from '../../utilities/EmailCalendarUtils';
import download_invite from '../../images/confirmed_date.png';

function ReservationList(props) {
    const [canceledReservation, setCanceledReservation] = useState(null);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let currentUrl = useRef(useLocation());
    let reservations = props.reservations;

    const cancelReservation = async (reservationId, isParking) => {
        resetNotifications();

        try {
            let reservationsUrl = `/api/v1/users/${props.user.id}/reservations/${reservationId}`;
            reservationsUrl = isParking ? reservationsUrl + '?type=parking' : reservationsUrl;
            const response = await AxiosRequest.destroy(reservationsUrl);
            if (response.status === 200) {
                const newReservations = reservations;
                newReservations.splice(newReservations.findIndex(r => r.id === response.data.id && r.reservationType === response.data.reservationType), 1);
                props.onChange(newReservations);
                setCanceledReservation(response.data);
                setCancelSuccess(true);
            }
        } catch (error) {
            setError(true);
            setErrorMessage(error.response.data.message || error.response.data.error);
        }
    };

    // This will handle a cancel from the reservation email
    useEffect(() => {
        if (currentUrl.current.pathname.includes('cancel-reservation') && currentUrl.current.search && props.user.id && reservations.length > 0) {
            // The query string will look like this: ?id=8
            // If it is a parking reservation, query string will look like this: ?id=8&isParking=true
            const urlParams = new URLSearchParams(currentUrl.current.search);
            const reservationId = urlParams.get('id');
            const isParking = urlParams.get('isParking');
            cancelReservation(reservationId, !!isParking);
            currentUrl.current.search = '';
        }
    });

    const resetNotifications = () => {
        setCancelSuccess(false);
        setError(false);
        setErrorMessage('');
    };

    const sortReservations = (reservations) => {
        const sortOrder = [RESERVATION_TYPE.SPACE, RESERVATION_TYPE.PARKING];
        return reservations.sort((r1, r2) => {
            return (new Date(r1.reservationDate) - new Date(r2.reservationDate)) ||
                (sortOrder.indexOf(r1.reservationType) - sortOrder.indexOf(r2.reservationType))
        });
    }

    return (
        <React.Fragment>
            <Notification id={'cancel-success-notification'} notificationType={'success'} dismissable={true} dismissText={'Click to close'} active={cancelSuccess} handleDismiss={resetNotifications}>Reservation canceled {canceledReservation ? 'for ' + DateUtils.getLocaleDateFromString(canceledReservation.reservationDate) : ''}</Notification>
            <Notification id={'error-notification'} notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <h3>{props.listTitle}</h3>
                {reservations.length > 0 ? sortReservations(reservations).map(reservation => (
                    <div key={`${reservation.reservationType}_${reservation.id}`} className={'row no-gutters p-2 my-3'} style={{border: '1px solid var(--light-border)', borderRadius: '5px'}}>
                    <div className={'col col-sm-3 pr-2'}>
                        <h6 className={'small text-muted'}>Date</h6>
                        {DateUtils.getLocaleDateFromString(reservation.reservationDate)}{reservation.reservationType === RESERVATION_TYPE.SPACE ? ' (' + DateUtils.displayReservationHours(reservation) + ')' : ''}
                    </div>
                    <div className={'col col-sm-4 pr-2'}>
                        <h6 className={'small text-muted'}>Office</h6>
                        {reservation.reservationType === RESERVATION_TYPE.SPACE ? reservation.officeName : 'Boston, MA'}
                    </div>
                    <div className={'col col-sm-2 pr-2'}>
                        <h6 className={'small text-muted'}>Space</h6>
                        {reservation.reservationType === RESERVATION_TYPE.SPACE ? reservation.spaceName : reservation.isOvernight ? 'Overnight Parking' : 'Parking'}
                        {reservation.reservationType === RESERVATION_TYPE.PARKING && reservation.isMissingVehicleInfo ? <img alt={'Missing vehicle information'} title={'Missing vehicle information'} src={missing_vehicle_logo} className={'ml-1 mt-n1'} style={{height: '1rem'}}/> : null}
                    </div>
                    <div className={'col col-sm-3 d-flex flex-column flex-sm-row align-items-center justify-content-center'}>
                        <div className={'row pb-1 pb-sm-0'}>
                            {reservation.reservationType === RESERVATION_TYPE.PARKING ? <Link to={{pathname: '/reserve-parking', state: {reservation: reservation}}} className={'btn logo-btn'}><img alt={'Edit reservation'} title={'Edit reservation'} src={pen_logo} /></Link> : <div className={'d-none d-sm-block'} style={{width: '29px'}}/>}
                            <button className={'btn logo-btn d-none d-sm-block'} onClick={() => { reservation.reservationType === RESERVATION_TYPE.SPACE ? createSpaceCalendarInvite(reservation, reservation.floorDto.displayName) : createParkingCalendarInvite(reservation, 'Boston, MA') }}><img alt={'Download calendar invite'} title={'Download calendar invite'} src={download_invite}/></button>
                            <button className={'btn logo-btn'} onClick={() => {if (window.confirm('Are you sure you would like to cancel this reservation?')) cancelReservation(reservation.id, reservation.reservationType === RESERVATION_TYPE.PARKING)}}><img alt={'Cancel reservation'} title={'Cancel reservation'} src={trash_logo}/></button>
                        </div>
                        <div className={'row d-block d-sm-none'}>
                            <button className={'btn logo-btn'} onClick={() => {reservation.reservationType === RESERVATION_TYPE.SPACE ? createSpaceCalendarInvite(reservation, reservation.floorDto.displayName) : createParkingCalendarInvite(reservation, 'Boston, MA')}}><img alt={'Download calendar invite'} title={'Download calendar invite'} src={download_invite}/></button>
                        </div>
                    </div>
                </div>
            )) : <i>You have no {props.listTitle.toLowerCase()}</i>
            }
        </React.Fragment>
    );
}

export default ReservationList;