import React, { useState } from 'react';

function AddFloor({ office, addFloor }) {
    const [form, setState] = useState({
        floorNumber: '',
        isClientActive: false
    });

    const handleSubmit = e => {
        e.preventDefault();
        let isActive = form.isClientActive;
        const newFloor = {
            floorNumber: form.floorNumber,
            isClientActive: isActive,
            officeId: office.id
        };
        addFloor(newFloor);
        setState({
            floorNumber: '',
            isClientActive: false
        });
        document.getElementById('floor-form').reset();
        document.getElementById('floor-active').checked = false;
    }

    const updateField = e => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    return (
        <form className='form' id='floor-form' onSubmit={handleSubmit}>
            <input required type='text' id='floor-number' name='floorNumber'
                   value={form.floorNumber} placeholder='Enter Floor Number' onChange={updateField} />
            <input type='checkbox' id='floor-active' name='isClientActive' value='true' onChange={updateField} className={'ml-2'}/><label>Active</label>
            <button className='ml-4 mb-1 btn btn--primary' type='submit' value='Submit'>Create Floor</button>
        </form>
    )
}

export default AddFloor;