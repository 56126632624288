import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import Textbox from '../standard/Textbox';
import * as Constants from '../../utilities/Constants';
import AxiosRequest from '../../utilities/AxiosRequest';
import Notification from '../standard/Notification';
import Group from './Group';
import InfoSVG from '../../images/InformationSVG';
import {Tooltip, OverlayTrigger, Tabs, Tab} from 'react-bootstrap';
import * as PreferenceUtils from '../../utilities/PreferenceUtils';

function MyGroups({user}) {
    const [allUserGroups, setAllUserGroups] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [groupAllOptIn, setGroupAllOptIn] = useState(false);
    const helpText = 'Create group(s) to identify when your peers are in the office so you can collaborate with them. Each member can opt in to share their reservation information with you and other members of your group.';
    const [memberGroups, setMemberGroups] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [ownedGroups, setOwnedGroups] = useState([]);
    const url = '/api/v1/users';

    const addGroupMember = async (groupId, member) => {
        try {
            resetNotifications();
            let newGroups = ownedGroups;
            let groupIndex = newGroups.findIndex(g => g.groupId === groupId);
            let groupUpdate = newGroups[groupIndex];
            // Component only shows for owner, if member being added is the user then they shouldn't be added as they are the owner
            if (member.employeeId.toLowerCase() === user.employeeId.toLowerCase()) {
                setError(true);
                setErrorMessage('User cannot be added because they are the group owner');
            } else if (groupUpdate.groupMembers.some(m => m.employeeId.toLowerCase() === member.employeeId.toLowerCase())) {
                setError(true);
                setErrorMessage('User cannot be added because they are already a group member');
            } else {
                const response = await AxiosRequest.post(`${url}/${user.id}/groups/${groupId}/members`, member);
                if (response.status === 201) {
                    groupUpdate.groupMembers.push(response.data);
                    newGroups[groupIndex] = groupUpdate;
                    newGroups.sort((a, b) => b.groupId - a.groupId);
                    newGroups = [...newGroups];
                    setOwnedGroups(newGroups);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred adding a group member');
                }
            }
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.message);
        }
    };

    const removeGroup = async (groupId) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(`${url}/${user.id}/groups/${groupId}`);
            if (response.status === 204) {
                const newGroups = ownedGroups.filter(g => g.groupId !== groupId);
                setOwnedGroups(newGroups);
            } else {
                setError(true);
                setErrorMessage('An error occurred deleting a group');
            }
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.message);
        }
    };

    const removeGroupMember = async (groupId, memberId) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(`${url}/${user.id}/groups/${groupId}/members/${memberId}`);
            if (response.status === 204) {
                let newGroups = ownedGroups;
                let groupIndex = newGroups.findIndex(g => g.groupId === groupId);
                let groupUpdate = newGroups[groupIndex];
                let updateGroupMembers = groupUpdate.groupMembers.filter((member) => member.memberId !== memberId);
                groupUpdate.groupMembers = updateGroupMembers;
                newGroups[groupIndex] = groupUpdate;
                newGroups.sort((a, b) => b.groupId - a.groupId);
                newGroups = [...newGroups];
                setOwnedGroups(newGroups);
            } else {
                setError(true);
                setErrorMessage('An error occurred removing a group member');
            }
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.message);
        }
    };

    useEffect(() => {
        resetNotifications();
        const getPreferences = async () => {
            try {
                if (user && user.id) {
                    const response = await PreferenceUtils.getPreferences();
                    if (response.status === 200) {
                        let groups = response.userGroups;
                        setAllUserGroups(groups);
                        let memberGroupsResponse = groups.filter(g => g.ownerId !== user.id);
                        // add owner as a member of the group (so they show up in the table)
                        memberGroupsResponse.forEach(g => g.groupMembers.push({memberName: g.ownerName, memberId: g.ownerId, hasOptedIn: true, groupId: g.groupId}));
                        setMemberGroups(memberGroupsResponse);
                        setOwnedGroups(groups.filter(g => g.ownerId === user.id).sort((a, b) => b.groupId - a.groupId));
                        setGroupAllOptIn(response.groupAllOptIn);
                    } else {
                        setError(true);
                        setErrorMessage(response.message);
                    }
                }
            } catch (err) {
                setError(true);
                setErrorMessage(err.response.data.message || err.response.data.error);
            }
        };
        getPreferences();
    }, [user]);

    const handleNewGroupSubmit = async (e) => {
        try {
            e.preventDefault();
            const newGroup = {
                groupName: newGroupName
            };
            const response = await AxiosRequest.post(`${url}/${user.id}/groups`, newGroup);
            if (response.status === 201) {
                const newGroups = [response.data, ...ownedGroups];
                setOwnedGroups(newGroups);
                // Remove focus from Textbox prior to clearing
                const submitButton = document.getElementById('group-submit-button');
                submitButton.focus();
                const clearButton = document.getElementById('group-name-clear');
                clearButton.click();
            } else {
                setError(true);
                setErrorMessage('An error occurred adding a new group');
            }
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.message);
        }
    };

    const savePreferences = async (preference) => {
        resetNotifications();
        const response = await PreferenceUtils.savePreferences(preference);
        if (response.length > 0) {
            setError(true);
            setErrorMessage(response);
            return false;
        }
        return true;
    };

    const saveGroupOptIn = async (toggleValue, group, setSaveSuccess) => {
        const newUserGroups = await PreferenceUtils.setGroupOptIn(group, user.id, toggleValue, allUserGroups);
        setAllUserGroups(newUserGroups);
        const saveSuccess = await savePreferences({
            userId: user.id,
            userGroups: newUserGroups,
            userGroupsDirty: true
        });
        setSaveSuccess(saveSuccess);
    };

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    };

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div id={'home'} className={'mx-auto'}>
                <div className={'greeting'}>
                    <Link to={'/'}>Home</Link>&nbsp;&gt;&nbsp;Reservation Groups
                </div>
                <section>
                    <div className={'row pl-3'}>
                        <h4>Reservation Groups</h4>
                        <OverlayTrigger placement={'top'} delay={{show: 250, hide: 400}} overlay={<Tooltip id={'info-tooltip'}>{helpText}</Tooltip>}>
                            <button className={'pl-1 btn btn-popover'}><InfoSVG height={14}/></button>
                        </OverlayTrigger>
                    </div>
                    <Tabs defaultActiveKey={'owner'} className={'my-2'}>
                        <Tab eventKey={'owner'} title={'Group Management'}>
                            <div className={'container'}>
                                <form className={'form pb-0'} id={'new-group-form'} onSubmit={handleNewGroupSubmit}>
                                    <div className={'row pt-1 align-items-center'}>
                                        <div className={'col-6 px-0'}>
                                            <Textbox id={'group-name'} isRequired={true} textboxType={Constants.INPUT_TYPES.TEXT} placeholderText={'Enter group name...'} isRequred={false} maxLength={30} changeMethod={(e) => setNewGroupName(e.target.value)} clearMethod={() => setNewGroupName('')} allowAll={true}/>
                                        </div>
                                        <div className={'col-6'}>
                                            <button id='group-submit-button' className='ml-0 ml-sm-4 mb-1 btn btn--primary' type='submit' value='Submit'>Add New Group</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {ownedGroups.length > 0 ? ownedGroups.map((group, index) => (
                                <Group key={group.groupId} addGroupMember={addGroupMember} removeGroup={removeGroup} removeGroupMember={removeGroupMember} group={group} owner={true} index={index} startCollapsed={ownedGroups.length !== 1 && group.groupMembers.length !== 0} user={user}/>
                            )) : <span>You have not created any groups yet.</span>}
                        </Tab>
                        <Tab eventKey={'Member'}
                             title={<span>
                                 Group Membership
                                 <OverlayTrigger placement={'top'} delay={{show: 250, hide: 400}} overlay={<Tooltip id={'info-tooltip'}>If the opt-in toggle is disabled, you are opted in to all groups. Manage this setting from your Notifications page.</Tooltip>}>
                                     <button className={'pl-1 btn btn-popover'}><InfoSVG height={14}/></button>
                                 </OverlayTrigger>
                            </span>}
                        >
                            {memberGroups.length > 0 ? memberGroups.map((group, index) => (
                                <Group key={group.groupId} addGroupMember={addGroupMember} removeGroup={removeGroup} removeGroupMember={removeGroupMember} group={group} owner={false} index={index} startCollapsed={memberGroups.length !== 1} user={user} userGroupAllOptIn={groupAllOptIn} saveGroupOptIn={saveGroupOptIn}/>
                            )) : <span>You are not a member of any groups yet.</span>}
                        </Tab>
                    </Tabs>
                </section>
                <div className={'section-divider'}></div>
            </div>
        </React.Fragment>
    );
}

export default MyGroups;