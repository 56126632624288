import React, { useState } from 'react';
import UserSearch from '../../standard/UserSearch';

function AddSecurityGuard({ addUser }) {
    const [form, setState] = useState({
        name: '',
        employeeId: '',
        email: '',
        isSecurityGuard: true
    });

    const handleSubmit = e => {
        e.preventDefault();

        let isSecurityGuard = form.isSecurityGuard;
        const newUser = {
            name: form.name,
            employeeId: form.employeeId,
            email: form.email,
            isSecurityGuard: Boolean(isSecurityGuard)
        };
        addUser(newUser);
        setState({
            name: '',
            employeeId: '',
            email: '',
            isSecurityGuard: true
        });
        document.getElementById('admin-form').reset();
    };

    const setUserName = (selectedUsers) => {
        if (selectedUsers.length > 0) {
            setState({
                ...form,
                name: selectedUsers[0].displayName,
                employeeId: selectedUsers[0].employeeId,
                email: selectedUsers[0].mail
            });
        } else {
            setState({
                ...form,
                name: '',
                employeeId: '',
                email: ''
            });
        }
    };

    return (
        <React.Fragment>
            <div className={'container ml-0'}>
                <form className='form' id='admin-form' onSubmit={handleSubmit}>
                    <div className={'row'}>
                        <div className={'col-5'}>
                            <UserSearch placeholderText={'Enter security guard\'s last name or ID...'} setSearchResults={setUserName}/>
                        </div>
                        <div className={'col'}>
                            <input type='hidden' id='admin-name' name='name' />
                            <input type='hidden' id='employeeId' name='employeeId' />
                            <input type='hidden' id='email' name='email' />
                            <input type='submit' className='ml-3 btn btn--primary' value='Add Security Guard'/>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default AddSecurityGuard;