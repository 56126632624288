import React, { useState } from 'react';
import UserSearch from '../../standard/UserSearch';

function AddAdmin({ addAdmin }) {
    const [form, setState] = useState({
        name: '',
        employeeId: '',
        email: '',
        isOfficeAdmin: false,
        isSuperAdmin: false,
        isParkingAdmin: false
    });

    const handleSubmit = e => {
        e.preventDefault();

        let isOfficeAdmin = form.isOfficeAdmin;
        let isSuperAdmin = form.isSuperAdmin;
        let isParkingAdmin = form.isParkingAdmin;
        const newAdmin = {
            name: form.name,
            employeeId: form.employeeId,
            email: form.email,
            isOfficeAdmin: Boolean(isOfficeAdmin),
            isSuperAdmin: Boolean(isSuperAdmin),
            isParkingAdmin: Boolean(isParkingAdmin)
        };
        addAdmin(newAdmin);
        setState({
            name: '',
            employeeId: '',
            email: '',
            isOfficeAdmin: false,
            isSuperAdmin: false,
            isParkingAdmin: false
        });
        document.getElementById('admin-form').reset();
        document.getElementById('isOfficeAdmin').checked = false;
        document.getElementById('isSuperAdmin').checked = false;
        document.getElementById('isParkingAdmin').checked = false;
    };

    const updateField = e => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const setAdminName = (selectedAdmins) => {
        if (selectedAdmins.length > 0) {
            setState({
                ...form,
                name: selectedAdmins[0].displayName,
                employeeId: selectedAdmins[0].employeeId,
                email: selectedAdmins[0].mail
            });
        } else {
            setState({
               ...form,
               name: '',
               employeeId: '',
               email: ''
            });
        }
    }

    return (
        <React.Fragment>
            <div className={'container ml-0'}>
                <form className='form' id='admin-form' onSubmit={handleSubmit}>
                    <div className={'row'}>
                        <div className={'col-5'}>
                            <UserSearch placeholderText={'Enter employee\'s last name or ID...'} setSearchResults={setAdminName}/>
                        </div>
                        <div className={'col'}>
                            <input type='hidden' id='admin-name' name='name' />
                            <input type='hidden' id='employeeId' name='employeeId' />
                            <input type='hidden' id='email' name='email' />
                            <input type='checkbox' id='isOfficeAdmin' name='isOfficeAdmin' value='true' onChange={updateField}/><label>Office</label>
                            <input type='checkbox' id='isSuperAdmin' name='isSuperAdmin' value='true' onChange={updateField} className={'ml-2'}/><label>Super</label>
                            <input type='checkbox' id='isParkingAdmin' name='isParkingAdmin' value='true' onChange={updateField} className={'ml-2'}/><label>Parking</label>
                            <input type='submit' className='ml-3 btn btn--primary' value='Add Admin'/>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default AddAdmin;