import React from 'react';

import lm_logo from '../../images/liberty_logo_white.png';
import fb_logo from '../../images/facebook_teal.png';
import tw_logo from '../../images/twitter_teal.png';
import li_logo from '../../images/linkedin_teal.png';
import {useRouteMatch} from 'react-router-dom';

function Footer() {
    const adminMatch = useRouteMatch('/admin');

    return (
        <footer className={'mt-auto pt-0' + (adminMatch ? ' px-sm-2' : ' pt-sm-3')}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-sm'}>
                        <img className={'liberty'} alt={'Liberty Mutual Logo'} src={lm_logo} />
                    </div>
                    <div className={'col-sm' + (adminMatch ? ' d-none' : '')}>
                        <div id={'social-media'}>
                            <p>Connect with us</p>
                            <p>Stay up-to-date with the latest news</p>
                            <img alt={'Facebook Logo'} src={fb_logo} />
                            <img alt={'Twitter Logo'} src={tw_logo} />
                            <img alt={'LinkedIn Logo'} src={li_logo} />
                        </div>
                    </div>
                </div>
            </div>
            <div id={'language'} className={'text-center' + (adminMatch ? ' d-none' : '')}>
                <strong><u>English</u></strong>
            </div>
        </footer>
    )
}

export default Footer;