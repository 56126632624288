import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import AxiosRequest from '../../../utilities/AxiosRequest';
import Notification from '../../standard/Notification';
import AddFloor from './AddFloor';
import MapUpload from './MapUpload';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import file_image from '../../../images/file_image.png';
import trash_logo from '../../../images/trash_logo.png';
import upload_logo from '../../../images/upload_logo.png';

function Floors(props) {
    const [currentOffice, setCurrentOffice] = useState(props.location.state.office);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [floors, setFloors] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const url = '/api/v1/floors';

    const getFloors = async () => {
        try {
            setFloors([]);
            const response = await AxiosRequest.get('/api/v1/offices/' + props.location.state.office.id + '/floors');
            if (response.status === 200) {
                setFloors(response.data);
            } else {
                setError(true);
                setErrorMessage('An error occurred retrieving floor list');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred retrieving floor list');
        }
    };

    const getGradeList = async () => {
        try {
            const response = await AxiosRequest.get('/api/v1/users/grades');
            if (response.status === 200) {
                setGradeList(response.data);
            } else {
                setError(true);
                setErrorMessage('An error occurred retrieving grade list');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred retrieving grade list');
        }
    };

    // file upload modal
    const [floorForMap, setFloorForMap] = useState(null);
    const [show, setShow] = useState(false);

    const initModal = (floor) => setFloorForMap(floor);

    const showModal = () => {
        if(floorForMap != null) {
            setShow(true);
        }
    };

    const handleClose = () => {
        setShow(false);
        getFloors();
    };

    useEffect(() => {
        resetNotifications();
        setCurrentOffice(props.location.state.office);
        getGradeList();
        getFloors();
        showModal();
        // eslint-disable-next-line
    }, [props, floorForMap]);

    const editFloorActive = async (id) => {
        try {
            resetNotifications();
            const newFloors = [...floors];
            const editIndex = newFloors.findIndex((floor => floor.id === id));
            newFloors[editIndex].isClientActive = !newFloors[editIndex].isClientActive;
            const response = await AxiosRequest.put(url + '/' + id, newFloors[editIndex]);
            if (response.status === 200) {
                setFloors(newFloors);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing a floor');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a floor');
        }
    };

    const editFloorNickname = async (id, nickname) => {
        try {
            resetNotifications();
            const newFloors = [...floors];
            const editIndex = newFloors.findIndex((floor => floor.id === id));
            newFloors[editIndex].nickname = nickname;
            const response = await AxiosRequest.put(url + '/' + id, newFloors[editIndex]);
            if (response.status === 200) {
                setFloors(newFloors);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing a floor');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a floor');
        }
    };

    const editFloorGradeList = async (id, gradeList) => {
        try {
            resetNotifications();
            const currentFloors = [...floors];
            const newFloors = [...floors];
            const editIndex = newFloors.findIndex((floor => floor.id === id));
            newFloors[editIndex].gradeList = Array.from(gradeList.selectedOptions, option => option.value);
            // moving the set to before the await resolved the jumpy selecting
            setFloors(newFloors);
            const response = await AxiosRequest.put(url + '/' + id, newFloors[editIndex]);
            if (response.status === 200) {
                // do nothing
            } else {
                setFloors(currentFloors);
                setError(true);
                setErrorMessage('An error occurred editing a floor');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a floor');
        }
    };

    const editEligibilityExpiration = async (id, eligibilityExpirationDay) => {
        try {
            resetNotifications();
            const currentFloors = [...floors];
            const newFloors = [...floors];
            const editIndex = newFloors.findIndex((floor => floor.id === id));
            newFloors[editIndex].spaceEligibilityExpiration = eligibilityExpirationDay;
            setFloors(newFloors);
            const response = await AxiosRequest.put(url + '/' + id, newFloors[editIndex]);
            if (response.status === 200) {
                // do nothing
            } else {
                setFloors(currentFloors);
                setError(true);
                setErrorMessage('An error occurred editing a floor');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a floor');
        }
    };

    const addFloor = async (floor) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.post(url, floor);
            if (response.status === 201) {
                const newFloors = [...floors, response.data];
                setFloors(newFloors);
            } else {
                setError(true);
                setErrorMessage('An error occurred adding a new floor');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred adding a new floor');
        }
    };
    
    const removeFloor = async (id) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(url + '/' + id);
            if (response.status === 204) {
                const newFloors = floors.filter((floor) => floor.id !== id);
                setFloors(newFloors);
            } else {
                setError(true);
                setErrorMessage('An error occurred removing a floor');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred removing a floor');
        }
    };

    const removeMap = async (id) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(url + '/' + id + '/maps');
            if (response.status === 204) {
                const newFloors = [...floors];
                const editIndex = newFloors.findIndex((floor => floor.id === id));
                newFloors[editIndex].hasMap = false;
                setFloors(newFloors);
            } else {
                setError(true);
                setErrorMessage('An error occurred removing a floor map');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred removing a floor map');
        }
    };

    const seatButtonText = (floor) => {
        return 'Manage ' + floor.spaceCount + ' Seat' + (floor.spaceCount === 1 ? '' : 's');
    }

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    }

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/admin'}>Home</Link> &gt; <Link to={'/admin/manage-office-choice'}>Offices</Link>
                &nbsp;&gt;&nbsp;
                <Link to={{pathname: '/admin/manage-offices', state: {isIntl: currentOffice.officeCountry === 'US' ? false : true}}}>
                    {currentOffice.officeCountry === 'US' ? 'US' : 'International'} Offices
                </Link>
                &nbsp;&gt;&nbsp;
                {currentOffice.nickname ? currentOffice.nickname : currentOffice.officeName} ({currentOffice.officeNumber})
            </div>
            <section>
                <h1>Floor Management</h1>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true,
                        }}
                        columns={[
                            {title: <label>Name</label>,
                                render: (floor) => <input type='text' size='35' defaultValue={floor.nickname ? floor.nickname : floor.floorNumber}
                                                          onBlur={(e) => editFloorNickname(floor.id, e.target.value)} aria-label={'Name'}/>,
                                customFilterAndSearch: (term, floor) => (floor.nickname !== null ? floor.nickname : '').toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.nickname ? a.nickname : a.floorNumber).toUpperCase().localeCompare((b.nickname ? b.nickname : b.floorNumber).toUpperCase())},
                            {title: <label>Number</label>, field: 'floorNumber'},
                            {title: <label>Map</label>, searchable: false, align: 'center',
                                render: (floor) =>
                                    floor.hasMap
                                        ?
                                            <><Link to={{pathname: '/admin/floor-map', state: {office: currentOffice, floor: floor}}}><button className='btn logo-btn'><img alt={'View Map'} src={file_image}/></button></Link>
                                            <button className='btn logo-btn' onClick={() => removeMap(floor.id)}><img alt={'Trash Logo'} src={trash_logo}/></button></>
                                        :
                                        <button className='btn logo-btn' onClick={() => initModal(floor)}><img alt={'Upload Map'} src={upload_logo}/></button>,
                                customSort: (a, b) => a.hasMap - b.hasMap
                            },
                            {title: <label>Active</label>, searchable: false, align: 'center',
                                render: (floor) => <input type='checkbox' defaultChecked={floor.isClientActive} onChange={() => editFloorActive(floor.id)} aria-label={'Active'}/>,
                                customSort: (a, b) => a.isClientActive - b.isClientActive},
                            {title: <label>Eligible Grades</label>, tooltip: 'Select grades eligible for private offices for this floor (excludes unrestricted private offices in Tango)', hidden: currentOffice.officeCountry !== 'US', align: 'center',
                                render: (floor) =>
                                    <select id={`floor-grade-list-${floor.id}`} name={`floorGradeList-${floor.id}`}
                                            value={ floor.gradeList ? floor.gradeList : [] } multiple={true}
                                            title={'CTRL to select more than one option or to unselect'}
                                            onChange={(e) => editFloorGradeList(floor.id, e.target)}
                                    >
                                        {gradeList.sort().map((grade, index) => (
                                            <option key={index} value={grade.code}>{grade.name}</option>))
                                        }
                                    </select>
                            },
                            {title: <label>Eligibility Expiration</label>, tooltip: 'Number of calendar days from today that eligibility opens for restricted private offices for all users', hidden: currentOffice.officeCountry !== 'US', align: 'center',
                                render: (floor) => <span><input type='number' min='0' required defaultValue={!floor.spaceEligibilityExpiration ? 0 : floor.spaceEligibilityExpiration}
                                                                 onBlur={(e) => editEligibilityExpiration(floor.id, e.target.value)} aria-label={'Eligibility Expiration'}/> Days</span>,
                                customSort: (a, b) => a.spaceEligibilityExpiration - b.spaceEligibilityExpiration},
                            {title: <label>Actions</label>, sorting: false, searchable: false, align: 'center',
                                render: (floor) =>  <div className='text-nowrap'><Link to={{pathname: '/admin/manage-spaces', state: {floor: floor, office: currentOffice}}}><button className='btn btn--secondary'>{seatButtonText(floor)}</button></Link>
                                    {currentOffice.officeCountry !== 'US' ? <button className='btn logo-btn pl-3' onClick={() => {if (window.confirm('Delete floor?')) removeFloor(floor.id)}}><img alt={'Delete floor'} src={trash_logo}/></button> : null}</div>
                            }
                        ]}
                        data={floors}
                        components={{
                            Toolbar: (props) => (
                                <div className={'row p-0 m-0'}>
                                    <div className={'col-9 p-0 m-0'}>{currentOffice.officeCountry !== 'US' ? <AddFloor office={currentOffice} addFloor={addFloor}/> : null}</div>
                                    <div className={'col-3 p-0 m-0'}><MTableToolbar {...props} /></div>
                                </div>
                            ),
                        }}
                        />
                </div>
            </section>
            <div>
                <Modal show={show} onHide={handleClose} size='lg' backdrop='static' animation={false} >
                    <Modal.Header><h1>Upload a Floor Map</h1></Modal.Header>
                    <Modal.Body><MapUpload floor={floorForMap}/></Modal.Body>
                    <Modal.Footer><button className='btn btn--secondary' active='true' onClick={handleClose}>Close</button></Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default Floors;