// At least 10 digits or ( + 3 digits + ) + space + 3 digits + - + 4 or more digits
const PHONE_PATTERN = '^(\\d{10,})|(\\(\\d{3}\\) \\d{3}-\\d{4,})$';
// Start with any non-whitespace or @, alphanumeric, hyphen, period allowed after an @, at least two characters required after final period at end of email
const EMAIL_PATTERN = '^[^\\s@]+@[A-Za-z0-9.-]+\\.[A-Za-z0-9-]{2,64}$';
// Start with 1 non-whitespace, alphanumeric or hyphen allowed zero or more times
const TEXT_PATTERN = '^\\w{1}[\\w -]+$';
// At least 1 non-whitespace character
const TEXT_ANY_PATTERN = '^\\S.*';

function formatPhone(value) {
    const phone = value.replace(/\D/g, '');
    const phoneArray = phone.match(/(\d{0,3})(\d{0,3})(\d{0,})/);
    return !phoneArray[2] ? phoneArray[1] : '(' + phoneArray[1] + ') ' + phoneArray[2] + (phoneArray[3] ? '-' + phoneArray[3] : '');
}

function validateEmail(value) {
    return value.match(EMAIL_PATTERN);
}

function validatePhone(value) {
    return value.replace(/\D/g, '').match(PHONE_PATTERN);
}

function validateText(value) {
    return value.match(TEXT_PATTERN);
}

function validateTextAny(value) {
    return value.match(TEXT_ANY_PATTERN);
}

export {
    EMAIL_PATTERN,
    PHONE_PATTERN,
    TEXT_PATTERN,
    TEXT_ANY_PATTERN,
    formatPhone,
    validateEmail,
    validatePhone,
    validateText,
    validateTextAny
}