// These imports are to support IE: https://reactjs.org/docs/react-dom.html#browser-support
import 'core-js';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import Container from './Container';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Switch>
              <Route><Container/></Route>
          </Switch>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
