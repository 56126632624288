import * as DateUtils from './DateUtils';
import * as ics from 'ics';

const EMAIL_BODY_FOOTER = `\n\nView your reservations: ${window.location.origin}/reservation-dashboard`;

const createParkingCalendarInvite = (reservation, officeName) => {
    let reservationStartDate, reservationEndDate, reservationDateString;
    if (reservation.reservationDate !== null && reservation.reservationDate !== undefined) {
        reservationStartDate = reservationEndDate = DateUtils.parseDate(reservation.reservationDate);
        reservationDateString = DateUtils.getLocaleDateFromString(reservation.reservationDate, false);
    } else {
        reservationStartDate = DateUtils.parseDate(reservation.reservationDateGroup[0]);
        reservationEndDate = DateUtils.parseDate(reservation.reservationDateGroup[1]);
        if (reservationStartDate.getTime() !== reservationEndDate.getTime()) {
            reservationDateString = `${DateUtils.getLocaleDateFromString(reservation.reservationDateGroup[0], false)} - ${DateUtils.getLocaleDateFromString(reservation.reservationDateGroup[1], false)}`;
        } else {
            reservationDateString = DateUtils.getLocaleDateFromString(reservation.reservationDateGroup[0], false);
        }
    }

    ics.createEvent({
        title: `${officeName} Parking Reservation${reservation.isOvernight ? ' (with overnight parking)' : ''}`,
        description: `Parking space reserved for ${officeName} on ${reservationDateString}${reservation.isOvernight ? ' with overnight parking' : ''}${EMAIL_BODY_FOOTER}`,
        busyStatus: 'FREE',
        start: [reservationStartDate.getFullYear(), reservationStartDate.getMonth() + 1, reservationStartDate.getDate()],
        end: [reservationEndDate.getFullYear(), reservationEndDate.getMonth() + 1, reservationEndDate.getDate() + 1],
        location: officeName
    }, (error, value) => {
        if (error) {
            console.error(`An error occurred creating calendar event: ${error}`);
        }
        downloadCalendarInvite(value);
    });
};
const createSpaceCalendarInvite = (reservation, floorName) => {
    let reservationDateString = DateUtils.getLocaleDateFromString(reservation.reservationDate, false);
    const reservationDate = DateUtils.parseDate(reservation.reservationDate);
    let startArray = [reservationDate.getFullYear(), reservationDate.getMonth() + 1, reservationDate.getDate()];
    let endArray = [reservationDate.getFullYear(), reservationDate.getMonth() + 1, reservationDate.getDate()];
    if (!(reservation.reservationStartHour === 7 && reservation.reservationEndHour === 17)) {
        let startHour = reservation.reservationStartHour;
        let endHour = reservation.reservationEndHour;
        let startString = ((startHour === 0 || startHour === 12) ? 12 : startHour % 12) + (startHour >= 12 ? 'PM' : 'AM');
        let endString = ((endHour === 0 || endHour === 12) ? 12 : endHour % 12) + (endHour >= 12 ? 'PM' : 'AM');
        reservationDateString += ` from ${startString} - ${endString}`;
        startArray = [reservationDate.getFullYear(), reservationDate.getMonth() + 1, reservationDate.getDate(), reservation.reservationStartHour, 0];
        endArray = [reservationDate.getFullYear(), reservationDate.getMonth() + 1, reservationDate.getDate(), reservation.reservationEndHour, 0];
    }

    ics.createEvent({
        title: `Space ${reservation.spaceName} Reservation`,
        description: `You have a reservation for ${reservationDateString}:\n\nSpace: ${reservation.spaceName}\nOffice: ${reservation?.officeName !== null ? reservation.officeName : reservation.officeDto.displayName}\nFloor: ${floorName}${EMAIL_BODY_FOOTER}`,
        busyStatus: 'FREE',
        start: startArray,
        end: endArray,
        location: `Space: ${reservation.spaceName}, Office: ${reservation?.officeName !== null ? reservation.officeName : reservation.officeDto.displayName}, Floor: ${floorName}`
    }, (error, value) => {
        if (error) {
            console.error(`An error occurred creating calendar event: ${error}`);
        }
        downloadCalendarInvite(value);
    });
};

const downloadCalendarInvite = (value) => {
    // below from https://stackoverflow.com/a/65178698
    let blob = new Blob([value], {type: 'text/calendar;charset=utf-8'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(blob, 'reservation.ics');
    } else { // for Non-IE (chrome, firefox etc.)
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        let csvUrl = URL.createObjectURL(blob);
        a.href = csvUrl;
        a.download = 'reservation.ics';
        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
    }
}

export {
    createParkingCalendarInvite,
    createSpaceCalendarInvite
}