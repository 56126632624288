import React, {useEffect, useState} from 'react';
import AxiosRequest from '../../utilities/AxiosRequest';
import Notification from '../standard/Notification';
import CheckmarkSVG from '../../images/CheckmarkSVG';
import close_circle from '../../images/close_circle.png';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import * as ValidateUtils from '../../utilities/ValidateUtils';
import * as DateUtils from '../../utilities/DateUtils';
import {TextField} from '@material-ui/core';

function ParkingReservations() {
    const [date, setDate] = useState(DateUtils.getYMDDate(new Date()))
    const [disableEditPast, setDisableEditPast] = useState(false);
    const [reservations, setReservations] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const url = '/api/v1/reservations';

    useEffect(() => {
        resetNotifications();

        const getReservations = async () => {
            try {
                const response = await AxiosRequest.get(`${url}?date=${date}`);
                if (response.status === 200) {
                    setReservations([]);
                    setReservations(response.data);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving parking reservations');
                }
            } catch (err) {
                setError(true);
                setErrorMessage('An error occurred retrieving parking reservations');
            }
        };
        getReservations();

        setDisableEditPast(date < DateUtils.getYMDDate(new Date()));
    }, [date]);

    const editReservation = async (updatedReservation, checkIn) => {
        try {
            resetNotifications();
            const newReservations = [...reservations];
            const editIndex = newReservations.findIndex(reservation => reservation.id === updatedReservation.id);
            let endpointUrl = checkIn ?  url + '/' + updatedReservation.id + '?checkIn=true' : url + '/' + updatedReservation.id
            const response = await AxiosRequest.put(endpointUrl, newReservations[editIndex]);
            if (response.status === 200) {
                updatedReservation.checkedIn = checkIn;
                newReservations[editIndex] = updatedReservation;
                setReservations(newReservations);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing the reservation for: ' + updatedReservation.employeeName);
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a reservation');
        }
    };

    const editReservationPhone = (reservation, phoneTextbox) => {
        resetNotifications();
        //strip out non-numeric
        const phone = phoneTextbox.value.replace(/\D/g,'');
        if (ValidateUtils.validatePhone(phone)) {
            reservation.contactPhone = phone;
            editReservation(reservation, false);
            phoneTextbox.value = ValidateUtils.formatPhone(phone);
        } else {
            phoneTextbox.value = ValidateUtils.formatPhone(reservation.contactPhone);
            setError(true);
            setErrorMessage('Contact Number is invalid');
        }
    };

    const editReservationState = (e, reservation) => {
        resetNotifications();
        if (e.target.value.length > 0 && ValidateUtils.validateText(e.target.value)) {
            reservation.vehicleState = e.target.value.toUpperCase();
            editReservation(reservation, false);
        } else {
            e.target.value = reservation.vehicleState;
            setError(true);
            setErrorMessage('Vehicle State is required');
        }
    };

    const editReservationPlate = (e, reservation) => {
        resetNotifications();
        if (e.target.value.length > 0 && ValidateUtils.validateTextAny(e.target.value)) {
            reservation.vehiclePlate = e.target.value.toUpperCase();
            editReservation(reservation, false);
        } else {
            e.target.value = reservation.vehiclePlate;
            setError(true);
            setErrorMessage('Vehicle Plate is required');
        }
    };

    const editReservationMake = (e, reservation) => {
        if (e.target.value.length === 0 || ValidateUtils.validateText(e.target.value)) {
            reservation.vehicleMake = e.target.value.toUpperCase();
            editReservation(reservation, false);
        } else {
            e.target.value = reservation.vehicleMake;
            setError(true);
            setErrorMessage('Vehicle Make is invalid');
        }
    };

    const editReservationModel = (e, reservation) => {
        if (e.target.value.length === 0 || ValidateUtils.validateText(e.target.value)) {
            reservation.vehicleModel = e.target.value.toUpperCase();
            editReservation(reservation, false);
        } else {
            e.target.value = reservation.vehicleModel;
            setError(true);
            setErrorMessage('Vehicle Model is invalid');
        }
    };

    const editReservationColor = (e, reservation) => {
        if (e.target.value.length === 0 || ValidateUtils.validateText(e.target.value)) {
            reservation.vehicleColor = e.target.value.toUpperCase();
            editReservation(reservation, false);
        } else {
            e.target.value = reservation.vehicleColor;
            setError(true);
            setErrorMessage('Vehicle Color is invalid');
        }
    };

    const editReservationCheckIn = (reservation) => {
        if (reservation.vehiclePlate.length > 0 && reservation.vehicleState.length > 0) {
            editReservation(reservation, true);
        } else {
            setError(true);
            setErrorMessage('Vehicle State and Vehicle Plate are required to check in');
        }
    };

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    };

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <section>
                <h1>Parking Reservations for {DateUtils.parseDate(date).toLocaleDateString()} </h1>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true
                        }}
                        columns={[
                            {title: 'Name', field: 'employeeName'},
                            {title: 'Employee ID', field: 'employeeId',
                                customFilterAndSearch: (term, reservation) => (reservation.employeeId).toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.employeeId).localeCompare(b.employeeId)},
                            {title: 'Contact Number', field: 'contactPhone',
                                render: (reservation) => <input type={'text'} size={'19'} maxLength={19} defaultValue={ValidateUtils.formatPhone(reservation.contactPhone)} onBlur={(e) => editReservationPhone(reservation, e.target)}
                                                                disabled={disableEditPast} title={disableEditPast ? 'Past reservations cannot be modified' : ''} onChange={(e) => e.target.value = ValidateUtils.formatPhone(e.target.value)}/>,
                                customFilterAndSearch: (term, reservation) => (reservation.contactPhone.replace(/\D/g,'')).indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => a.contactPhone.replace(/\D/g,'').localeCompare(b.contactPhone.replace(/\D/g,''))},
                            {title: 'Vehicle State',
                                render: (reservation) => <input type='text' size={'2'} maxLength={2} defaultValue={reservation.vehicleState} disabled={disableEditPast} title={disableEditPast ? 'Past reservations cannot be modified' : ''}
                                                                onBlur={(e) => editReservationState(e, reservation)}/>,
                                customFilterAndSearch: (term, reservation) => (reservation.vehicleState.toUpperCase()).indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => a.vehicleState.localeCompare(b.vehicleState)},
                            {title: 'Vehicle Plate',
                                render: (reservation) => <input type='text' size={'20'} maxLength={30} defaultValue={reservation.vehiclePlate} disabled={disableEditPast} title={disableEditPast ? 'Past reservations cannot be modified' : ''}
                                                                onBlur={(e) => editReservationPlate(e, reservation)}/>,
                                customFilterAndSearch: (term, reservation) => (reservation.vehiclePlate.toUpperCase()).indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => a.vehiclePlate.localeCompare(b.vehiclePlate)},
                            {title: 'Vehicle Make',
                                render: (reservation) => <input type='text' size={'20'} maxLength={30} defaultValue={reservation.vehicleMake} disabled={disableEditPast} title={disableEditPast ? 'Past reservations cannot be modified' : ''}
                                                                onBlur={(e) => editReservationMake(e, reservation)}/>,
                                customFilterAndSearch: (term, reservation) => (reservation.vehicleMake.toUpperCase()).indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => a.vehicleMake.localeCompare(b.vehicleMake)},
                            {title: 'Vehicle Model',
                                render: (reservation) => <input type='text' size={'20'} maxLength={30} defaultValue={reservation.vehicleModel} disabled={disableEditPast} title={disableEditPast ? 'Past reservations cannot be modified' : ''}
                                                                onBlur={(e) => editReservationModel(e, reservation)}/>,
                                customFilterAndSearch: (term, reservation) => (reservation.vehicleModel.toUpperCase()).indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => a.vehicleModel.localeCompare(b.vehicleModel)},
                            {title: 'Vehicle Color',
                                render: (reservation) => <input type='text' size={'15'} maxLength={30} disabled={disableEditPast} title={disableEditPast ? 'Past reservations cannot be modified' : ''}
                                                                defaultValue={reservation.vehicleColor} onBlur={(e) => editReservationColor(e, reservation)}/>,
                                customFilterAndSearch: (term, reservation) => (reservation.vehicleColor.toUpperCase()).indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => a.vehicleColor.localeCompare(b.vehicleColor)},
                            {title: 'Overnight', align: 'center',
                                render: (reservation) => reservation.isOvernight ? <CheckmarkSVG width={28} height={28} title={'Overnight parking reserved'}/>
                                    :  <img title={'Overnight parking not reserved'} alt={'Overnight parking not reserved'} src={close_circle} height={28} width={28}/>},
                            {title: 'Actions', align: 'center',
                                render: (reservation) => reservation.checkedIn ?
                                    <CheckmarkSVG width={28} height={28} title={'Checked In'}/>
                                    : <button className='btn btn--secondary' disabled={date !== DateUtils.getYMDDate(new Date())} title={date !== DateUtils.getYMDDate(new Date()) ? 'Reservations can only be checked in on the day of reservation' : ''} onClick={() => {editReservationCheckIn(reservation)}}>Check In</button>,
                                customSort: (a, b) => a.checkedIn - b.checkedIn
                            }
                        ]}
                        data={reservations}
                        components={{
                            Toolbar: (props) => (
                                <div className={'row p-0 m-0 mt-1'}>
                                    <div className={'col p-0 m-0 my-auto'}><label className={'mr-1 mt-1'} htmlFor={'date'}><b>Choose date:</b></label>
                                        <TextField type={'date'} id={'-date'} value={date}
                                                   InputProps={{
                                                       className: 'date-field',
                                                       disableUnderline: true,
                                                       required: true
                                                   }}
                                                   onChange={(event) => {
                                                       setDate(DateUtils.getYMDDate(DateUtils.parseDate(event.target.value)))
                                                   }}
                                        />
                                    </div>
                                    <div className={'col p-0 m-0'}><MTableToolbar {...props} /></div>
                                </div>
                            ),
                        }}
                    />
                </div>
            </section>
        </React.Fragment>
    );
}

export default ParkingReservations;