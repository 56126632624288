import React, {useCallback, useEffect, useState} from 'react';
import office_icon from '../../images/office_teal.png';
import AxiosRequest from '../../utilities/AxiosRequest';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../css/Typeahead.css';
import {Link} from 'react-router-dom';
import ReservationList from './ReservationList';
import Notification from '../standard/Notification';
import parking_logo from '../../images/auto.png';
import calendar_logo from '../../images/calendar.png';
import QuickReserve from './QuickReserve';
import {RESERVATION_TYPE} from '../../utilities/Constants';
import {LoadingProgress} from '../standard/LoadingProgress';

function Home({user}) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isQuickReserveHidden, setIsQuickReserveHidden] = useState(true);
    const [isQuickReserveMinimized, setIsQuickReserveMinimized] = useState(true);
    const [offices, setOffices] = useState([]);
    const [selectedOffices, setSelectedOffices] = useState([]);
    const [userHistory, setUserHistory] = useState(null);
    const [userMostRecentOfficeId, setUserMostRecentOfficeId] = useState(null);
    const [userReservations, setUserReservations] = useState();
    const [workspaceDisabled, setWorkspaceDisabled] = useState(true);

    const selectOffice = (selection) => {
        setSelectedOffices(selection);
        if (selection.length > 0) {
            setWorkspaceDisabled(false);
        } else {
            setWorkspaceDisabled(true);
        }
    };

    const updateUserReservations = (reservations) => {
        setUserReservations(reservations);
    };

    const getReservations = useCallback (async (didCancel) => {
        try {
            if (user.id) {
                const response = await AxiosRequest.get(`/api/v1/users/${user.id}/reservations`);
                if (response.status === 200) {
                    if (!didCancel) {
                        setUserReservations(response.data);
                    }
                } else {
                    if (!didCancel) {
                        setError(true);
                        setErrorMessage('An error occurred retrieving upcoming reservations');
                    }
                }
            }
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.message || err.response.data.error);
        }
    }, [user]);

    useEffect(() => {
        let didCancel = false;
        getReservations(didCancel);

        return () => {
            didCancel = true;
        };
    }, [getReservations]);

    useEffect(() => {
        resetNotifications();
        let didCancel = false;

        const getOffices = async () => {
            try {
                const response = await AxiosRequest.get('/api/v1/offices?isClientActive=true');
                if (response.status === 200) {
                    let res = response.data;
                    res.sort((a, b) => {
                        const aName = a.nickname ? a.nickname : a.officeName;
                        const bName = b.nickname ? b.nickname : b.officeName;
                        return aName.localeCompare(bName);
                    });
                    if (!didCancel) {
                        setOffices(res);
                        const timer = setTimeout(() => {
                            setIsLoading(false);
                        }, 300);
                        return () => clearTimeout(timer);
                    }
                } else {
                    if (!didCancel) {
                        setError(true);
                        setErrorMessage('An error occurred retrieving the office list');
                    }
                }
            } catch (err) {
                setError(true);
                setErrorMessage(err.response.data.message || err.response.data.error);
            }
        };
        getOffices();

        return () => {
            didCancel = true;
        };
    }, []);

    useEffect(() => {
        if (offices?.length > 0 && userMostRecentOfficeId) {
            selectOffice([offices.find(o => o.id === userMostRecentOfficeId)]);
        }
    }, [userMostRecentOfficeId, offices]);

    useEffect(() => {
        const getUserHistory = async () => {
            try {
                if (user.id) {
                    const response = await AxiosRequest.get(`/api/v1/users/${user.id}/reservations/history`);
                    if (response.status === 200) {
                        setUserHistory(response.data);
                        // Use most recent reservation for defaulting office dropdown
                        if (response.data.length > 0) {
                            const mostRecentSpace = response.data.find(s => s.isMostRecent);
                            setUserMostRecentOfficeId(mostRecentSpace?.officeId);
                        }
                        setIsQuickReserveHidden(response.data.length === 0);
                    } else {
                        setError(true);
                        setErrorMessage('An error occurred retrieving user reservation history');
                    }
                }
            } catch (error) {
                setError(true);
                setErrorMessage(error?.response?.data?.message || error?.response?.data?.error || 'An unexpected error occurred');
            }
        };
        getUserHistory();
    }, [user]);

    const getOfficeNickname = (option) => {
        return option.nickname ? option.nickname + ' - ' + option.officeNumber : option.officeName + ' - ' + option.officeNumber;
    };

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    };

    const minimizeQuickReserve = () => {
        setIsQuickReserveMinimized(!isQuickReserveMinimized);
    };

    return (
        <React.Fragment>
            <div id={'home'} className={'mx-auto'}>
                <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
                <QuickReserve user={user} isMinimized={isQuickReserveMinimized} minimizeMethod={minimizeQuickReserve} refreshReservationListMethod={getReservations} userReservationDates={userReservations?.filter(r => r.reservationType === RESERVATION_TYPE.SPACE).map(r => r.reservationDate)} userHistory={userHistory} />
                <h3 className={'greeting'}>Welcome, {user.firstName}</h3>
                <section>
                    <h3>Liberty Mutual Reservations</h3>
                    <span className={'lead'}>What do you need to reserve today?</span>
                    <div id={'office-search'} className={'py-4'}>
                        <Typeahead
                            clearButton={true}
                            id={'office-search-text'}
                            options={offices}
                            onChange={selectOffice}
                            labelKey={option => getOfficeNickname(option)}
                            filterBy={(option, props) => {
                                // Show full menu if default is pre-selected, otherwise match text to nickname
                                if (userMostRecentOfficeId && props.text === getOfficeNickname(offices.find(o => o.id === userMostRecentOfficeId))) {
                                    return true;
                                } else {
                                    // This code replaces the special characters in props.text with their escaped versions
                                    // This prevents the characters from being interpreted as special characters, which caused the app to crash
                                    const escapedText = props.text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                                    const regex = new RegExp(escapedText, 'gi');
                                    return getOfficeNickname(option).match(regex) !== null;
                                }
                            }}
                            selected={selectedOffices}
                            disabled={isLoading}
                        >{isLoading && <div id={'loading-offices'}><LoadingProgress message=' Loading offices...' /></div>}</Typeahead>
                    </div>
                    <Link id={'reserve-personal-workspace'} to={{pathname: '/reserve-calendar', state: {office: selectedOffices[0], userReservations: userReservations}}} disabled={workspaceDisabled} onClick={(e) => {if (workspaceDisabled) e.preventDefault()}}>
                        <div className={'card' + (isQuickReserveHidden ? ' mb-3' : '')} data-toggle={'tooltip'} title={workspaceDisabled ? 'Please select an office' : ''}>
                            <div className={'row no-gutters'}>
                                <div className={'col-9'}>
                                    <div className={'card-body p-2 p-sm-3'}>
                                        <h4 className={'card-title'}>Personal Workspace</h4>
                                        <p className={'card-text'}>Individual working area with monitor and docking station</p>
                                    </div>
                                </div>
                                <div className={'col-3 align-self-center'}>
                                    <div className={'card-body p-2 p-sm-3 d-flex justify-content-end'}>
                                        <img src={office_icon} height={'80px'} alt={'Personal workspace'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div id={'quick-reserve-button'} className={'mb-3'} onClick={() => setIsQuickReserveMinimized(false)} title={'Reserve a space from your history for a single day'} style={{display: isQuickReserveHidden ? 'none' : 'block'}}>Quick Reserve</div>
                    <Link id={'reserve-parking'} to={{pathname: '/reserve-parking'}}>
                        <div className={'card mb-3'}>
                            <div className={'row no-gutters'}>
                                <div className={'col-9'}>
                                    <div className={'card-body p-2 p-sm-3'}>
                                        <h4 className={'card-title'}>Boston Parking Space</h4>
                                        <p className={'card-text'}>Boston garage access for visiting employees and people with HR-approved exceptions</p>
                                    </div>
                                </div>
                                <div className={'col-3 align-self-center'}>
                                    <div className={'card-body p-2 p-sm-3 d-flex justify-content-end'}>
                                        <img src={parking_logo} height={'80px'} alt={'Boston Parking'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link id={'reservation-dashboard'} to={'/reservation-dashboard'}>
                        <div className={'card'}>
                            <div className={'row no-gutters'}>
                                <div className={'col-9'}>
                                    <div className={'card-body p-2 p-sm-3'}>
                                        <h4 className={'card-title'}>Reservation Dashboard</h4>
                                        <p className={'card-text'}>All of your reservations in one place</p>
                                    </div>
                                </div>
                                <div className={'col-3 align-self-center'}>
                                    <div className={'card-body p-2 p-sm-3 d-flex justify-content-end'}>
                                        <img src={calendar_logo} height={'80px'} alt={'My Reservations'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </section>
                <div className={'section-divider'}></div>
                <section>{userReservations && <ReservationList user={user} reservations={userReservations} onChange={updateUserReservations} listTitle={'Upcoming Reservations'}/>}</section>
                <div className={'section-divider'}></div>
            </div>
        </React.Fragment>
    )
}

export default Home;