import React, {useState, useEffect} from 'react';
import AxiosRequest from '../../../utilities/AxiosRequest';
import {Link} from 'react-router-dom';
import AddOffice from './AddOffice';
import Notification from '../../standard/Notification';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import {AVAILABLE_INTERNATIONAL_COUNTRIES} from '../../../utilities/Constants';
import trash_logo from '../../../images/trash_logo.png';

function Offices(props) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const isIntl = props.location.state.isIntl;
    const [offices, setOffices] = useState([]);
    const [timezones, setTimezones] = useState([]);
    const url = '/api/v1/offices';

    useEffect(() => {
        resetNotifications();

        const getOffices = async () => {
            try {
                const response = await AxiosRequest.get(url + '?isIntl=' + isIntl);
                if (response.status === 200) {
                    setOffices(response.data);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving office list');
                }
            } catch (err) {
                setError(true);
                setErrorMessage(err.response.data.message || err.response.data.error);
            }
        };

        getOffices();

        const getTimezones = async () => {
            try {
                const response = await AxiosRequest.get('/api/v1/timezones');
                if (response.status === 200) {
                    setTimezones(response.data);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving timezone list');
                }
            } catch (err) {
                setError(true);
                setErrorMessage('An error occurred retrieving timezone list');
            }
        };

        getTimezones();
    }, [isIntl]);

    const editOffice = async (updatedOffice) => {
        try {
            resetNotifications();
            if (isIntl) {
                if (!updatedOffice.officeName || updatedOffice.officeName === '') {
                    setError(true);
                    setErrorMessage('Office Name cannot be empty');
                    return;
                } else if (!updatedOffice.officeNumber || updatedOffice.officeNumber === '' || !updatedOffice.officeNumber.match(/^[^\s]{4}$/)) {
                    setError(true);
                    setErrorMessage('Office Code must be four characters long');
                    return;
                }
            }
            const newOffices = [...offices];
            const editIndex = newOffices.findIndex(office => office.id === updatedOffice.id);
            newOffices[editIndex] = updatedOffice;
            const response = await AxiosRequest.put(url + '/' + updatedOffice.id + '?isIntl=' + isIntl, newOffices[editIndex]);
            if (response.status === 200) {
                setOffices(newOffices);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing the office: ' + (updatedOffice.nickname ? updatedOffice.nickname : updatedOffice.officeName));
            }
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.message || err.response.data.error);
        }
    };

    const editCapacity = (office, capacity) => {
        office.maxCapacity = capacity !== '' ? capacity : 0;
        editOffice(office);
    };

    const editMaxReservationDays = (office, dayLimit) => {
        office.maxReservationDays = dayLimit !== '' ? dayLimit : 0;
        editOffice(office);
    };

    const editOfficeName = (office, name) => {
        if (office.officeCountry === 'US') {
            office.nickname = name;
        } else {
            office.officeName = name;
        }
        editOffice(office);
    };

    const editOfficeNumber = (office, number) => {
        office.officeNumber = number;
        editOffice(office);
    };

    const editOfficeCountry = (office, country) => {
        office.officeCountry = country;
        editOffice(office);
    };

    const editOfficeActive = (office) => {
        office.isClientActive = !office.isClientActive;
        editOffice(office);
    };

    const editOfficeTimezone = (office, timezone) => {
        office.officeTimezoneId = timezone;
        editOffice(office);
    }

    const addOffice = async (office) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.post(url + '?isIntl=' + isIntl, office);
            if (response.status === 200) {
                const newOffices = [...offices, response.data];
                setOffices(newOffices);
            } else {
                setError(true);
                setErrorMessage('An error occurred adding office: ' + (office.nickname ? office.nickname : office.officeName));
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred adding an office');
        }
    };

    const deleteOffice = async (id) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(url + id + '?isIntl=' + isIntl);
            if (response.status === 204) {
                const newOffices = offices.filter((office) => office.id !== id);
                setOffices(newOffices);
            } else {
                setError(true);
                setErrorMessage('An error occurred deleting an office');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred deleting an office');
        }
    };

    const resetNotifications = () => {
        setErrorMessage('');
        setError(false);
    }

    const floorButtonText = (office) => {
        return 'Manage ' + (office.floorCount ? office.floorCount : 0) + ' Floor' + (office.floorCount === 1 ? '' : 's');
    }

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/admin'}>Home</Link> &gt; <Link to={'/admin/manage-office-choice/'}>Offices</Link>
                &nbsp;&gt; {isIntl ? 'International' : 'US'} Offices
            </div>
            <section>
                <h1>{isIntl ? 'International' : 'US'} Office Management</h1>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true,
                        }}
                        columns={[
                            {title: 'Name',
                                render: (office) => <input type='text' size={office.officeCountry === 'US' ? '35' : '20'} defaultValue={office.nickname ? office.nickname : office.officeName}
                                   onBlur={(e) => editOfficeName(office, e.target.value)}/>,
                                customFilterAndSearch: (term, office) => (office.nickname + ' ' + office.officeName).toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) =>  (a.nickname ? a.nickname : a.officeName).toUpperCase().localeCompare((b.nickname ? b.nickname : b.officeName).toUpperCase())},
                            {title: 'Code',
                                render: (office) => office.officeCountry === 'US' ?
                                    office.officeNumber :
                                    <input type={'text'} maxLength={4} size={'5'} defaultValue={office.officeNumber}
                                           onBlur={(e) => editOfficeNumber(office, e.target.value)} />,
                                customFilterAndSearch: (term, office) => (office.officeNumber.toUpperCase()).indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => a.officeNumber.toUpperCase().localeCompare(b.officeNumber.toUpperCase())},
                            {title: 'Country', hidden: !isIntl, align: 'center',
                                 render: (office) => office.officeCountry === 'US' ? null :
                                    <select required id={'office-country'} name={'officeCountry'} value={office.officeCountry}
                                         onChange={(e) => editOfficeCountry(office, e.target.value)}>
                                     {AVAILABLE_INTERNATIONAL_COUNTRIES.map((country, index) => (
                                         <option key={index} value={country.code}>{country.name} ({country.code})</option>))
                                     }
                                    </select>,
                                customFilterAndSearch: (term, office) => {
                                    const officeMatch = AVAILABLE_INTERNATIONAL_COUNTRIES.find(country => country.code === office.officeCountry);
                                    const officeMatchDisplay = officeMatch.name + ' (' + officeMatch.code + ')';
                                    return (officeMatchDisplay.toUpperCase().indexOf(term.toUpperCase()) !== -1)},
                                customSort: (a, b) => a.officeCountry.localeCompare(b.officeCountry)},
                            {title: 'Active', align: 'center',
                                render: (office) => <input type='checkbox' defaultChecked={office.isClientActive}
                                                       onChange={() => editOfficeActive(office)} />,
                                customSort: (a, b) => a.isClientActive - b.isClientActive},
                            {title: 'Capacity', align: 'center',
                                render: (office) => <span><input type='number' min='0' max='100' required defaultValue={office.maxCapacity}
                                                            onBlur={(e) => editCapacity(office, e.target.value)}/> %</span>,
                                customSort: (a, b) =>  a.maxCapacity - b.maxCapacity},
                            {title: 'Reservation Limit', tooltip: 'Number of calendar days in the future', align: 'center',
                                render: (office) => <span><input type='number' min='0' required defaultValue={!office.maxReservationDays ? 0 : office.maxReservationDays}
                                                             onBlur={(e) => editMaxReservationDays(office, e.target.value)}/> Days</span>,
                                customSort: (a, b) => a.maxReservationDays - b.maxReservationDays},
                            {title: 'Timezone', align: 'center',
                                render: (office) => <select id={'office-timezone'} name={'officeTimezone'}  style={{maxWidth: '250px'}}
                                    value={office.officeTimezoneId} onChange={(e) => editOfficeTimezone(office, e.target.value)}>
                                    <option value={''}>Select a Timezone</option>
                                    {timezones.map((zone, index) => (
                                        <option key={index} value={zone.id}>{zone.timezoneId} ({zone.timezoneDisplayName})</option>))}
                                </select>,
                                customFilterAndSearch: (term, office) => {
                                    const zoneMatch = timezones.find(zone => zone.id === office.officeTimezoneId);
                                    let result = false;
                                    if (zoneMatch != null) {
                                        const zoneMatchDisplay = zoneMatch.timezoneId + ' (' + zoneMatch.timezoneDisplayName + ')';
                                        result = (zoneMatchDisplay.toUpperCase().indexOf(term.toUpperCase()) !== -1)
                                    }
                                    return result},
                                customSort: (a, b) => {
                                    if (!a.officeTimezoneId) return 1;
                                    if (!b.officeTimezoneId) return -1;
                                    return timezones[a.officeTimezoneId - 1].timezoneId.localeCompare(timezones[b.officeTimezoneId - 1].timezoneId)
                                }},
                            {title: 'Actions', sorting: false, searchable: false, align: 'center',
                                render: (office) => <div className='text-nowrap'><Link to={{pathname: '/admin/manage-floors', state: {office: office}}}>
                                                 <button className='btn btn--secondary'>{floorButtonText(office)}</button></Link>
                                {office.officeCountry === 'US' ? null : <button className='btn logo-btn' title='Delete' onClick={() => {
                                    if (window.confirm('Delete office?')) { deleteOffice(office.id); }}}><img alt={'Trash Logo'} src={trash_logo}/></button>}</div>
                            }
                            ]}
                        data={offices}
                        components={{
                            Toolbar: (props) => (
                                <div className={'container-fluid'}>
                                    <div className={'d-flex flex-wrap row p-0 m-0'}>
                                        {isIntl ? <AddOffice addOffice={addOffice} timezones={timezones}/> : null}
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col'}><MTableToolbar {...props} /></div>
                                    </div>
                                </div>
                            ),
                        }}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default Offices;