import React, {useCallback, useContext, useEffect, useState} from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import close_circle_image from '../../images/close_circle.png';
import UserSearch from '../standard/UserSearch';
import * as Constants from '../../utilities/Constants';
import * as DateUtils from '../../utilities/DateUtils';
import AxiosRequest from '../../utilities/AxiosRequest';
import {getOverlappingReservationsForSpan} from '../../utilities/ReservationUtils';
import UserContext from '../../context/UserContext';

function ReserveMenu({hideReserveDropdown, resetErrorNotifications, selectedDates, selectedHours, selectedSeat, userReservations, setUserReservations, getReservations, setError, setErrorMessage, atMaxCapacity, ...props}) {
    const [isPrivateSpaceEligible, setIsPrivateSpaceEligible] = useState(false);
    const [reservationUser, setReservationUser] = useState(null);
    const user = useContext(UserContext);

    const reservationUrl = '/api/v1/reservations';
    const addReservation = async (reservationEmployeeId) => {
        hideReserveDropdown();
        resetErrorNotifications();
        resetBehalfOfState();

        const newReservation = [{
            employeeId: reservationEmployeeId,
            spaceId: selectedSeat.id,
            reservationDateGroup: [DateUtils.getYMDDate(selectedDates[0]), DateUtils.getYMDDate(selectedDates[1])],
            reserverId: user.id,
            reservationStartHour: selectedHours[0],
            reservationEndHour: selectedHours[1]
        }];
        try {
            let response = await AxiosRequest.post(reservationUrl, newReservation);
            if (response.status === 201) {
                const createdReservation = response.data[0];
                if (newReservation[0].employeeId === user.employeeId) {
                    createdReservation.forEach((reservation, index) => {
                        createdReservation[index] = {...reservation, spaceName: selectedSeat?.nickname ? selectedSeat?.nickname : selectedSeat?.spaceNumber, officeName: props.currentOffice.current.displayName}
                    });
                    setUserReservations([...userReservations, ...createdReservation]);
                }
                props.setSaveSuccess(true);
                props.setCreatedReservation(createdReservation);
                getReservations();
            } else {
                setError(true);
                setErrorMessage('There was a problem creating your reservation');
            }
        } catch (error) {
            setError(true);
            setErrorMessage(error?.response?.data?.message || error?.response?.data?.error || 'An unexpected error occurred');
        }
    };

    const cancelReservation = async (reservation) => {
        resetErrorNotifications();
        hideReserveDropdown();
        resetBehalfOfState();

        try {
            let response = await AxiosRequest.destroy(`/api/v1/users/${reservation.userId}/reservations/${reservation.id}`);
            if (response.status === 200) {
                props.setCancelSuccess(true);
                if (reservation.userId === user.id) {
                    const tempUserReservations = userReservations?.filter(r => r.id !== reservation.id);
                    setUserReservations(tempUserReservations);
                }
                getReservations();
            }
        } catch (error) {
            setError(true);
            setErrorMessage(error?.response?.data?.message || error?.response?.data?.error || 'An unexpected error occurred');
        }
    };

    const setOnBehalfOf = (users) => {
        setIsPrivateSpaceEligible(false);
        setReservationUser(users.length > 0 ? users[0] : null);
    };

    const userHasReservation = useCallback(() => {
        return getOverlappingReservationsForSpan(userReservations, selectedDates[0], selectedDates[1], selectedHours).length !== 0;
    }, [userReservations, selectedDates, selectedHours]);

    const resetBehalfOfState = () => {
        setReservationUser(null);
        setIsPrivateSpaceEligible(false);
    };

    useEffect(() => {
        const getBehalfOfUser = async () => {
            if (reservationUser && selectedSeat.isPrivateSpace) {
                try {
                    const response = await AxiosRequest.get(`/api/v1/users?employeeId=${reservationUser.employeeId.toUpperCase()}&floorId=${selectedSeat.floorId}&endDate=${DateUtils.getYMDDate(selectedDates[1])}`);
                    if (response.status === 200) {
                        setIsPrivateSpaceEligible(response.data.isPrivateSpaceEligible);
                    } else {
                        setError(true);
                        setErrorMessage('An error occurred retrieving user information');
                    }
                } catch (error) {
                    setError(true);
                    setErrorMessage(error?.response?.data?.message || error?.response?.data?.error || 'An unexpected error occurred');
                }
            }
        };
        getBehalfOfUser();
    }, [reservationUser, selectedSeat, selectedDates, setError, setErrorMessage]);

    return (
        <div id={'reserve-space-dropdown'} ref={props.reserveDropdown} className={'p-3'} style={{visibility: 'hidden', zIndex: 1}}>
            <h4 className={'pb-3'}>
                {!atMaxCapacity && selectedSeat?.isFullyAvailable && selectedSeat?.assignedUserIds && !(selectedSeat?.assignedUserIds.length === 1 && selectedSeat?.assignedUserIds.includes(user.employeeId)) && selectedSeat?.isPrivateSpace && user.isPrivateSpaceEligible ?
                    `Reserve ${selectedSeat?.nickname ? selectedSeat?.nickname : selectedSeat?.spaceNumber} for:` :
                    selectedSeat?.nickname ? selectedSeat?.nickname : selectedSeat?.spaceNumber}
            </h4>
            {selectedSeat?.isFullyAvailable ?
                !atMaxCapacity ?
                    selectedSeat?.assignedUserIds && !(selectedSeat?.assignedUserIds.length === 1 && selectedSeat?.assignedUserIds.includes(user.employeeId)) ?
                        <React.Fragment>
                            {props.selectedSeatAssignedUsers.map((user, index) => (
                                <div key={index} className={'row'}>
                                    <div className={'col'}><input type={'button'} className={'btn btn--secondary mb-2'} value={user.displayName} onClick={(event) => {event.preventDefault(); setReservationUser(user); addReservation(user.employeeId.toUpperCase());}}/></div>
                                </div>
                            ))}
                            {selectedSeat.reservations.length > 0 ? <hr/> : null}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {userHasReservation() ? <span className={'small d-inline-block mb-2'} style={{color: 'var(--error-text)', width: '15em'}}>You have a reservation within the selected range</span>
                                : selectedSeat?.isPrivateSpace && !user.isPrivateSpaceEligible ? <span className={'small d-inline-block mb-2 px-2'} style={{color: 'var(--error-text)', width: '15em'}}>You are not eligible to reserve this space</span>
                                    : <input type={'button'} className={'btn btn--secondary mb-2'} value={'Reserve'} onClick={(event) => {event.preventDefault(); addReservation(user.employeeId);}}/>
                            }
                            {selectedSeat?.assignedUserIds ? null :
                                <div>
                                    <OverlayTrigger trigger={'click'} placement={'top'} transition={false} rootClose={true} overlay={
                                        <Popover id={'reserve-behalf-of-popover'} style={{minWidth: '300px'}}>
                                            <Popover.Title as={'h3'}>
                                                Reserve {selectedSeat ? (selectedSeat.nickname ? selectedSeat.nickname : selectedSeat.spaceNumber) : 'this seat'} for:
                                                <img src={close_circle_image} alt={'close'} height={'20px'} style={{cursor: 'pointer', float: 'right'}} title={'Close Behalf Of'} onClick={() => {resetBehalfOfState(); document.body.click();}}/>
                                            </Popover.Title>
                                            <Popover.Content>
                                                <div className={'text-center'}>
                                                    <UserSearch placeholderText={'Enter employee\'s last name or ID...'} setSearchResults={setOnBehalfOf}/>
                                                    {selectedSeat.isPrivateSpace ?
                                                        !reservationUser ? <div className={'mt-2'}>Select an eligible user</div> :
                                                            !isPrivateSpaceEligible ? <div className={'mt-2'}>This user <span style={{color: 'var(--error-text)'}}>is not eligible</span> to reserve</div> :
                                                                <div className={'mt-2'}>This user <span style={{color: 'var(--success-text)'}}>is eligible</span> to reserve</div>
                                                        : null
                                                    }
                                                    <input id={'reserve-on-behalf-button'} type={'button'} className={'btn btn--secondary mt-2 mb-1'} value={'Reserve'} disabled={!reservationUser || (selectedSeat.isPrivateSpace && !isPrivateSpaceEligible)} title={!reservationUser && !selectedSeat.isPrivateSpace ? 'Please select a user' : ''}
                                                           onClick={(event) => {
                                                        event.preventDefault();
                                                        try {
                                                            if (reservationUser) {
                                                                document.body.click();
                                                                addReservation(reservationUser.employeeId.toUpperCase());
                                                            } else {
                                                                alert('Please select an employee to reserve for');
                                                            }
                                                        } catch (e) {
                                                            setError(true);
                                                            setErrorMessage('There was a problem creating your reservation');
                                                        }
                                                    }}/>
                                                </div>
                                            </Popover.Content>
                                        </Popover>
                                    }>
                                        {({ref, ...triggerHandler}) => (
                                            <span ref={ref} {...triggerHandler} className={'link'}>Behalf Of</span>
                                        )}
                                    </OverlayTrigger>
                                </div>
                            }
                            {selectedSeat?.reservations.length > 0 ? <hr/> : null}
                        </React.Fragment>
                    :
                    <React.Fragment>
                        <div className={'small d-inline-block'} style={{color: 'var(--error-text)', width: '15em'}}>{Constants.FLOOR_MAX_CAPACITY_WARNING}</div>
                        {selectedSeat?.reservations.length > 0 ? <hr/> : null}
                    </React.Fragment>
                : null
            }
            {selectedSeat?.reservations.length > 0 ?
                selectedSeat.reservations.map((reservation, index) => (
                    <div key={index} className={'d-flex flex-row align-items-center justify-content-between'}>
                        <div className={'pr-2'}>{DateUtils.getLocaleDateFromString(reservation.reservationDate) + ' (' + DateUtils.displayReservationHours(reservation) + ')'}</div>
                        {(selectedSeat.displayCurrentUser && !selectedSeat.isSingleReserver) || selectedSeat.isFullyAvailable ? <div className={'pr-2'}>{reservation.employeeName}</div> : null}
                        {user.isOfficeAdmin || user.isSuperAdmin ?
                            <input type={'button'} className={'btn btn--secondary mb-1'} value={'Cancel'} onClick={() => {
                                if (window.confirm('Are you sure you would like to cancel this reservation?')) cancelReservation(reservation);
                            }}/>
                            : null}
                    </div>
                ))
                : null
            }
        </div>
    )
}

export default ReserveMenu;