import React, {useContext, useEffect, useRef, useState} from 'react';
import Toggle from '../standard/Toggle';
import Textbox from '../standard/Textbox';
import {Link} from 'react-router-dom';
import Notification from '../standard/Notification';
import UserContext from '../../context/UserContext';
import * as Constants from '../../utilities/Constants';
import MaterialTable from '@material-table/core';
import CheckmarkSVG from '../../images/CheckmarkSVG';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import InfoSVG from '../../images/InformationSVG';
import * as PreferenceUtils from '../../utilities/PreferenceUtils';

function Notifications() {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [groupAllOptIn, setGroupAllOptIn] = useState(false);
    const [notifyPersonalEmail, setNotifyPersonalEmail] = useState(false);
    const [notifyWorkEmail, setNotifyWorkEmail] = useState(true);
    const [personalEmail, setPersonalEmail] = useState('');
    const [saveSuccessGroupAllOptIn, setSaveSuccessGroupAllOptIn] = useState(false);
    const [saveSuccessGroupOptIn, setSaveSuccessGroupOptIn] = useState(false);
    const [saveSuccessNotifyPersonalEmail, setSaveSuccessNotifyPersonalEmail] = useState(false);
    const [saveSuccessNotifyWorkEmail, setSaveSuccessNotifyWorkEmail] = useState(false);
    const [saveSuccessPersonalEmail, setSaveSuccessPersonalEmail] = useState(false);
    const user = useContext(UserContext);
    const [userGroups, setUserGroups] = useState(null);
    let workEmail = useRef('');

    const savePreferences = async (preference) => {
        resetNotifications();
        const response = await PreferenceUtils.savePreferences(preference);
        if (response.length > 0) {
            setError(true);
            setErrorMessage(response);
            return false;
        }
        return true;
    };

    useEffect(() => {
        resetNotifications();

        const getPreferences = async () => {
            try {
                if (user && user.id) {
                    workEmail.current = user.email;
                    const response = await PreferenceUtils.getPreferences();
                    if (response.status === 200) {
                        setPersonalEmail(response.personalEmail);
                        setNotifyWorkEmail(response.notifyWorkEmail);
                        setNotifyPersonalEmail(response.notifyPersonalEmail);
                        setUserGroups(response.userGroups.filter(g => g.ownerId !== user.id));
                        setGroupAllOptIn(response.groupAllOptIn);
                    } else {
                        setError(true);
                        setErrorMessage(response.message);
                    }
                }
            } catch (err) {
                setError(true);
                setErrorMessage(err.response.data.message || err.response.data.error);
            }
        };
        getPreferences();
    }, [user]);

    useEffect(() => {
        if (!personalEmail) {
            setNotifyPersonalEmail(false);
        }
    }, [personalEmail]);

    const saveNotifyWorkEmail = async (toggleValue) => {
        setNotifyWorkEmail(toggleValue);
        const saveSuccess = await savePreferences({
            userId: user.id,
            notifyWorkEmail: toggleValue,
            notifyWorkEmailDirty: true
        });
        setSaveSuccessNotifyWorkEmail(saveSuccess);
    };

    const saveNotifyPersonalEmail = async (toggleValue) => {
        let notifyPersonal = toggleValue;
        if (!personalEmail) {
            setNotifyPersonalEmail(false);
            notifyPersonal = false;
        }
        // Don't send if it didn't change
        if (notifyPersonalEmail !== notifyPersonal) {
            setNotifyPersonalEmail(toggleValue);
            const saveSuccess = await savePreferences({
                userId: user.id,
                notifyPersonalEmail: notifyPersonal,
                notifyPersonalEmailDirty: true
            });
            setSaveSuccessNotifyPersonalEmail(saveSuccess);
        }
    };

    const savePersonalEmail = async (textboxValue) => {
        setPersonalEmail(textboxValue);
        let notifyPersonal = notifyPersonalEmail;
        let notifyPersonalDirty = false;
        if (!textboxValue) {
            setNotifyPersonalEmail(false);
            notifyPersonal = false;
            notifyPersonalDirty = true;
        }
        const saveSuccess = await savePreferences({
            userId: user.id,
            personalEmail: textboxValue,
            personalEmailDirty: true,
            notifyPersonalEmail: notifyPersonal,
            notifyPersonalEmailDirty: notifyPersonalDirty
        });
        setSaveSuccessPersonalEmail(saveSuccess);
    };

    const saveGroupAllOptIn = async (toggleValue) => {
        setGroupAllOptIn(toggleValue);
        let newUserGroups = userGroups;
        if (toggleValue) {
            newUserGroups = userGroups.map(group => {
                group.groupMembers = group.groupMembers.map(member => {
                    member.hasOptedIn = member.memberId === user.id ? toggleValue : member.hasOptedIn;
                    return member;
                });
                return group;
            });
            setUserGroups(newUserGroups);
        }
        const saveSuccess = await savePreferences({
            userId: user.id,
            userGroups: newUserGroups,
            userGroupsDirty: true,
            groupAllOptIn: toggleValue,
            groupAllOptInDirty: true
        });
        setSaveSuccessGroupAllOptIn(saveSuccess);
    };

    const saveGroupOptIn = async (toggleValue, group) => {
        const newUserGroups = await PreferenceUtils.setGroupOptIn(group, user.id, toggleValue, userGroups);
        setUserGroups(newUserGroups);
        const saveSuccess = await savePreferences({
            userId: user.id,
            userGroups: newUserGroups,
            userGroupsDirty: true
        });
        setSaveSuccessGroupOptIn(saveSuccess);
    };

    const resetNotifications = () => {
        setSaveSuccessNotifyWorkEmail(false);
        setSaveSuccessNotifyPersonalEmail(false);
        setSaveSuccessPersonalEmail(false);
        setSaveSuccessGroupAllOptIn(false);
        setSaveSuccessGroupOptIn(false);
        setError(false);
        setErrorMessage('');
    }

    return (
        <div id={'notifications'} className={'mx-auto'}>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/'}>Home</Link>&nbsp;&gt;&nbsp;Notifications
            </div>
            <section className={'pb-2'}>
                <h4>Contact Information</h4>
                <div className={'mt-4'}>
                    <div className={'pb-1'}>
                        <Textbox id={'work-email'} textboxType={Constants.INPUT_TYPES.EMAIL} isDisabled={false}
                                 placeholderText={'Work Email'} initialValue={workEmail.current}
                                 title={'Employee work email sourced from People@Liberty'} maxLength={255}
                                 isReadOnly={true}/>
                    </div>
                    <div className={'pt-1'}>
                        <Textbox id={'personal-email'} textboxType={Constants.INPUT_TYPES.EMAIL}
                                 placeholderText={'Personal Email'} initialValue={personalEmail}
                                 editValue={savePersonalEmail} clearMethod={() => savePersonalEmail('')} maxLength={255} isReadOnly={false}/>
                        {saveSuccessPersonalEmail ?
                            <span className={'pl-2 success-fade'}>
                                <CheckmarkSVG width={19} height={19} title={'Saved successfully'}/>
                                <span className={'success-message pl-1 small'}>Saved</span>
                            </span>
                            : <span>&nbsp;</span>}
                    </div>
                </div>
            </section>
            <div className={'section-divider'}></div>
            <section>
                <h4>Reservation Reminder</h4>
                <div className={'mt-4'}>
                    <div className={'pb-2'}>
                        <Toggle label={'Work Email'} defaultChecked={notifyWorkEmail} toggleValue={saveNotifyWorkEmail}/>
                        {saveSuccessNotifyWorkEmail ?
                            <span className={'pl-2 success-fade'}>
                                <CheckmarkSVG width={19} height={19} title={'Saved successfully'}/>
                                <span className={'success-message pl-1 small'}>Saved</span>
                            </span>
                            : null}
                    </div>
                    <div className={'pt-1'}>
                        <Toggle label={'Personal Email'} defaultChecked={notifyPersonalEmail} toggleValue={saveNotifyPersonalEmail}/>
                        <span className={'pl-2'}>
                            <OverlayTrigger placement={'top'} delay={{show: 250, hide: 400}}
                                            overlay={<Tooltip id={'info-personal-notify'}>Personal email is required to turn on this notification</Tooltip>}>
                                <button className={'btn btn-popover'}><InfoSVG height={14}/></button>
                            </OverlayTrigger>
                        </span>
                        {saveSuccessNotifyPersonalEmail ?
                            <span className={'pl-2 success-fade'}>
                                <CheckmarkSVG width={19} height={19} title={'Saved successfully'}/>
                                <span className={'success-message pl-1 small'}>Saved</span>
                            </span>
                            : null}
                    </div>
                </div>
            </section>
            <div className={'section-divider'}></div>
            <section className={'pb-2'}>
                <div className={'row ml-0'}>
                    <h4>Group Opt-In/Out</h4>
                    <OverlayTrigger placement={'top'} delay={{show: 250, hide: 400}} overlay={<Tooltip id={'info-group-notify'}>Opt in to share your reservation information with the group owner and members.</Tooltip>}>
                        <button className={'pl-2 btn btn-popover'}><InfoSVG height={14}/></button>
                    </OverlayTrigger>
                </div>
                <div className={'mt-3'}>
                    <div className={'pb-1'}>
                        <Toggle label={'Automatically opt in to all group notifications'} defaultChecked={groupAllOptIn} toggleValue={saveGroupAllOptIn}/>
                        <OverlayTrigger placement={'top'} delay={{show: 250, hide: 400}} overlay={<Tooltip id={'info-group-all-notify'}>By selecting this option you agree to share your information with group owners/members without receiving email notifications. Turn off to manage each notification individually.</Tooltip>}>
                            <button className={'pl-2 btn btn-popover d-inline-block'}><InfoSVG height={14}/></button>
                        </OverlayTrigger>
                        {saveSuccessGroupAllOptIn ?
                            <span className={'pl-2 success-fade'}>
                                <CheckmarkSVG width={19} height={19} title={'Saved successfully'}/>
                                <span className={'success-message pl-1 small'}>Saved</span>
                            </span>
                            : null}
                    </div>
                    <div className={'table table-responsive table-striped pt-1 mb-2'}>
                        <MaterialTable
                            options={{
                                draggable: false,
                                headerStyle: {
                                    backgroundColor: 'var(--liberty-teal)',
                                    fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                    fontSize: '16px',
                                    fontWeight: '700'
                                },
                                paging: false,
                                search: true,
                                showTitle: false,
                                sorting: true
                            }}
                            localization={{body: {emptyDataSourceMessage: 'You are not a member of any groups yet.'}}}
                            columns={[
                                {title: 'Group Name', field: 'groupName'},
                                {title: 'Group Owner', field: 'ownerName'},
                                {
                                    title: 'Opt In', searchable: false, align: 'center',
                                    render: (group) => {
                                        const hasOptedInToGroup = groupAllOptIn ? groupAllOptIn : group.groupMembers.find(member => member.memberId === user.id)?.hasOptedIn;
                                        return(<Toggle label={''}
                                                defaultChecked={hasOptedInToGroup}
                                                toggleValue={(checked) => saveGroupOptIn(checked, group)}
                                                isDisabled={groupAllOptIn}
                                                title={hasOptedInToGroup ? 'Opted in' : 'Not opted in'}/>
                                        );
                                    },
                                    customSort: (a, b) => a.groupMembers.find(member => member.memberId === user.id)?.hasOptedIn - b.groupMembers.find(member => member.memberId === user.id)?.hasOptedIn
                                }
                            ]}
                            data={userGroups ? userGroups : []}
                            components={{Toolbar: () => null}}
                        />
                    </div>
                    {saveSuccessGroupOptIn ?
                        <span className={'pl-2 success-fade'}>
                            <CheckmarkSVG width={19} height={19} title={'Saved successfully'}/>
                            <span className={'success-message pl-1 small'}>Saved</span>
                        </span>
                        : <span>&nbsp;</span>}
                </div>
            </section>
            <div className={'section-divider'}></div>
        </div>
    );
}

export default Notifications;