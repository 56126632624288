import * as Constants from './Constants';

function changeEndDate(newEndDateString, startDate) {
    const newEndDate = parseDate(newEndDateString);
    // Return end date only if:
    //  * end date is before start date
    //  * start date is empty
    if (!startDate || newEndDate < startDate) {
        return ([newEndDate, newEndDate]);
    } else {
        return ([startDate, newEndDate]);
    }
}

function changeStartDate(newStartDateString, endDate) {
    const newStartDate = parseDate(newStartDateString);
    // Return start date only if:
    //  * start date is after end date
    //  * end date is empty
    if (!endDate || newStartDate > endDate) {
        return ([newStartDate, newStartDate]);
    } else {
        return ([newStartDate, endDate]);
    }
}

function displayReservationHours(reservation) {
    if (reservation.reservationStartHour === 7 && reservation.reservationEndHour === 17) {
        return 'Full Day';
    } else {
        return Constants.HOURS_IN_A_DAY.find(h => h.code === reservation.reservationStartHour).name + '-' + Constants.HOURS_IN_A_DAY.find(h => h.code === reservation.reservationEndHour).name;
    }
}

function getLocaleDateFromString(dateString, isWeekdayShort = true) {
    let date = parseDate(dateString);
    return date.toLocaleDateString([], {weekday: isWeekdayShort ? 'short' : 'long', year: '2-digit', month: '2-digit', day: 'numeric'});
}

function getMaxDate(office) {
    return getOfficeMaxDate(office.maxReservationDays);
}

function getOfficeMaxDate(officeMaxReservationDays) {
    let maximumDate = new Date();
    if (officeMaxReservationDays !== null && officeMaxReservationDays !== 0) {
        maximumDate.setDate(maximumDate.getDate() + officeMaxReservationDays);
    } else {
        // maxDate has to be a value, for now setting max to 2 years from now if no maxReservationDays set
        maximumDate.setDate(maximumDate.getDate() + 730);
    }
    return maximumDate;
}

function getYMDDate(date) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
}

// Date.parse() (Called with new Date()) notoriously unreliable for parsing, parse manually so we can control timezone
function parseDate(dateString) {
    let parts = dateString.split('-');
    return new Date(parts[0], parts[1] - 1, parts[2]);
}

export {
    changeEndDate,
    changeStartDate,
    displayReservationHours,
    getLocaleDateFromString,
    getMaxDate,
    getOfficeMaxDate,
    getYMDDate,
    parseDate
}