import React, {useState} from 'react';
import {AVAILABLE_INTERNATIONAL_COUNTRIES} from '../../../utilities/Constants';

function AddOffice({addOffice, timezones}) {
    const [form, setState] = useState({
        officeName: '',
        officeNumber: '',
        officeCountry: '',
        officeTimezone: '',
        isClientActive: false
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const newOffice = {
            officeName: form.officeName,
            officeNumber: form.officeNumber,
            officeCountry: form.officeCountry,
            officeTimezoneId: form.officeTimezone,
            isClientActive: form.isClientActive
        };
        addOffice(newOffice);
        setState({
            officeName: '',
            officeNumber: '',
            officeCountry: '',
            officeTimezoneId: '',
            isClientActive: false
        });
        document.getElementById('office-form').reset();
        document.getElementById('office-active').checked = false;
    };

    const updateField = (event) => {
        setState({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    return (
        <form className={'form pb-0'} id={'office-form'} onSubmit={(event) => handleSubmit(event)}>
            <input required className={'mb-1'} type={'text'} id={'office-name'} name={'officeName'} value={form.officeName} placeholder={'Office Name'}
                   onChange={(event) => updateField(event)}/>
            <input required className={'mb-1'} type={'text'} id={'office-number'} name={'officeNumber'} value={form.officeNumber} placeholder={'Office Code'}
                   maxLength={4} pattern={'^[^s]{4}$'} title={'Four-character office code'}
                   onChange={(event) => updateField(event)}/>
            <select required className={'mb-1'} id={'office-country'} name={'officeCountry'} value={form.officeCountry}
                    onChange={(event) => updateField(event)}>
                <option value={''}>Select a Country</option>
                {AVAILABLE_INTERNATIONAL_COUNTRIES.map((country, index) => (
                    <option key={index} value={country.code}>{country.name} ({country.code})</option>)
                )}
            </select>
            <select required className={'mb-1'} id={'office-timezone'} name={'officeTimezone'} value={form.officeTimezone}
                    style={{maxWidth: '250px'}} onChange={(event) => updateField(event)}>
                <option value={''}>Select a Timezone</option>
                {timezones.map((zone, index) => (
                    <option key={index} value={zone.id}>{zone.timezoneId} ({zone.timezoneDisplayName})</option>))}
            </select>
            <input type={'checkbox'} id={'office-active'} name={'isClientActive'} value={'true'} className={'ml-2 mb-1'}
                   onChange={(event) => updateField(event)}/><label>Active</label>
            <button className={'ml-4 mb-1 btn btn--primary'} type={'submit'} value={'Submit'}>Create Office</button>
        </form>
    );
}

export default AddOffice;