import * as Constants from './Constants';
import {fabric} from 'fabric';
import * as DateUtils from './DateUtils';
import {getOverlappingReservations, getOverlappingReservationsForSpan} from './ReservationUtils';

/**
 * Create a seat group for use on a Fabric canvas
 * @param isUserDisplay Boolean representing whether this is user-facing
 * @param seatText The text to be displayed on the seat group
 * @param seatMapJson The attributes identifying where the seat group is placed
 * @param seatId The space ID
 * @returns {*} Fabric group representing a seat
 */
function createFabricSeat(isUserDisplay, seatText, seatMapJson, seatId) {
    const fabricText = new fabric.Textbox(seatText, {
        fontFamily: 'Guardian Sans',
        fontSize: 14,
        originX: 'center',
        originY: 'center',
        textAlign: 'center',
        textBackgroundColor: null,
        breakWords: true
    });
    const fabricRectangle = new fabric.Rect({
        fill: Constants.LIBERTY_TEAL,
        height: seatMapJson.height !== '' ? seatMapJson.height : fabricText.height + 30,
        opacity: 0.8,
        originX: 'center',
        originY: 'center',
        stroke: Constants.LIBERTY_DARK_GRAY,
        width: seatMapJson.width !== '' ? seatMapJson.width : fabricText.width + 10
    });
    resizeSeatText(fabricText, fabricRectangle);

    const fabricGroup = new fabric.Group([fabricRectangle, fabricText], {
        angle: seatMapJson.angle,
        borderColor: isUserDisplay ? Constants.LIBERTY_TEAL : Constants.LIBERTY_YELLOW,
        cornerColor: isUserDisplay ? Constants.LIBERTY_TEAL : Constants.LIBERTY_YELLOW,
        cornerSize: 8,
        hasControls: isUserDisplay ? false : true,
        height: seatMapJson.height !== '' ? seatMapJson.height : fabricRectangle.height,
        hoverCursor: 'pointer',
        id: seatId,
        left: seatMapJson.left,
        lockMovementX: isUserDisplay ? true : false,
        lockMovementY: isUserDisplay ? true : false,
        lockRotation: isUserDisplay ? true : false,
        lockScalingX: isUserDisplay ? true : false,
        lockScalingY: isUserDisplay ? true : false,
        scaleX: seatMapJson.scaleX,
        scaleY: seatMapJson.scaleY,
        selectable: isUserDisplay ? false : true,
        top: seatMapJson.top,
        transparentCorners: false,
        width: seatMapJson.width !== '' ? seatMapJson.width : fabricRectangle.width
    });
    return fabricGroup;
}

/**
 * Determine whether the canvas was dragged
 * @param canvas The canvas that was dragged
 * @param dragViewportStart The viewport of the canvas
 */
function didCanvasDrag(canvas, dragViewportStart) {
    const isViewportDifferent =
        dragViewportStart[0] !== canvas.viewportTransform[4] ||
        dragViewportStart[1] !== canvas.viewportTransform[5];
    return isViewportDifferent;
}

/**
 * Drag the canvas
 * @param canvas The canvas to be dragged
 * @param event The event containing drag information
 */
function dragCanvas(canvas, event) {
    const clientX = event.clientX;
    const clientY = event.clientY;

    let vpt = canvas.viewportTransform;
    vpt[4] += clientX - canvas.lastPosX;
    vpt[5] += clientY - canvas.lastPosY;
    canvas.requestRenderAll();
    canvas.lastPosX = clientX;
    canvas.lastPosY = clientY;
}

/**
 * Update fill and text for a seat (Fabric group)
 * Set helpful information in the seat object to reduce redundant logic
 * @param fabricSeatGroup The Fabric object representing a seat
 * @param selectedDates The user's selected dates
 * @param selectedHours The user's selected hours
 * @param seat The seat entity to format
 * @param user The current user
 * @param canvas The canvas to add partial availability indicator to if necessary
 * @param hasAccessibility The boolean to change color of seat's display based on user toggle
 */
function formatFabricSeat(fabricSeatGroup, selectedDates, selectedHours, seat, user, canvas, hasAccessibility) {
    let isFullyAvailable, isPartiallyAvailable;

    // figure which days to check reservations for
    let daysToReserve = [];
    for (let d = new Date(selectedDates[0].valueOf()); d <= selectedDates[1]; d.setDate(d.getDate() + 1)) {
        daysToReserve.push(DateUtils.getYMDDate(d));
    }
    let reservationDates = seat.reservations.map(r => r.reservationDate);
    let unreservedDates = [];
    if (selectedHours[0] === 7 && selectedHours[1] === 17) {
        // get the intersection of reservation dates and days to reserve
        unreservedDates = daysToReserve.filter(d => !reservationDates.includes(d));
        // if the intersection is not the same as the days to reserve but it is greater than zero, the seat is partially available
        isPartiallyAvailable = unreservedDates.length && unreservedDates.length !== daysToReserve.length;
        // the same as the days to reserve would be completely available which we don't add an indicator for
        isFullyAvailable = unreservedDates.length === daysToReserve.length;
    } else {
        let partialAvailableCount = 0;
        for (let i = 0; i < daysToReserve.length; i++) {
            let dayReservations = seat.reservations.filter(r => r.reservationDate === daysToReserve[i]);
            if (dayReservations.length > 0) {
                let filteredReservations = getOverlappingReservations(dayReservations, selectedHours);
                // if there are no reservations for the day within the selected span of hours, that day is available
                partialAvailableCount += filteredReservations.length === 0 ? 1 : 0;
            } else {
                // if there are no reservations for the day, that day is available
                partialAvailableCount += 1;
            }
        }
        isPartiallyAvailable = partialAvailableCount === 0 ? false : partialAvailableCount < daysToReserve.length;
        // if there are partially available days/hours for every day to reserve, the seat is fully available
        isFullyAvailable = partialAvailableCount === daysToReserve.length;
    }

    canvas.remove(canvas.getObjects('group').find(g => g.partialAvailabilityId === seat.id));
    if (isPartiallyAvailable) {
        const fabricCircle = new fabric.Circle({
            fill: Constants.LIBERTY_ATMOSPHERIC_WHITE,
            originX: 'center',
            originY: 'center',
            radius: 13,
            stroke: Constants.LIBERTY_BLUE
        });
        const fabricImage = new fabric.Image(document.getElementById('partial-available-image'), {
            originX: 'center',
            originY: 'center',
            scaleX: 0.06,
            scaleY: 0.06
        });
        const fabricPartialAvailabilityGroup = new fabric.Group([fabricCircle, fabricImage], {
            hoverCursor: 'default',
            left: fabricSeatGroup.left,
            lockMovementX: true,
            lockMovementY: true,
            originX: 'center',
            originY: 'center',
            partialAvailabilityId: seat.id,
            selectable: false,
            top: fabricSeatGroup.top
        });
        canvas.add(fabricPartialAvailabilityGroup);
    }

    let fabricSeatRectangle = fabricSeatGroup.getObjects('rect')[0];
    let fabricSeatTextbox = fabricSeatGroup.getObjects('textbox')[0];
    // Reset textbox options
    fabricSeatTextbox.set({
        height: fabricSeatRectangle.height - 30,
        scaleX: 1,
        scaleY: 1,
        text: '',
        textBackgroundColor: null,
        width: fabricSeatRectangle.width - 10
    });

    seat.isFullyAvailable = isFullyAvailable;
    if (seat.reservations.length === 0 || isFullyAvailable) {
        fabricSeatRectangle.fill = (seat.assignedUserIds && !seat.assignedUserIds.includes(user.employeeId)) || (seat.isPrivateSpace && !user.isPrivateSpaceEligible) ? (!hasAccessibility ? Constants.LIBERTY_LIGHT_GRAY : Constants.LIBERTY_LIGHT_GRAY_ACCESSIBILITY) : (!hasAccessibility ? Constants.LIBERTY_TEAL : Constants.LIBERTY_TEAL_ACCESSIBILITY);
        fabricSeatRectangle.set('opacity', !hasAccessibility ? .8 : 100);
        fabricSeatTextbox.set('fill', ((seat.assignedUserIds && !seat.assignedUserIds.includes(user.employeeId)) || (seat.isPrivateSpace && !user.isPrivateSpaceEligible)) && hasAccessibility ? 'white' : 'black');
        fabricSeatTextbox.set('text', seat.assignedUserIds?.includes(user.employeeId) ? user.name : seat.nickname ? seat.nickname : seat.spaceNumber);
        seat.displayCurrentUser = false;
        seat.isSingleReserver = false;
    } else if (!isFullyAvailable) {
        if (seat.reservations.length === 1) {
            let isMyReservation = seat.reservations[0].employeeId.toUpperCase() === user.employeeId.toUpperCase();
            fabricSeatRectangle.fill = isMyReservation ? (!hasAccessibility ? Constants.LIBERTY_YELLOW : Constants.LIBERTY_YELLOW_ACCESSIBILITY) : (!hasAccessibility ? Constants.LIBERTY_LIGHT_GRAY : Constants.LIBERTY_LIGHT_GRAY_ACCESSIBILITY);
            fabricSeatRectangle.set('opacity', !hasAccessibility ? .8 : 100);
            fabricSeatTextbox.set('fill', !isMyReservation && hasAccessibility ? 'white' : 'black');
            fabricSeatTextbox.set('text', seat.reservations[0].employeeName);
            seat.displayCurrentUser = !isMyReservation;
            seat.isSingleReserver = true;
        } else {
            const reservationUsers = seat.reservations.map(r => r.employeeName);
            const uniqueReservationUsers = reservationUsers.reduce((previous, current) => {
                if (previous.indexOf(current) === -1) {
                    previous.push(current);
                }
                return previous;
            }, []);
            fabricSeatRectangle.fill = uniqueReservationUsers.includes(user.name) ? (!hasAccessibility ? Constants.LIBERTY_YELLOW : Constants.LIBERTY_YELLOW_ACCESSIBILITY) : (!hasAccessibility ? Constants.LIBERTY_LIGHT_GRAY : Constants.LIBERTY_LIGHT_GRAY_ACCESSIBILITY);
            const filteredReservations = getOverlappingReservationsForSpan(seat.reservations, selectedDates[0], selectedDates[1], selectedHours);
            fabricSeatTextbox.set('text', filteredReservations.length === 1 ? filteredReservations[0].employeeName : uniqueReservationUsers.length === 1 ? uniqueReservationUsers[0] : 'Shared');
            seat.displayCurrentUser = uniqueReservationUsers.length > 1;
            seat.isSingleReserver = uniqueReservationUsers.length === 1;
        }
    }
    resizeSeatText(fabricSeatTextbox, fabricSeatRectangle);
}

/**
 * Determine scale for image based on window width and apply it to canvas
 * @param canvas The canvas whose background will be adjusted
 * @param canvasWidth The new width of the canvas
 * @param mapImage The background image of the canvas
 */
function resizeCanvas(canvas, canvasWidth, mapImage) {
    const widthAspectRatio = canvasWidth / mapImage.width;
    canvas.setHeight(widthAspectRatio * mapImage.height);
    canvas.setZoom(widthAspectRatio);
    canvas.setBackgroundImage(mapImage, () => canvas.requestRenderAll());
}

/**
 * Resize textbox to fit within seat rectangle
 * @param fabricTextbox The Fabric object representing the text of a seat
 * @param fabricRectangle The Fabric object representing the seat
 */
function resizeSeatText(fabricTextbox, fabricRectangle) {
    const widthDiff = fabricTextbox.width - fabricRectangle.width;
    const heightDiff = fabricTextbox.height - fabricRectangle.height;
    if (widthDiff > 0 || heightDiff > 0) {
        // Text is larger than rectangle so slowly decrease it to fit
        while (fabricTextbox.height > fabricRectangle.height) {
            fabricTextbox.set({fontSize: fabricTextbox.fontSize - 1});
        }
        // If it is still too wide, scale it down
        if (fabricTextbox.width > fabricRectangle.width) {
            // Fabric quirk: downsizing font does not shrink width so we have to scale it to fit
            fabricTextbox.scaleToWidth(fabricRectangle.width);
        }
    } else {
        // Text is smaller than rectangle so slowly increase it to fit
        while (fabricTextbox.width < fabricRectangle.width && fabricTextbox.height < fabricRectangle.height) {
            fabricTextbox.set({fontSize: fabricTextbox.fontSize + 1});
        }
        // If the final font change bumped either height or width bigger, downsize by one
        if (fabricTextbox.width > fabricRectangle.width || fabricTextbox.height > fabricRectangle.height) {
            fabricTextbox.set({fontSize: fabricTextbox.fontSize - 1});
        }
    }
}

/**
 * Zoom canvas in using default scale factor
 * @param canvas The canvas that will be zoomed
 */
function zoomIn(canvas) {
    let zoom = canvas.getZoom() * Constants.MAP_SCALE_FACTOR;
    if (zoom > Constants.MAP_MAX_ZOOM) zoom = Constants.MAP_MAX_ZOOM;
    canvas.setZoom(zoom);
    canvas.requestRenderAll();
}

/**
 * Zoom canvas out using default scale factor
 * @param canvas The canvas that will be zoomed
 */
function zoomOut(canvas) {
    let zoom = canvas.getZoom() / Constants.MAP_SCALE_FACTOR;
    if (zoom < Constants.MAP_MIN_ZOOM) zoom = Constants.MAP_MIN_ZOOM;
    canvas.setZoom(zoom);
    canvas.requestRenderAll();
}

export {
    createFabricSeat,
    didCanvasDrag,
    dragCanvas,
    formatFabricSeat,
    resizeCanvas,
    zoomIn,
    zoomOut
};