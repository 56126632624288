import React, {useEffect, useState} from 'react';
import './Notification.css';
import CheckmarkSVG from '../../images/CheckmarkSVG';
import ErrorSVG from '../../images/ErrorSVG';
import InformationSVG from '../../images/InformationSVG';

function Notification({ id, notificationType, dismissable, dismissText, active, children, handleDismiss }) {
    const [display, setDisplay] = useState('none');
    const [top, setTop] = useState('0px');

    const dismiss = () => {
        setDisplay('none');
        handleDismiss();
    };

    useEffect(() => {
        setDisplay(active ? 'flex' : 'none');
        setTop(window.scrollY + 'px');
    }, [active]);

    window.onscroll = () => {
        setTop(window.scrollY + 'px');
    };

    return(
        <div id={id} className={`notification ${notificationType}`} style={{display: display, top: top}}>
            <div className={'cell icon'}>
                <InformationSVG width={16} height={16} title={'Informational Notification Icon'}/>
                <CheckmarkSVG width={16} height={16} title={'Success Notification Icon'}/>
                <ErrorSVG width={16} height={16} title={'Error Notification Icon'}/>
            </div>
            <div className={'cell content'}>
                <div className={'message'}>{children}</div>
            </div>
            <div className={'cell dismiss'} style={{display: (dismissable ? 'block' : 'none')}}>
                <button onClick={dismiss}>
                    <svg aria-label={dismissText} width={'16'} height={'16'} xmlns={'http://www.w3.org/2000/svg'} focusable={'false'} role={'img'} viewBox={'0 0 16 16'}>
                        <path d={'M15.358 0L8.078 7.282 0.796 0 0.765 0 0.074 0.692 7.37 7.988 0.074 15.285 0.781 15.992 8.078 8.696 15.373 15.992 16 15.366 16 15.204 8.784 7.988 16 0.773 16 0.611 15.389 0z'}></path>
                        <rect width={'100%'} height={'100%'} style={{opacity: 0}}>
                            <title>{dismissText}</title>
                        </rect>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Notification;