import React, { useState } from 'react';

function AddSpace({ office, floor, addSpace, existingSpaces }) {
    const [form, setState] = useState({
        spaceNumber: '',
        isClientActive: false
    });

    const handleSubmit = e => {
        e.preventDefault();
        let isActive = form.isClientActive;
        const newSpace = {
            spaceNumber: form.spaceNumber,
            isClientActive: isActive,
            officeId: office.id,
            floorId: floor.id
        };
        if (existingSpaces.findIndex(s => s.spaceNumber.toUpperCase() === newSpace.spaceNumber.toUpperCase()) >= 0) {
            alert('A space with that number already exists');
            document.getElementById('space-number').focus();
        } else {
            addSpace(newSpace);
            setState({
                spaceNumber: '',
                isClientActive: false
            });
            document.getElementById('space-form').reset();
            document.getElementById('space-active').checked = false;
        }
    }

    const updateField = e => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    return (
        <form className='form' id='space-form' onSubmit={handleSubmit}>
            <input required type='text' id='space-number' name='spaceNumber'
                   value={form.spaceNumber} placeholder='Enter Space Number' onChange={updateField} />
            <input type='checkbox' id='space-active' name='isClientActive' value='true' onChange={updateField} className={'ml-2'}/><label>Active</label>
            <button className='ml-4 mb-1 btn btn--primary' type='submit' value='Submit'>Create Space</button>
        </form>
    )
}

export default AddSpace;