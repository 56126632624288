import React from 'react';
import {Link} from 'react-router-dom';
import international_logo from '../../../images/international.png';
import us_logo from '../../../images/usa.png';
import exceptions_logo from '../../../images/exceptions_logo.png';

function OfficeChoice() {
    return (
        <React.Fragment>
            <div id={'admin-home'} className={'pt-5'}>
                <span>
                    <Link to={{pathname: '/admin/manage-offices', state: {isIntl: false}}}><img src={us_logo} alt={'US Logo'} /></Link>
                    <h3>US</h3>
                </span>
                <span>
                    <Link to={{pathname: '/admin/manage-offices', state: {isIntl: true}}}><img src={international_logo} alt={'International Logo'} /></Link>
                    <h3>International</h3>
                </span>
                <span>
                    <Link to={{pathname: '/admin/manage-space-exceptions'}}><img src={exceptions_logo} alt={'Exceptions Logo'} /></Link>
                    <h3>Exceptions</h3>
                </span>
            </div>
        </React.Fragment>
    );
}

export default OfficeChoice;