import React, {useEffect, useState} from 'react';
import AxiosRequest from '../../../utilities/AxiosRequest';
import Notification from '../../standard/Notification';
import {Link} from 'react-router-dom';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import {TextField} from '@material-ui/core';
import * as DateUtils from '../../../utilities/DateUtils';
import trash_logo from '../../../images/trash_logo.png';
import AddException from '../AddException';
import {EXCEPTION_TYPE} from '../../../utilities/Constants';

function SpaceExceptions() {
    const [exceptions, setExceptions] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const exceptionsUrl = '/api/v1/users/exceptions';

    useEffect(() => {
        const getExceptions = async () => {
            try {
                const response = await AxiosRequest.get(exceptionsUrl + `?exceptionType=${EXCEPTION_TYPE.SPACE}`);
                if (response.status === 200) {
                    setExceptions(response.data);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving space eligibility exceptions list');
                }
            } catch (err) {
                setError(true);
                setErrorMessage('An error occurred retrieving space eligibility exceptions list');
            }
        };

        getExceptions();
    }, []);

    const addSpaceException = async (addSpaceException) => {
        try {
            resetNotifications();
            const existingException = exceptions.findIndex(exception => exception.employeeId.toUpperCase() === addSpaceException.employeeId.toUpperCase());
            if (existingException >= 0) {
                setError(true);
                setErrorMessage('That user exception already exists.  Please modify the existing record.');
            } else {
                const response = await AxiosRequest.post(exceptionsUrl + `?exceptionType=${EXCEPTION_TYPE.SPACE}`, addSpaceException);
                if (response.status === 201) {
                    const newExceptions = [...exceptions, response.data];
                    setExceptions(newExceptions);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred adding space exception');
                }
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred adding space exception');
        }
    };

    const editSpaceException = async (updatedException) => {
        try {
            resetNotifications();
            const newExceptions = [...exceptions];
            const editIndex = newExceptions.findIndex(exception => exception.id === updatedException.id);
            newExceptions[editIndex] = updatedException;
            const response = await AxiosRequest.put(exceptionsUrl + '/' + updatedException.id + `?exceptionType=${EXCEPTION_TYPE.SPACE}`, newExceptions[editIndex]);
            if (response.status === 200) {
                setExceptions(newExceptions);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing the space exception for user: ' + updatedException.name);
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a space exception');
        }
    };

    const deleteSpaceException = async (id) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(exceptionsUrl + '/' + id + `?exceptionType=${EXCEPTION_TYPE.SPACE}`);
            if (response.status === 204) {
                const newExceptions = exceptions.filter((exception) => exception.id !== id);
                setExceptions(newExceptions);
            } else {
                setError(true);
                setErrorMessage('An error occurred removing a space exception');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred removing a space exception');
        }
    };

    const editExceptionExpiration = (exception, date) => {
        exception.spaceExceptionExpirationDate = date;
        editSpaceException(exception);
    };

    const resetNotifications = () => {
        setErrorMessage('');
        setError(false);
    };

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/admin'}>Home</Link> &gt; <Link to={'/admin/manage-office-choice/'}>Office Administration</Link>
                &nbsp;&gt; Exceptions
            </div>
            <section>
                <h1>Space Reservation Exceptions</h1>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true
                        }}
                        columns={[
                            {title: 'Name', field: 'name', sorting: true},
                            {title: 'Employee ID', field: 'employeeId',
                                customFilterAndSearch: (term, admin) => (admin.employeeId).toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.employeeId).localeCompare((b.employeeId))},
                            {title: 'Expiration Date',
                                render: (exception) =>
                                    <React.Fragment>
                                        <TextField
                                            type={'date'}
                                            id={`expiration-date-${exception.id}`}
                                            name={`expiration-date-${exception.id}`}
                                            style={{backgroundColor: 'white'}}
                                            value={exception.spaceExceptionExpirationDate}
                                            InputProps={{
                                                className: 'date-field',
                                                disableUnderline: true
                                            }}
                                            inputProps={{
                                                min: DateUtils.getYMDDate(new Date())
                                            }}
                                            onChange={(event) => {
                                                editExceptionExpiration(exception, event.target.value)
                                            }}
                                        />
                                        <label htmlFor={`expiration-date-${exception.id}`} className={'offscreen-label'}>
                                            Expiration date for {exception.name}
                                        </label>
                                    </React.Fragment>,
                                customFilterAndSearch: (term, exception) => {
                                    return exception.spaceExceptionExpirationDate.indexOf(term) !== -1
                                },
                                customSort: (a, b) => {
                                    return DateUtils.parseDate(a.spaceExceptionExpirationDate) - DateUtils.parseDate(b.spaceExceptionExpirationDate);
                                }
                            },
                            {title: 'Date Added',
                                render: (exception) => <span>{DateUtils.getYMDDate(new Date(exception.spaceExceptionCreatedDate))}</span>,
                                customFilterAndSearch: (term, exception) => {
                                    return DateUtils.getYMDDate(new Date(exception.spaceExceptionCreatedDate)).indexOf(term) !== -1
                                },
                                customSort: (a, b) => {
                                    return new Date(a.spaceExceptionCreatedDate) - new Date(b.spaceExceptionCreatedDate);
                                }
                            },
                            {title: 'Actions', sorting: false, searchable: false, align: 'center',
                                render: (exception) => <button className='btn logo-btn' onClick={() => deleteSpaceException(exception.id)}><img alt={'Trash Logo'} src={trash_logo}/></button>}
                        ]}
                        data={exceptions}
                        components={{
                            Toolbar: (props) => (
                                <div className={'row p-0 m-0 align-items-end'}>
                                    <div className={'col-9 p-0'}><AddException addException={addSpaceException} exceptionType={EXCEPTION_TYPE.SPACE}/></div>
                                    <div className={'col-3 p-0'}><MTableToolbar {...props} /></div>
                                </div>
                            ),
                        }}
                    />
                </div>
            </section>
        </React.Fragment>
    );
}

export default SpaceExceptions;