import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import offices_logo from '../../images/offices.png';
import admins_logo from '../../images/admins.png';
import parking_logo from '../../images/auto.png';
import UserContext from '../../context/UserContext';

function AdminHome() {
    const user = useContext(UserContext);

    return(
        <div id={'admin-home'} className={'pt-5'}>
            { user.isSuperAdmin
                ?   <span>
                    <Link to='/admin/manage-admins'><img src={admins_logo} alt={'Admins Logo'} /></Link>
                    <h3>Administrators</h3>
                </span>
                : null }
            { user.isOfficeAdmin
                ?   <span>
                    <Link to='/admin/manage-office-choice'><img src={offices_logo} alt={'Offices Logo'} /></Link>
                    <h3>Offices</h3>
                </span>
                : null }
            { user.isParkingAdmin
                ?   <span>
                    <Link to='/admin/manage-parking'><img src={parking_logo} alt={'Parking Logo'} /></Link>
                    <h3>Parking</h3>
                </span>
                : null }
        </div>
    );
}

export default AdminHome;