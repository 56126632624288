import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AxiosRequest from './utilities/AxiosRequest';
import Header from './components/standard/Header';
import Footer from './components/standard/Footer';
import AdminHome from './components/administration/AdminHome';
import Admins from './components/administration/Admin/Admins';
import Offices from './components/administration/Office/Offices';
import OfficeChoice from './components/administration/Office/OfficeChoice';
import Spaces from './components/administration/Space/Spaces';
import Home from './components/home/Home';
import Floors from './components/administration/Floor/Floors';
import Notifications from './components/preferences/Notifications';
import MyGroups from './components/preferences/MyGroups';
import FloorMap from './components/administration/FloorMap/FloorMap';
import ReserveCalendar from './components/home/ReserveCalendar';
import PrivateRoute from './components/standard/PrivateRoute';
import UserContext from './context/UserContext';
import ReserveFloors from './components/home/ReserveFloors';
import ReserveSpace from './components/home/ReserveSpace';
import ParkingChoice from './components/administration/Parking/ParkingChoice';
import ParkingOffices from './components/administration/Parking/ParkingOffices';
import SecurityGuards from './components/administration/Parking/SecurityGuards';
import ParkingExceptions from './components/administration/Parking/ParkingExceptions';
import ParkingReservations from './components/home/ParkingReservations';
import ReserveParking from './components/home/ReserveParking';
import ReservationDashboard from './components/home/ReservationDashboard';
import SpaceExceptions from './components/administration/Office/SpaceExceptions';

function App() {
    const [user, setUser] = useState({
        name: '',
        employeeId: '',
        isOfficeAdmin: false,
        isSuperAdmin: false,
        isParkingAdmin: false,
        isSecurityGuard: false,
        id: '',
        email: '',
        firstName: ''
    });

    useEffect(() => {
        let didCancel = false;

        async function initUser() {
            try {
                const result = await AxiosRequest.get('/api/v1/users/user');
                if (!didCancel) {
                    setUser(prevUser => ({
                        ...prevUser,
                        employeeId: result.data.employeeId,
                        name: result.data.name,
                        isOfficeAdmin: result.data.isOfficeAdmin,
                        isSuperAdmin: result.data.isSuperAdmin,
                        isParkingAdmin: result.data.isParkingAdmin,
                        isSecurityGuard: result.data.isSecurityGuard,
                        id: result.data.id,
                        email: result.data.email,
                        firstName: result.data.firstName
                    }));
                }
            } catch (ex) {
                console.error(ex);
            }
        }
        initUser();

        return () => {
            didCancel = true;
        };
    }, []);

    return (
        <Router>
            <UserContext.Provider value={user}>
                <div id='page' className={'d-flex flex-column h-100'}>
                    <Header/>
                    <div id='body'>
                        <Switch>
                            <Route exact path='/'><Home user={user}/></Route>
                            <PrivateRoute exact path='/admin' allowed={user.isOfficeAdmin || user.isSuperAdmin || user.isParkingAdmin}
                                          component={AdminHome}/>
                            <PrivateRoute exact path='/admin/manage-office-choice' allowed={user.isOfficeAdmin}
                                          component={OfficeChoice}/>
                            <PrivateRoute exact path='/admin/manage-offices' allowed={user.isOfficeAdmin}
                                          component={Offices}/>
                            <PrivateRoute exact path='/admin/manage-space-exceptions' allowed={user.isOfficeAdmin}
                                          component={SpaceExceptions}/>
                            <PrivateRoute exact path='/admin/manage-floors' allowed={user.isOfficeAdmin}
                                          component={Floors}/>
                            <PrivateRoute exact path='/admin/manage-spaces' allowed={user.isOfficeAdmin}
                                          component={Spaces}/>
                            <PrivateRoute exact path='/admin/manage-admins' allowed={user.isSuperAdmin}
                                          component={Admins}/>
                            <PrivateRoute exact path='/admin/floor-map' allowed={user.isOfficeAdmin}
                                          component={FloorMap}/>
                            <PrivateRoute exact path={'/admin/manage-parking'} allowed={user.isParkingAdmin}
                                          component={ParkingChoice}/>
                            <PrivateRoute exact path={'/admin/manage-parking-offices'} allowed={user.isParkingAdmin}
                                          component={ParkingOffices}/>
                            <PrivateRoute exact path={'/admin/manage-security-guards'} allowed={user.isParkingAdmin}
                                          component={SecurityGuards}/>
                            <PrivateRoute exact path={'/admin/manage-parking-exceptions'} allowed={user.isParkingAdmin}
                                          component={ParkingExceptions}/>
                            <PrivateRoute exact path={'/parking-reservations'} allowed={user.isSecurityGuard}
                                          component={ParkingReservations}/>
                            <Route exact path='/notification-preferences' component={Notifications}/>
                            <Route exact path='/group-preferences'><MyGroups user={user}/></Route>
                            <Route exact path='/reserve-calendar' component={ReserveCalendar}/>
                            <Route exact path='/reserve-floors' component={ReserveFloors}/>
                            <Route exact path='/reserve-space' component={ReserveSpace}/>
                            <Route exact path='/cancel-reservation'><Home user={user}/></Route>
                            <Route exact path='/reserve-parking' component={ReserveParking}/>
                            <Route exact path='/reservation-dashboard' component={ReservationDashboard}/>
                        </Switch>
                    </div>
                    <Footer/>
                </div>
            </UserContext.Provider>
        </Router>
    );
}

export default App;