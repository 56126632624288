import React from 'react';

function CheckmarkSVG({width, height, title}) {
    return (
        <svg className={'success-icon'} aria-label={title} width={width} height={height} xmlns={'http://www.w3.org/2000/svg'} focusable={'false'} role={'img'} viewBox={'0 0 16 16'}>
            <path d={'M8.0007,0.0044 C12.4087,0.0044 15.9947,3.5914 15.9947,8.0004 C15.9947,12.4084 12.4087,15.9954 8.0007,15.9954 C5.8677,15.9954 3.8607,15.1634 2.3497,13.6514 C0.8377,12.1394 0.0047,10.1324 0.0047,8.0004 C0.0047,3.5914 3.5917,0.0044 8.0007,0.0044 Z M8.0007,1.0044 C4.1437,1.0044 1.0047,4.1424 1.0047,8.0004 C1.0047,11.8574 4.1437,14.9954 8.0007,14.9954 C11.8577,14.9954 14.9947,11.8574 14.9947,8.0004 C14.9947,4.1424 11.8577,1.0044 8.0007,1.0044 Z M11.901,4.9673 L12.611,5.6733 L6.486,11.8403 L3.404,8.8013 L4.107,8.0893 L6.478,10.4283 L11.901,4.9673 Z'} fill={'var(--success-text)'}></path>
            <rect width={'100%'} height={'100%'} style={{opacity: 0}}>
                <title>{title}</title>
            </rect>
        </svg>
    );
}

export default CheckmarkSVG;