import React, {useEffect, useState} from 'react';
import AddException from '../AddException';
import AxiosRequest from '../../../utilities/AxiosRequest';
import {Link} from 'react-router-dom';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import Notification from '../../standard/Notification';
import trash_logo from '../../../images/trash_logo.png';
import * as DateUtils from '../../../utilities/DateUtils';
import {TextField} from '@material-ui/core';
import {EXCEPTION_TYPE} from '../../../utilities/Constants';

function ParkingExceptions() {
    const [exceptions, setExceptions] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const exceptionsUrl = '/api/v1/users/exceptions';

    useEffect(() => {
        resetNotifications();

        const getExceptions = async () => {
            try {
                const response = await AxiosRequest.get(exceptionsUrl + `?exceptionType=${EXCEPTION_TYPE.PARKING}`);
                if (response.status === 200) {
                    setExceptions(response.data);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving parking exceptions list');
                }
            } catch (err) {
                setError(true);
                setErrorMessage('An error occurred retrieving parking exceptions list');
            }
        };

        getExceptions();
    }, []);

    const addParkingException = async (addParkingException) => {
        try {
            resetNotifications();
            const existingException = exceptions.findIndex(exception => exception.employeeId.toUpperCase() === addParkingException.employeeId.toUpperCase());
            if (existingException >= 0) {
                setError(true);
                setErrorMessage('That user exception already exists.  Please modify the existing record.');
            } else {
                const response = await AxiosRequest.post(exceptionsUrl + `?exceptionType=${EXCEPTION_TYPE.PARKING}`, addParkingException);
                if (response.status === 201) {
                    const newExceptions = [...exceptions, response.data];
                    setExceptions(newExceptions);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred adding parking exception');
                }
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred adding parking exception');
        }
    };

    const editParkingException = async (updatedException) => {
        try {
            resetNotifications();
            const newExceptions = [...exceptions];
            const editIndex = newExceptions.findIndex(exception => exception.id === updatedException.id);
            newExceptions[editIndex] = updatedException;
            const response = await AxiosRequest.put(exceptionsUrl + '/' + updatedException.id + `?exceptionType=${EXCEPTION_TYPE.PARKING}`, newExceptions[editIndex]);
            if (response.status === 200) {
                setExceptions(newExceptions);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing the parking exception for user: ' + updatedException.name);
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a parking exception');
        }
    };

    const deleteParkingException = async (id) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(exceptionsUrl + '/' + id + `?exceptionType=${EXCEPTION_TYPE.PARKING}`);
            if (response.status === 204) {
                const newExceptions = exceptions.filter((exception) => exception.id !== id);
                setExceptions(newExceptions);
            } else {
                setError(true);
                setErrorMessage('An error occurred removing a parking exception');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred removing a parking exception');
        }
    };

    const editExceptionExpiration = (exception, date) => {
        exception.parkingExceptionExpirationDate = date;
        editParkingException(exception);
    };

    const resetNotifications = () => {
        setErrorMessage('');
        setError(false);
    };

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/admin'}>Home</Link> &gt; <Link to={'/admin/manage-parking/'}>Parking Administration</Link>
                &nbsp;&gt; Exceptions
            </div>
            <section>
                <h1>Parking Exceptions</h1>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true
                        }}
                        columns={[
                            {title: 'Name', field: 'name', sorting: true},
                            {title: 'Employee ID', field: 'employeeId',
                                customFilterAndSearch: (term, admin) => (admin.employeeId).toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.employeeId).localeCompare((b.employeeId))},
                            {title: 'Expiration Date',
                                render: (exception) =>
                                    <React.Fragment>
                                        <TextField
                                            type={'date'}
                                            id={`expiration-date-${exception.id}`}
                                            name={`expiration-date-${exception.id}`}
                                            style={{backgroundColor: 'white'}}
                                            value={exception.parkingExceptionExpirationDate}
                                            InputProps={{
                                                className: 'date-field',
                                                disableUnderline: true
                                            }}
                                            inputProps={{
                                                min: DateUtils.getYMDDate(new Date())
                                            }}
                                            onChange={(event) => {
                                                editExceptionExpiration(exception, event.target.value)
                                            }}
                                        />
                                        <label htmlFor={`expiration-date-${exception.id}`} className={'offscreen-label'} >
                                            Expiration date for {exception.name}
                                        </label>
                                    </React.Fragment>,
                                customFilterAndSearch: (term, exception) => {
                                    return exception.parkingExceptionExpirationDate.indexOf(term) !== -1
                                },
                                customSort: (a, b) => {
                                    return DateUtils.parseDate(a.parkingExceptionExpirationDate) - DateUtils.parseDate(b.parkingExceptionExpirationDate);
                                }
                            },
                            {title: 'Date Added',
                                render: (exception) =>  <span>{DateUtils.getYMDDate(new Date(exception.parkingExceptionCreatedDate))}</span>,
                                customFilterAndSearch: (term, exception) => {
                                    return DateUtils.getYMDDate(new Date(exception.parkingExceptionCreatedDate)).indexOf(term) !== -1
                                },
                                customSort: (a, b) => {
                                    return new Date(a.parkingExceptionCreatedDate) - new Date(b.parkingExceptionCreatedDate);
                                }
                            },
                            {title: 'Actions', sorting: false, searchable: false, align: 'center',
                                render: (exception) => <button className='btn logo-btn' onClick={() => deleteParkingException(exception.id)}><img alt={'Trash Logo'} src={trash_logo}/></button>}
                        ]}
                        data={exceptions}
                        components={{
                            Toolbar: (props) => (
                                <div className={'row p-0 m-0 align-items-end'}>
                                    <div className={'col-9 p-0'}><AddException addException={addParkingException} exceptionType={EXCEPTION_TYPE.PARKING}/></div>
                                    <div className={'col-3 p-0'}><MTableToolbar {...props} /></div>
                                </div>
                            ),
                        }}
                    />
                </div>
            </section>
        </React.Fragment>
    );
}

export default ParkingExceptions;