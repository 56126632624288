import React, {useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import GraphUtils from '../../utilities/GraphUtils';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../css/Typeahead.css';

function UserSearch({setSearchResults, placeholderText, defaultTextDisplay, blurMethod, isDisabled = false}) {
    const [isSearching, setIsSearching] = useState(false);
    const [matchingUsers, setMatchingUsers] = useState([]);

    const searchUsers = async (searchText) => {
        if (searchText.length > 3) {
            setIsSearching(true);
            searchText = searchText.replace(/'/g, `''`);
            const graphUsers = await GraphUtils.searchUserByNameOrId(searchText);
            setMatchingUsers(graphUsers);
            setIsSearching(false);
        }
    };

    return(
        <React.Fragment>
            <AsyncTypeahead
                clearButton={true}
                defaultInputValue={defaultTextDisplay}
                id={'user-search'}
                isLoading={isSearching}
                filterBy={() => true}
                inputProps={{required: true}}
                labelKey={(option) => `${option.displayName} (${option.jobTitle})`}
                onBlur={(e) => {if(typeof(blurMethod) === 'function') blurMethod(e.target.value)}}
                onChange={setSearchResults}
                onSearch={(searchString) => searchUsers(searchString)}
                options={matchingUsers}
                disabled={isDisabled}
                placeholder={placeholderText}
            />
        </React.Fragment>
    );
}

export default UserSearch;