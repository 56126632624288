import React, {useEffect, useState} from 'react';
import AxiosRequest from '../../../utilities/AxiosRequest';
import {Link} from 'react-router-dom';
import AddSpace from '../Space/AddSpace';
import Notification from '../../standard/Notification';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import trash_logo from '../../../images/trash_logo.png';

function Spaces(props) {
    const [currentFloor, setCurrentFloor] = useState(props.location.state.floor);
    const [currentOffice, setCurrentOffice] = useState(props.location.state.office);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [spaces, setSpaces] = useState([]);
    const url = '/api/v1/spaces';

    useEffect(() => {
        resetNotifications();
        setCurrentFloor(props.location.state.floor);
        setCurrentOffice(props.location.state.office);

        const getSpaces = async () => {
            try {
                const response = await AxiosRequest.get('/api/v1/offices/' + props.location.state.office.id + '/floors/' + props.location.state.floor.id + '/spaces');
                if (response.status === 200) {
                    setSpaces(response.data);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving seat list');
                }
            } catch (err) {
                setError(true);
                setErrorMessage('An error occurred retrieving seat list');
            }
        };
        getSpaces();
    }, [props]);

    const editSpaceActive = async (id) => {
        try {
            resetNotifications();
            const newSpaces = [...spaces];
            const editIndex = newSpaces.findIndex((space => space.id === id));
            newSpaces[editIndex].isClientActive = !newSpaces[editIndex].isClientActive;
            const response = await AxiosRequest.put(url + '/' + id, newSpaces[editIndex]);
            if (response.status === 200) {
                setSpaces(newSpaces);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing a seat');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a seat');
        }
    };

    const editSpaceNickname = async (id, nickname) => {
        try {
            resetNotifications();
            const newSpaces = [...spaces];
            const editIndex = newSpaces.findIndex((space => space.id === id));
            newSpaces[editIndex].nickname = nickname;
            const response = await AxiosRequest.put(url + '/' + id, newSpaces[editIndex]);
            if (response.status === 200) {
                setSpaces(newSpaces);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing a seat');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred editing a seat');
        }
    };

    const addSpace = async (space) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.post(url, space);
            if (response.status === 201) {
                const newSpaces = [...spaces, response.data];
                setSpaces(newSpaces);
            } else {
                setError(true);
                setErrorMessage('An error occurred adding a seat');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred adding a seat');
        }
    };

    const removeSpace = async (id) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(url + '/' + id);
            if (response.status === 204) {
                const newSpaces = spaces.filter((space) => space.id !== id);
                setSpaces(newSpaces);
            } else {
                setError(true);
                setErrorMessage('An error occurred deleting a seat');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred deleting a seat');
        }
    };

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    }

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/admin'}>Home</Link> &gt; <Link to={'/admin/manage-office-choice'}>Offices</Link>
                &nbsp;&gt;&nbsp;
                <Link to={{pathname: '/admin/manage-offices', state: {isIntl: currentOffice.officeCountry === 'US' ? false : true}}}>
                    {currentOffice.officeCountry === 'US' ? 'US' : 'International'} Offices
                </Link>
                &nbsp;&gt;&nbsp;
                <Link to={{pathname: '/admin/manage-floors', state: {office: currentOffice, floor: currentFloor}}}>
                    {currentOffice.nickname ? currentOffice.nickname : currentOffice.officeName} ({currentOffice.officeNumber})
                </Link>
                &nbsp;&gt;&nbsp;
                {currentFloor.nickname ? currentFloor.nickname : currentFloor.floorNumber}
            </div>
            <section>
                <div className={'container-fluid mx-0 px-0'}>
                    <div className='row'>
                        <div className={'col-9'}>
                            <h1>Seat Management</h1>
                        </div>
                        {currentFloor.hasMap ?
                            <div className='col-3 text-right float-right'>
                                <Link to={{pathname: '/admin/floor-map', state: {office: currentOffice, floor: currentFloor}}}>
                                    <button className='btn btn--primary mt-2'>Floor Map</button>
                                </Link>
                            </div>
                            : ''
                        }
                    </div>
                </div>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true,
                        }}
                        columns={[
                            {title: 'Nickname',
                                render: (space) => <input type='text' size='35' defaultValue={space.nickname ? space.nickname : space.spaceNumber}
                                                      onBlur={(e) => editSpaceNickname(space.id, e.target.value)}></input>,
                                customFilterAndSearch: (term, space) => (space.nickname !== null ? space.nickname : '').toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.nickname ? a.nickname : a.spaceNumber).toUpperCase().localeCompare((b.nickname ? b.nickname : b.spaceNumber).toUpperCase())},
                            {title: 'Number', field: 'spaceNumber'},
                            {title: 'Status / Type', hidden: currentOffice.officeCountry !== 'US',
                                render: (space) => <>{space.spaceStatus} / {space.spaceType}</>,
                                customFilterAndSearch: (term, space) => (space.spaceStatus + ' / ' + space.spaceType).toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.spaceStatus + ' ' + a.spaceType).toUpperCase().localeCompare((b.spaceStatus + ' ' + b.spaceType).toUpperCase())},
                            {title: 'Assigned To', hidden: currentOffice.officeCountry !== 'US', render: (space) => space.assignedUserIds ? space.assignedUserIds.join(', ') : '',
                                customFilterAndSearch: (term, space) => ((space.assignedUserIds ? space.assignedUserIds.join(', ') : '').toUpperCase()).indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.assignedUserIds ? a.assignedUserIds.join(', ') : '').toUpperCase().localeCompare((b.assignedUserIds ? b.assignedUserIds.join(', ') : '').toUpperCase())},
                            {title: 'Active', searchable: false, align: 'center',
                                render: (space) => <input type='checkbox' defaultChecked={space.isClientActive} onChange={() => editSpaceActive(space.id)}/>,
                                customSort: (a, b) => a.isClientActive - b.isClientActive},
                            {title: 'Actions', hidden: currentOffice.officeCountry === 'US', sorting: false, searchable: false, align: 'center',
                                render: (space) => <div className='text-nowrap'>
                                    <button className='btn logo-btn' onClick={() => {if (window.confirm('Delete space?')) removeSpace(space.id)}}><img alt={'Delete space'} src={trash_logo}/></button></div>}
                        ]}
                        data={spaces}
                        components={{
                            Toolbar: (props) => (
                                <div className={'row p-0 m-0'}>
                                    <div className={'col-9 p-0 m-0'}>{currentOffice.officeCountry !== 'US' ? <AddSpace office={currentOffice} floor={currentFloor} addSpace={addSpace} existingSpaces={spaces}/> : null}</div>
                                    <div className={'col-3 p-0 m-0'}><MTableToolbar {...props} /></div>
                                </div>
                            ),
                        }}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default Spaces;