import React, {useState} from 'react';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import {RESERVATION_TYPE} from '../../utilities/Constants';
import {Link} from 'react-router-dom';
import * as DateUtils from '../../utilities/DateUtils';
import trash_logo from '../../images/trash_logo.png';
import caret_up_image from '../../images/caret_up.png';
import caret_down_image from '../../images/caret_down.png';
import location_png from '../../images/location.png';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import InfoSVG from '../../images/InformationSVG';
import pen_logo from '../../images/pen_rgb.png';
import missing_vehicle_logo from '../../images/caution.png';
import {createParkingCalendarInvite, createSpaceCalendarInvite} from '../../utilities/EmailCalendarUtils';
import download_invite from '../../images/confirmed_date.png';

function ReservationTable({reservations, title, isUserReservation, isGroupReservation, userReservations, cancelReservation, groups}) {
    const groupHelpText = 'Create group(s) in Preferences to identify when your peers are in the office so you can collaborate with them.  Each member can opt in to share their reservation information with you.';
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <React.Fragment>
            <div className={'p-3'}>
                <button className={'collapse-caret pb-1'} data-toggle={'collapse'}
                        data-target={`#${title.toLowerCase().replace(/ /g, '-').concat('-group')}`}
                        onClick={() => setIsCollapsed(!isCollapsed)}>
                    <img src={isCollapsed ? caret_up_image : caret_down_image} alt={'collapse'}
                         style={{height: '1em', verticalAlign: 'baseline'}} className={'pr-2'} />
                </button>
                <h4 className={'d-inline-block'}>{title}
                    { isGroupReservation ? <OverlayTrigger placement={'top'} delay={{show: 250, hide: 400}} overlay={<Tooltip id={'info-tooltip'}>{groupHelpText}</Tooltip>}>
                        <button className={'pl-1 btn btn-popover'}><InfoSVG height={14}/></button>
                    </OverlayTrigger> : null }
                </h4>
            </div>
            <div id={title.toLowerCase().replace(/ /g, '-').concat('-group')} className={'collapse show px-3 pt-0 table-striped'} style={{marginTop: '-1.25em'}}>
                <MaterialTable
                    options={{
                        draggable: false,
                        emptyRowsWhenPaging: false,
                        headerStyle: {
                            backgroundColor: 'var(--liberty-teal)',
                            fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                            fontSize: '16px',
                            fontWeight: '700'
                        },
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 50, 100],
                        paging: true,
                        search: true,
                        sorting: true
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: isGroupReservation ?
                                groups?.length > 0 ?
                                    (<span>There are no reservations to show. Check the <Link to={'group-preferences'}>Reservation Groups</Link> page to view group member opt-in status.</span>)
                                    : (<span>You do not have any groups set up. Go to the <Link to={'group-preferences'}>Reservation Groups</Link> page to get started.</span>)
                                : 'There are no reservations to show.'
                        },
                    }}
                    title={null}
                    columns={[
                        {title: 'Name', align: 'center', field: 'employeeName', hidden: isUserReservation},
                        {title: 'Group Name(s)', field: 'groupName', hidden: !isGroupReservation},
                        {title: 'Date', align: 'center', defaultSort: 'asc',
                            render: (reservation) => {
                                const dateText = DateUtils.getLocaleDateFromString(reservation.reservationDate)  + '' + (reservation.reservationType === RESERVATION_TYPE.SPACE ? ' (' + DateUtils.displayReservationHours(reservation) + ')' : '');
                                return dateText;},
                            customSort: (a, b) => {
                                const sortOrder = [RESERVATION_TYPE.SPACE, RESERVATION_TYPE.PARKING];
                                return (DateUtils.parseDate(a.reservationDate) - DateUtils.parseDate(b.reservationDate)) ||
                                    (sortOrder.indexOf(a.reservationType) - sortOrder.indexOf(b.reservationType))
                            }},
                        {title: 'Office', align: 'center',
                            render: (reservation) => reservation.reservationType === RESERVATION_TYPE.SPACE ? reservation.officeName : 'Boston, MA',
                            customFilterAndSearch: (term, reservation) => {
                                const office = reservation.reservationType === RESERVATION_TYPE.SPACE ? reservation.officeName : 'Boston, MA';
                                return (office.toUpperCase().indexOf(term.toUpperCase()) !== -1);},
                            customSort: (a, b) => (a.reservationType === RESERVATION_TYPE.SPACE ? a.spaceName : 'Boston, MA').localeCompare(b.reservationType === RESERVATION_TYPE.SPACE ? b.spaceName : 'Boston, MA')},
                        {title: 'Floor', align: 'center',
                            render: (reservation) => reservation.reservationType === RESERVATION_TYPE.SPACE ? reservation.floorDto.displayName : 'N/A',
                            customFilterAndSearch: (term, reservation) => {
                                const floor = reservation.reservationType === RESERVATION_TYPE.SPACE ? reservation.floorDto.displayName : 'N/A';
                                return (floor.toUpperCase().indexOf(term.toUpperCase()) !== -1);},
                            customSort: (a, b) => (a.reservationType === RESERVATION_TYPE.SPACE ? a.spaceName : 'N/A').localeCompare(b.reservationType === RESERVATION_TYPE.SPACE ? b.spaceName : 'N/A')},
                        {title: 'Space', align: 'center',
                            render: (reservation) => {
                                const space = reservation.reservationType === RESERVATION_TYPE.SPACE ? reservation.spaceName : reservation.isOvernight ? 'Overnight Parking' : 'Parking';
                                const selectedDates = [DateUtils.parseDate(reservation.reservationDate), DateUtils.parseDate(reservation.reservationDate)];
                                return reservation.reservationType === RESERVATION_TYPE.SPACE ? <Link to={{pathname: '/reserve-space', state: {office: reservation.officeDto, floor: reservation.floorDto, selectedDates: selectedDates, atMaxCapacity: reservation.floorDto.currentCapacity >= 100, userReservations: userReservations,
                                        maxDate: DateUtils.getYMDDate(DateUtils.getMaxDate(reservation.officeDto)), selectedHours: [reservation.reservationStartHour, reservation.reservationEndHour]}}}><span title={'Open map to reservation'}>{space}</span></Link> :
                                    reservation.reservationType === RESERVATION_TYPE.PARKING && reservation.isMissingVehicleInfo ? <>{space}<img alt={'Missing vehicle information'} title={'Missing vehicle information'} src={missing_vehicle_logo} className={'ml-2 mt-n1'} style={{height: '1rem'}} /></> : space;
                            },
                            customFilterAndSearch: (term, reservation) => {
                                const space = reservation.reservationType === RESERVATION_TYPE.SPACE ? reservation.spaceName : 'Parking';
                                return (space.toUpperCase().indexOf(term.toUpperCase()) !== -1);},
                            customSort: (a, b) => (a.reservationType === RESERVATION_TYPE.SPACE ? a.spaceName : 'Parking').localeCompare(b.reservationType === RESERVATION_TYPE.SPACE ? b.spaceName : 'Parking')},
                        {title: 'Actions', sorting: false, searchable: false, align: 'center',
                            render: (reservation) => {
                                const selectedDates = [DateUtils.parseDate(reservation.reservationDate), DateUtils.parseDate(reservation.reservationDate)];
                                return (
                                    <React.Fragment>
                                        {reservation.reservationType === RESERVATION_TYPE.SPACE ? <Link to={{pathname: '/reserve-space', state: {office: reservation.officeDto, floor: reservation.floorDto, selectedDates: selectedDates, atMaxCapacity: reservation.floorDto.currentCapacity >= 100, userReservations: userReservations,
                                                maxDate: DateUtils.getYMDDate(DateUtils.getMaxDate(reservation.officeDto)), selectedHours: [reservation.reservationStartHour, reservation.reservationEndHour]}}} className={'btn logo-btn'}><img src={location_png} alt={'Open map to reservation'} title={'Open map to reservation'} /></Link> : null}
                                        {reservation.reservationType === RESERVATION_TYPE.PARKING ? <Link to={{pathname: '/reserve-parking', state: {reservation: reservation}}} className={'btn logo-btn'}><img alt={'Edit reservation'} title={'Edit reservation'} src={pen_logo} /></Link> : null}
                                        {isUserReservation ? <button className='btn logo-btn' onClick={() => {reservation.reservationType === RESERVATION_TYPE.SPACE ? createSpaceCalendarInvite(reservation, reservation.floorDto.displayName) : createParkingCalendarInvite(reservation, 'Boston, MA')}}><img alt={'Download calendar invite'} title={'Download calendar invite'} src={download_invite}/></button> : null}
                                        {!isGroupReservation ? <button className='btn logo-btn' onClick={() => {if (window.confirm('Are you sure you would like to cancel this reservation?')) cancelReservation(reservation.id, reservation.reservationType === RESERVATION_TYPE.PARKING, isUserReservation)}}><img alt={'Cancel reservation'} title={'Cancel reservation'} src={trash_logo}/></button> : null}
                                    </React.Fragment>
                                )
                            }}
                    ]}
                    data={reservations ? reservations : []}
                    components={{
                        Toolbar: props => (<MTableToolbar {...props} classes={{root: 'reservation-table-toolbar'}} />)
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default ReservationTable;