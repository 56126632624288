import React, {useEffect, useState} from 'react';
import AxiosRequest from '../../../utilities/AxiosRequest';
import Notification from '../../standard/Notification';
import MaterialTable from '@material-table/core';
import {Link} from 'react-router-dom';

function ParkingOffices() {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [offices, setOffices] = useState([]);
    const url = '/api/v1/offices';

    useEffect(() => {
    const getParkingOffices = async () => {
        resetNotifications();

        try {
            const response = await AxiosRequest.get(url + '?isParking=true');
            if (response.status === 200) {
                setOffices(response.data);
            } else {
                setError(true);
                setErrorMessage('An error occurred retrieving parking office list');
            }
        }  catch (err) {
            setError(true);
            setErrorMessage(err.response.data.message || err.response.data.error);
        }
    }

    getParkingOffices();
    }, []);

    const editParkingOffice = async (updatedOffice) => {
        resetNotifications();

        try {
            const newOffices = [...offices];
            const editIndex = newOffices.findIndex(office => office.id === updatedOffice.id);
            newOffices[editIndex] = updatedOffice;
            const response = await AxiosRequest.put(url + '/' + updatedOffice.id + '?isParking=true', newOffices[editIndex]);
            if (response.status === 200) {
                setOffices(newOffices);
            } else {
                setError(true);
                setErrorMessage('An error occurred editing the office: ' + updatedOffice.officeName);
            }
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.message || err.response.data.error);
        }
    };

    const editEmployeeGroup1Spots = (office, numSpots) => {
       office.employeeGroup1Spots = numSpots !== '' ? numSpots : 0;
       editParkingOffice(office);
    };

    const editEmployeeGroup2Spots = (office, numSpots) => {
        office.employeeGroup2Spots = numSpots !== '' ? numSpots : 0;
        editParkingOffice(office);
    };

    const editVisitorSpots = (office, visitorSpots) => {
        office.visitorSpots = visitorSpots !== '' ? visitorSpots : 0;
        editParkingOffice(office);
    };

    const editReservationLimit = (office, reservationLimit) => {
        office.reservationLimit = reservationLimit !== '' ? reservationLimit : 0;
        editParkingOffice(office);
    };

    const editParkingActive = (office) => {
        office.isClientActive = !office.isClientActive;
        editParkingOffice(office);
    };

    const resetNotifications = () => {
        setErrorMessage('');
        setError(false);
    };

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/admin'}>Home</Link> &gt; <Link to={'/admin/manage-parking/'}>Parking Administration</Link>
            </div>
            <section>
                <h1>Parking Offices</h1>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            paging: false,
                            search: false,
                            showTitle: false,
                            sorting: false
                        }}
                        columns={[
                            {title: 'Name', field: 'officeName'},
                            {title: 'Code', field: 'officeNumber'},
                            {title: 'Employee Group 1 Spots', tooltip: 'Number of parking spots available for executives level A and B', align: 'center',
                                render: (office) => <span><input type='number' min='0' required defaultValue={!office.employeeGroup1Spots ? 0 : office.employeeGroup1Spots}
                                                                 onBlur={(e) => editEmployeeGroup1Spots(office, e.target.value)}/></span>,
                                customSort: (a, b) => a.employeeGroup1Spots - b.employeeGroup1Spots},
                            {title: 'Employee Group 2 Spots', tooltip: 'Number of parking spots available for executives level C', align: 'center',
                                render: (office) => <span><input type='number' min='0' required defaultValue={!office.employeeGroup2Spots ? 0 : office.employeeGroup2Spots}
                                                                 onBlur={(e) => editEmployeeGroup2Spots(office, e.target.value)}/></span>,
                                customSort: (a, b) => a.employeeGroup2Spots - b.employeeGroup2Spots},
                            {title: 'Visitor Spots', tooltip: 'Number of parking spots available for visitors', align: 'center',
                                render: (office) => <span><input type='number' min='0' required defaultValue={!office.visitorSpots ? 0 : office.visitorSpots}
                                                                 onBlur={(e) => editVisitorSpots(office, e.target.value)}/></span>,
                                customSort: (a, b) => a.visitorSpots - b.visitorSpots},
                            {title: 'Reservation Limit', tooltip: 'Number of calendar days in the future', align: 'center',
                                render: (office) => <span><input type='number' min='0' required defaultValue={!office.reservationLimit ? 0 : office.reservationLimit}
                                                                 onBlur={(e) => editReservationLimit(office, e.target.value)}/></span>,
                                customSort: (a, b) => a.reservationLimit - b.reservationLimit},
                            {title: 'Active', align: 'center',
                                render: (office) => <input type='checkbox' defaultChecked={office.isClientActive}
                                                           onChange={() => editParkingActive(office)} />}
                        ]}
                        data={offices}
                    />
                </div>
            </section>
        </React.Fragment>
    );
}

export default ParkingOffices;