import React from 'react';
import {Link} from 'react-router-dom';
import offices_logo from '../../../images/offices.png';
import admins_logo from '../../../images/admins.png';
import exceptions_logo from '../../../images/exceptions_logo.png';

function ParkingChoice() {
    return (
        <React.Fragment>
            <div id={'admin-home'} className={'pt-5'}>
                <span>
                    <Link to={{pathname: '/admin/manage-parking-offices'}}><img src={offices_logo} alt={'Offices Logo'} /></Link>
                    <h3>Offices</h3>
                </span>
                <span>
                    <Link to={{pathname: '/admin/manage-security-guards'}}><img src={admins_logo} alt={'Security Guard Logo'} /></Link>
                    <h3>Security Guards</h3>
                </span>
                <span>
                    <Link to={{pathname: '/admin/manage-parking-exceptions'}}><img src={exceptions_logo} alt={'Exceptions Logo'} /></Link>
                    <h3>Exceptions</h3>
                </span>
            </div>
        </React.Fragment>
    );
}

export default ParkingChoice;