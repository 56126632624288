import React, {useState} from 'react';
import UserSearch from '../standard/UserSearch';

function AddGroupMember({addGroupMember, groupId}) {
    const [form, setState] = useState({
        name: '',
        employeeId: ''
    });

    const handleSubmit = e => {
        e.preventDefault();
        addGroupMember(groupId, form);
    };

    const setGroupMemberName = (selectedGroupMembers) => {
        if (selectedGroupMembers.length > 0) {
            setState({
                name: selectedGroupMembers[0].displayname,
                employeeId: selectedGroupMembers[0].employeeId
            });
        } else {
            setState({
                name: '',
                employeeId: ''
            });
        }
    };

    return (
        <React.Fragment>
            <form className='form' id='group-member-form' onSubmit={handleSubmit}>
                <div className={'row mr-0'}>
                    <div className={'col-8 pr-0'}>
                        <UserSearch placeholderText={'Enter employee\'s last name or ID...'} setSearchResults={setGroupMemberName}/>
                    </div>
                    <div className={'col-3 pl-0'}>
                        <input type='hidden' id='group-member-name' name='name' />
                        <input type='hidden' id='employeeId' name='employeeId' />
                        <input type='submit' className={'btn btn--dark ml-3'} title={'Add member to group'} value='Add'/>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
}

export default AddGroupMember;