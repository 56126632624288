import React, {useEffect, useState, useRef} from 'react';
import * as Constants from '../../utilities/Constants';

function ReservationHours({hours, changeHours}) {
    const firstRender = useRef(true);
    const [isFullDay, setIsFullDay] = useState(hours[0] === 7 && hours[1] === 17);
    const [selectedHours, setSelectedHours] = useState(hours);

    useEffect(() => {
        if (!firstRender.current) {
            changeHours(selectedHours);
        } else {
            firstRender.current = false;
        }
    }, [selectedHours, changeHours]);

    return(
        <React.Fragment>
            <select id={'start-hour'} className={'date-field'} disabled={isFullDay} value={isFullDay ? 'na' : selectedHours[0]} onChange={(e) => {e.target.value > selectedHours[1] && selectedHours[1] !== 0 ? setSelectedHours([Number(e.target.value), Number(e.target.value)]) : setSelectedHours([Number(e.target.value), selectedHours[1]])}}>
                <option value={'na'}>N/A</option>
                {Constants.HOURS_IN_A_DAY.map((hour, index) => (
                    <option key={index} value={hour.code}>{hour.name}</option>
                ))}
            </select>
            <span className={'px-sm-3 px-1 pt-1'}>to</span>
            <select id={'end-hour'} className={'date-field'} disabled={isFullDay} value={isFullDay ? 'na' : selectedHours[1]} onChange={(e) => {e.target.value < selectedHours[0] && selectedHours[0] !== 0 ? setSelectedHours([Number(e.target.value), Number(e.target.value)]) : setSelectedHours([selectedHours[0], Number(e.target.value)])}}>
                <option value={'na'}>N/A</option>
                {Constants.HOURS_IN_A_DAY.map((hour, index) => (
                    <option key={index} value={hour.code}>{hour.name}</option>
                ))}
            </select>
            <input id={'full-day'} type={'checkbox'} className={'ml-3 mr-1'} checked={isFullDay} onChange={() => {setIsFullDay(!isFullDay); setSelectedHours([7, 17]);}}/>Full Day
        </React.Fragment>
    );
}

export default ReservationHours;