import React, {useEffect, useRef, useState} from 'react';
import * as ValidateUtils from '../../utilities/ValidateUtils';
import * as Constants from '../../utilities/Constants';
import './Textbox.css';

function Textbox({ id, textboxType = Constants.INPUT_TYPES.TEXT, isDisabled, placeholderText, initialValue, editValue, title, isRequired, requiredMessage = 'Value is required.', changeMethod, clearMethod, maxLength, isReadOnly, allowAll }) {
    let defaultValue = useRef('');
    const [disabled, setDisabled] = useState(isDisabled);
    const [errorClass, setErrorClass] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [focusClass, setFocusClass] = useState('');
    const [showClear, setShowClear] = useState(!isReadOnly && initialValue?.length > 0);
    let textbox = useRef();
    const pattern = useRef(null);
    const [populatedClass, setPopulatedClass] = useState('');

    useEffect(() => {
        if (initialValue !== null && initialValue !== undefined) {
            defaultValue.current = initialValue;
            setPopulatedClass(initialValue.length > 0 ? 'is-populated' : '');
            setShowClear(!isReadOnly && initialValue?.length > 0);
        }
        switch (textboxType) {
            case Constants.INPUT_TYPES.EMAIL:
                pattern.current = ValidateUtils.EMAIL_PATTERN;
                break;
            case Constants.INPUT_TYPES.PHONE:
                pattern.current = ValidateUtils.PHONE_PATTERN;
                break;
            case Constants.INPUT_TYPES.TEXT:
                pattern.current = allowAll ? ValidateUtils.TEXT_ANY_PATTERN : ValidateUtils.TEXT_PATTERN;
                break;
            default:
                pattern.current = null;
        }
    }, [initialValue, textboxType, allowAll, isReadOnly]);

    useEffect(() => {
        setDisabled(isDisabled);
        if (isDisabled) {
            setErrorClass('');
            setErrorMessage('');
            textbox.current.value = '';
            setPopulatedClass('');
            if (editValue) {
                editValue('');
            }
            setShowClear(false);
        }
    }, [isDisabled, editValue]);

    const onBlur = (textboxValue) => {
        setFocusClass('');
        validate(textboxValue);
    };
    const onChange = (textboxValue) => {
        setShowClear(textboxValue.length > 0);
        setPopulatedClass(textboxValue.length > 0 ? 'is-populated' : '');
    };
    const onFocus = () => {
        setFocusClass('is-focused');
    };

    /**
     * Validate format based on the type of textbox
     * Future expansion to other types
     * @param textboxValue The value to compare to a format
     */
    const validate = (textboxValue) => {
        setErrorMessage('');
        setErrorClass('');
        if (!textboxValue && isRequired) {
            setErrorMessage(requiredMessage);
            setErrorClass('is-error');
            return;
        }
        if (textboxType === Constants.INPUT_TYPES.EMAIL) {
            if (textboxValue && !ValidateUtils.validateEmail(textboxValue)) {
                setErrorMessage('Please enter a valid email address.');
                setErrorClass('is-error');
                return;
            }
        } else if (textboxType === Constants.INPUT_TYPES.PHONE) {
            if (textboxValue && !ValidateUtils.validatePhone(textboxValue)) {
                setErrorMessage('Please enter a valid phone number.');
                setErrorClass('is-error');
                return;
            }
        } else if (textboxType === Constants.INPUT_TYPES.TEXT) {
            if (textboxValue && ((!allowAll && !ValidateUtils.validateText(textboxValue)) || (allowAll && !ValidateUtils.validateTextAny(textboxValue)))) {
                setErrorMessage(requiredMessage);
                setErrorClass('is-error');
                return;
            }
        }
        if (editValue) {
            editValue(textboxValue);
        }
    };

    const clear = () => {
        setErrorClass('');
        setErrorMessage('');
        textbox.current.value = '';
        clearMethod();
        onChange('');
    };

    return (
        <div id={id + '-wrapper'} className={`textbox-wrapper ${focusClass} ${populatedClass} ${errorClass} ${disabled ? 'is-disabled' : ''} ${isReadOnly ? 'is-read-only' : ''}`} title={title?.length > 0 ? title : null}>
            <div id={id + '-error'} className={'textbox-error'}>{errorMessage}</div>
            <label id={id + '-label'} className={'textbox-label'} htmlFor={id + '-input'}>{placeholderText}{isRequired ? <span style={{color: 'var(--error-text)'}}> *</span> : ''}</label>
            <div className={'textbox-area'}>
                <input ref={textbox} type={textboxType} id={id + '-input'} name={id} className={'textbox-input'} defaultValue={defaultValue.current} disabled={disabled} required={isRequired} pattern={pattern.current} maxLength={maxLength} readOnly={isReadOnly}
                   onBlur={(e) => {onBlur(e.target.value)}}
                   onChange={(e) => {onChange(e.target.value); if (typeof(changeMethod) === 'function') changeMethod(e);}}
                   onClick={onFocus}
                   onFocus={onFocus}
                   onInput={(e) => {e.target.setCustomValidity('')}}
                   onInvalid={(e) => {e.target.setCustomValidity(requiredMessage)}}
                />
                <div className={'rbt-div'} hidden={!showClear}>
                    <span id={id + '-clear'} className={'clear rbt-clear'} title={'Clear'} onClick={clear}>x</span>
                </div>
            </div>
        </div>
    );
}

export default Textbox;