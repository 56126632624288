import React, {useContext, useEffect, useState} from 'react';
import ReservationTable from './ReservationTable';
import AxiosRequest from '../../utilities/AxiosRequest';
import UserContext from '../../context/UserContext';
import Notification from '../standard/Notification';
import GraphUtils from '../../utilities/GraphUtils';
import * as DateUtils from '../../utilities/DateUtils';
import {Link} from 'react-router-dom';

function ReservationDashboard() {
    const [behalfOfReservations, setBehalfOfReservations] = useState([]);
    const [canceledReservation, setCanceledReservation] = useState(null);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const currentUser = useContext(UserContext);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [groups, setGroups] = useState([]);
    const [groupReservations, setGroupReservations] =  useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userReservations, setUserReservations] = useState([]);

    useEffect(() => {
        let didCancel = false;
        if (currentUser && currentUser.id) {
            const getBehalfOfReservations = async (reservationList) => {
                let behalfOfEmployees = [];
                for (const behalfOfId of reservationList.flatMap(r => r.employeeId)) {
                    const matches = await GraphUtils.searchUserByNameOrId(behalfOfId);
                    if (matches.length > 0) {
                        behalfOfEmployees.push(matches[0]);
                    }
                }
                let behalfOfReservations = [];
                for (const reservation of reservationList) {
                    const behalfOfReservation = reservation;
                    const employee = behalfOfEmployees.find(e => e.employeeId.toUpperCase() === behalfOfReservation.employeeId.toUpperCase());
                    behalfOfReservation.employeeName = employee.extension_128b6233d06d4df391d7de26c982b64e_msDS_cloudExtensionAttribute4 + ' ' + employee.surname;
                    behalfOfReservations.push(behalfOfReservation);
                }
                if (!didCancel) {
                    setBehalfOfReservations(behalfOfReservations);
                }
            };
            const getReservations = async () => {
                try {
                    const response = await AxiosRequest.get(`/api/v1/users/${currentUser.id}/reservations?type=ALL&includeBehalf=true`);
                    if (response.status === 200) {
                        if (!didCancel) {
                            setUserReservations(response.data.filter(r => r.employeeId.toUpperCase() === currentUser.employeeId.toUpperCase()));
                            if (response.data.filter(r => r.reserverId === currentUser.id && r.employeeId.toUpperCase() !== currentUser.employeeId.toUpperCase()).length > 0) {
                                getBehalfOfReservations(response.data.filter(r => r.reserverId === currentUser.id && r.employeeId.toUpperCase() !== currentUser.employeeId.toUpperCase()));
                            }
                        }
                    } else {
                        setError(true);
                        setErrorMessage('An error occurred retrieving user reservations');
                    }
                    setIsLoading(false);
                } catch (err) {
                    setError(true);
                    setErrorMessage(err.response.data.message || err.response.data.error);
                }
            };
            getReservations();
        }
        return () => {
            didCancel = true;
        };
    }, [currentUser]);

    useEffect(() => {
        resetNotifications();

        const getGroups = async () => {
            try {
                if (currentUser && currentUser.id) {
                    setGroups([]);
                    const response = await AxiosRequest.get(`/api/v1/users/${currentUser.id}/groups?includeReservations=true`);
                    if (response.status === 200) {
                        setGroups(response.data);
                        // put all group reservations in one array
                        let groupMemberReservations = [];
                        response.data.forEach(group => group.groupMembers.forEach(member => {
                            // remove current user's reservations from group reservations
                            if (member.memberId !== currentUser.id) {
                                let memberReservations = member.reservationDtoList;
                                memberReservations = memberReservations === null ? [] : memberReservations;
                                memberReservations.forEach(reservation => reservation.groupName = group.groupName);
                                groupMemberReservations.push(...memberReservations);
                            }
                        }));

                        // reduce duplicate reservations from different groups, concat group names
                        let resFound;
                        let newObj = groupMemberReservations.reduce((accumulator, reservation) => {
                            if ((resFound = accumulator.find(r => r.id === reservation.id))) {
                                resFound.groupName += `, ${reservation.groupName}`;
                            } else {
                                accumulator.push(reservation);
                            }
                            return accumulator;
                        }, []);
                        setGroupReservations(newObj);
                    } else {
                        setError(true);
                        setErrorMessage('An error occurred retrieving group list');
                    }
                }
            } catch (err) {
                setError(true);
                setErrorMessage(err.response.data.message || err.response.data.error);
            }
        };
        getGroups();
    }, [currentUser]);

    const cancelReservation = async (reservationId, isParking, isUserReservation) => {
        resetNotifications();

        try {
            let reservationsUrl = `/api/v1/users/${currentUser.id}/reservations/${reservationId}`;
            reservationsUrl = isParking ? reservationsUrl + '?type=parking' : reservationsUrl;
            const response = await AxiosRequest.destroy(reservationsUrl);
            if (response.status === 200) {
                const newGroupReservations = groupReservations;
                const matchingGroupReservations = newGroupReservations.filter(r => r.id === response.data.id && r.reservationType === response.data.reservationType);
                if (matchingGroupReservations.length > 0) {
                    matchingGroupReservations.forEach(r => newGroupReservations.splice(newGroupReservations.indexOf(r), 1));
                    setGroupReservations(newGroupReservations);
                }
                if (isUserReservation) {
                    const newReservations = userReservations;
                    newReservations.splice(newReservations.findIndex(r => r.id === response.data.id && r.reservationType === response.data.reservationType), 1);
                    setUserReservations(newReservations);
                } else {
                    const newBehalfReservations = behalfOfReservations;
                    newBehalfReservations.splice(newBehalfReservations.findIndex(r => r.id === response.data.id && r.reservationType === response.data.reservationType), 1);
                    setBehalfOfReservations(newBehalfReservations)
                }

                setCanceledReservation(response.data);
                setCancelSuccess(true);
            } else {
                setError(true);
                setErrorMessage('An error occurred canceling your reservation');
            }
        } catch (ex) {
            setError(true);
            setErrorMessage(ex.response.data.message || ex.response.data.error);
        }
    };

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
        setCancelSuccess(false);
    };

    return (
        <React.Fragment>
            <Notification id={'cancel-success-notification'} notificationType={'success'} dismissable={true} dismissText={'Click to close'} active={cancelSuccess} handleDismiss={resetNotifications}>Reservation canceled {canceledReservation ? 'for ' + DateUtils.getLocaleDateFromString(canceledReservation.reservationDate) : ''}</Notification>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/'}>Home</Link>&nbsp;&gt;&nbsp;Reservation Dashboard
            </div>
            { isLoading ? <></> :
                <section>
                    <h3>Reservation Dashboard</h3>
                    <ReservationTable title={'My Upcoming Reservations'} reservations={userReservations}
                                      isUserReservation={true} isGroupReservation={false}
                                      userReservations={userReservations} cancelReservation={cancelReservation}></ReservationTable>
                    { behalfOfReservations.length > 0 ? <ReservationTable title={'Behalf Of Reservations'} reservations={behalfOfReservations}
                                      isUserReservation={false} isGroupReservation={false} userReservations={userReservations}
                                      cancelReservation={cancelReservation}></ReservationTable> : null }
                    <ReservationTable title={'My Groups'} reservations={groupReservations} isUserReservation={false}
                                      isGroupReservation={true} userReservations={userReservations}
                                      groups={groups}></ReservationTable>
                </section>
            }
        </React.Fragment>
    );
}

export default ReservationDashboard;