import React, {useState, useEffect} from 'react';
import AddSecurityGuard from './AddSecurityGuard';
import AxiosRequest from '../../../utilities/AxiosRequest';
import {Link} from 'react-router-dom';
import Notification from '../../standard/Notification';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import trash_logo from '../../../images/trash_logo.png';

function SecurityGuards() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const url = '/api/v1/users/guards';

    useEffect(() => {
        resetNotifications();

        const getUsers = async () => {
            try {
                const response = await AxiosRequest.get(url);
                if (response.status === 200) {
                    setUsers(response.data);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving security guard list');
                }
            } catch (err) {
                setError(true);
                setErrorMessage('An error occurred retrieving security guard list');
            }
        };
        getUsers();
    }, []);

    const addUser = async (user) => {
        try {
            resetNotifications();
            if (user.name !== '') {
                const response = await AxiosRequest.post(url, user);
                if (response.status === 201) {
                    const newUsers = [...users, response.data];
                    setUsers(newUsers);
                } else {
                    setError(true);
                    setErrorMessage('An error occurred adding a security guard');
                }
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred adding a security guard');
        }
    };

    const removeUser = async (id) => {
        try {
            resetNotifications();
            const response = await AxiosRequest.destroy(url + '/' + id);
            if (response.status === 204) {
                const newUsers = users.filter((user) => user.id !== id);
                setUsers(newUsers);
            } else {
                setError(true);
                setErrorMessage('An error occurred removing a security guard');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred removing a security guard');
        }
    };

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    };

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}><Link to={'/admin'}>Home</Link> &gt; <Link to={'/admin/manage-parking/'}>Parking Administration</Link> </div>
            <section>
                <h1>Security Guard Management</h1>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 15,
                            pageSizeOptions: [15, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true
                        }}
                        columns={[
                            {title: 'Name', field: 'name'},
                            {title: 'Employee ID', field: 'employeeId',
                                customFilterAndSearch: (term, admin) => (admin.employeeId).toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                customSort: (a, b) => (a.employeeId).localeCompare((b.employeeId))},
                            {title: 'Actions', sorting: false, searchable: false, align: 'center',
                                render: (user) => <button className='btn logo-btn' onClick={() => removeUser(user.id)}><img alt={'Trash Logo'} src={trash_logo}/></button>}
                        ]}
                        data={users}
                        components={{
                            Toolbar: (props) => (
                                <div className={'row p-0 m-0 mt-1'}>
                                    <div className={'col-9 p-0 m-0'}><AddSecurityGuard addUser={addUser}/></div>
                                    <div className={'col-3 p-0 m-0'}><MTableToolbar {...props} /></div>
                                </div>
                            ),
                        }}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default SecurityGuards;
