import React, {useState} from 'react';
import UserSearch from '../standard/UserSearch';
import {TextField} from '@material-ui/core';
import * as DateUtils from '../../utilities/DateUtils';
import {EXCEPTION_TYPE} from '../../utilities/Constants';

function AddException({addException, exceptionType}) {
    const [form, setState] = useState({
        name: '',
        employeeId: '',
        expirationDate: ''
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const newException =
            exceptionType === EXCEPTION_TYPE.PARKING ?
                {
                    employeeId: form.employeeId,
                    name: form.name,
                    parkingExceptionExpirationDate: form.expirationDate
                } :
                {
                    employeeId: form.employeeId,
                    name: form.name,
                    spaceExceptionExpirationDate: form.expirationDate
                };
        addException(newException);
        setState({
            name: '',
            employeeId: '',
            expirationDate: ''
        });
        document.getElementById('exception-form').reset();
    };

    const setExceptionName = (selectedEmployees) => {
        if (selectedEmployees.length > 0) {
            setState({
                ...form,
                name: selectedEmployees[0].displayName,
                employeeId: selectedEmployees[0].employeeId,
            });
        } else {
            setState({
                ...form,
                name: '',
                employeeId: '',
            });
        }
    };

    const updateField = (event) => {
        setState({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    return (
        <React.Fragment>
            <div className={'container ml-0'}>
                <form className={'form'} id={'exception-form'} onSubmit={handleSubmit}>
                    <div className={'row'}>
                        <div className={'col-5'}>
                            <label className={'ml-2'} htmlFor='exception-user-search'>
                                <small>Employee Name or ID</small>
                            </label>
                        </div>
                        <div className={'col'}>
                            <label htmlFor='add-expiration-date'>
                                <small>Expiration Date</small>
                            </label>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-5'}  id={'exception-user-search'}>
                            <UserSearch placeholderText={'Enter employee\'s last name or ID...'} setSearchResults={setExceptionName}/>
                        </div>
                        <div className={'col'}>
                            <TextField
                                required
                                type={'date'}
                                id={'add-expiration-date'}
                                name={'expirationDate'}
                                InputProps={{
                                    className: 'date-field',
                                    disableUnderline: true
                                }}
                                inputProps={{
                                    min: DateUtils.getYMDDate(new Date())
                                }}
                                onChange={(event) => {updateField(event)}}
                                aria-labelledby='add-expiration-date'
                            />
                            <input type='hidden' id='exceptionName' name='name' />
                            <input type='hidden' id='employeeId' name='employeeId' />
                            <input type='submit' className='ml-4 btn btn--primary' value='Add Exception'/>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default AddException;