import { UserAgentApplication } from 'msal';

const apiScopeName = 'ireserve.api.read';
const graphScopeName = 'User.Read';
let msalInstance;

/**
 * Retrieve an access token for the provided scope
 * @param scope The scope for access
 * @returns {Promise<string>}
 */
function getAccessToken(scope) {
    const tokenRequest = { scopes: [scope] };

    // acquireTokenSilent is preferred to retrieve an access token:
    // checks for token in cache, validates it, refreshes if a new one is needed.
    const token = msalInstance
        .acquireTokenSilent(tokenRequest)
        .then((silentResponse) => {
            return silentResponse.accessToken;
        })
        .catch(silentError => {
            if (silentError.name === 'InteractionRequiredAuthError') {
                // handling the "interactive" redirect is completed in redirectCallback function
                msalInstance.acquireTokenRedirect({...tokenRequest});
            } else {
                console.error('Silent Error: ' + silentError);
            }
        });
    return token;
}

/**
 * Retrieve the token for application access
 * @returns {Promise<string>}
 */
function getAppToken() {
    const apiId = sessionStorage.getItem('apiId');
    const clientTokenScope = `${apiId}/${apiScopeName}`;
    return getAccessToken(clientTokenScope);
}

/**
 * Retrieve the token for Microsoft GraphQL access
 * @returns {Promise<string>}
 */
function getGraphToken() {
    return getAccessToken(graphScopeName);
}

/**
 * Set up UserAgentApplication, should only be called once per session
 * @param frontendId
 * @returns {UserAgentApplication}
 */
function initializeMsal(frontendId) {
    msalInstance = new UserAgentApplication({
        auth: {
            authority: 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1',
            clientId: frontendId
        }
    });

    msalInstance.handleRedirectCallback(redirectCallback);
    return msalInstance;
}

/**
 * Returns UserAgentApplication instance to avoid creating multiple per session
 * @returns UserAgentApplication (msalInstance)
 */
function getMsalInstance() {
    return msalInstance;
}

/**
 * Redirect callback passed to UserAgentApplication (msalInstance) to handle non-silent requests.  Currently handles responses
 * containing access tokens for application logic.
 * @param error AuthError object, will be empty if successful
 * @param response AuthResponse object containing success information from interactive request
 */
function redirectCallback(error, response) {
    if (error) {
        console.error('handleRedirectCallback error: ' + error);
    } else {
        if (response.tokenType === 'access_token' ) {
            response.scopes.forEach(scope => {
                if (scope.includes(apiScopeName)) {
                    sessionStorage.setItem('appToken', 'true');
                } else if (scope.includes(graphScopeName)) {
                    sessionStorage.setItem('graphToken', 'true');
                }
            });
        }
    }
}

const MsalAuthToken = {
    getAppToken,
    getGraphToken,
    getMsalInstance,
    initializeMsal
};

export default MsalAuthToken;