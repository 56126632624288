import React, {useState, useEffect, useRef} from 'react';
import App from './App';
import { LoadingProgress } from './components/standard/LoadingProgress';
import MsalAuthToken from './utilities/MsalAuthToken';
import axios from 'axios';

function Container() {
    const [environment, setInitEnvironment] = useState(false);
    const [appToken, setAppToken] = useState(false);
    const [graphToken, setGraphToken] = useState(false);
    let msalInstance = useRef();

    useEffect(() => {
        let didCancel = false;

        async function initEnvironment() {
            try {
                const frontendId = sessionStorage.getItem('frontendId');
                if (!frontendId) {
                    const result = (await axios.get('/api/v1/env')).data;
                    if (!didCancel) {
                        setInitEnvironment(true);
                        sessionStorage.setItem('frontendId', result.frontendId);
                        sessionStorage.setItem('apiId', result.apiId);
                        initializeTokens(result.frontendId);
                    }
                } else {
                    setInitEnvironment(true);
                    initializeTokens(frontendId);
                }
            } catch (ex) {
                // Probably update to use Error state and general Error component to display error page for UX for all api calls
                console.error(ex);
            }
        }

        async function initializeTokens(frontendId) {
            if (!msalInstance.current) {
                msalInstance.current = MsalAuthToken.initializeMsal(frontendId);
            }
            if (msalInstance.current.isCallback(window.location.hash)) {
                return;
            }
            if (!msalInstance.current.getAccount()) { // No logged in user
                msalInstance.current.loginRedirect();
            } else {
                const appToken = sessionStorage.getItem('appToken');
                const graphToken = sessionStorage.getItem('graphToken');
                if (!appToken) {
                    const aToken = await MsalAuthToken.getAppToken();
                    if (aToken) { // If silent request is successful, follow with Graph request
                        if (!didCancel) {
                            setAppToken(true);
                        }
                        const gToken = await MsalAuthToken.getGraphToken();
                        if (gToken && !didCancel) setGraphToken(true);
                    }
                } else if (!graphToken) { // Silent request forced redirect and App token already retrieved, get Graph token
                    await MsalAuthToken.getGraphToken();
                }
            }
        }
        initEnvironment();

        return () => {
            didCancel = true;
        };
    }, []);

    const isInitialized = () => {
        const api = sessionStorage.getItem('appToken') || appToken;
        const graph = sessionStorage.getItem('graphToken') || graphToken;
        return environment && api && graph;
    }

    return (
        <React.Fragment>
            {!isInitialized() && !environment && (<LoadingProgress message='Initializing...' />) }
            {!isInitialized() && environment && (<LoadingProgress message='Loading...' />) }
            {isInitialized() && (<App />) }
        </React.Fragment>
    );
}

export default Container;