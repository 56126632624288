import React, {useEffect, useRef, useState} from 'react';
import AxiosRequest from '../../utilities/AxiosRequest';
import {Link} from 'react-router-dom';
import Notification from '../standard/Notification';
import * as Constants from '../../utilities/Constants';
import * as DateUtils from '../../utilities/DateUtils';

function ReserveFloors(props) {
    const currentOffice = useRef(props.location.state.office);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [floors, setFloors] = useState([]);
    const selectedDates = useRef(props.location.state.selectedDates);

    useEffect(() => {
        resetNotifications();

        const getFloors = async () => {
            try {
                const response = await AxiosRequest.get('api/v1/offices/' + currentOffice.current.id +
                    '/floors?startDate=' + DateUtils.getYMDDate(selectedDates.current[0]) + '&endDate=' +
                    DateUtils.getYMDDate(selectedDates.current[1]) + '&startHour=' + props.location.state.selectedHours[0] + '&endHour=' + props.location.state.selectedHours[1]);
                if (response.status === 200) {
                    setFloors(response.data.filter(s => s.isClientActive));
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving floor list');
                }
            } catch (err) {
                setError(true);
                setErrorMessage(err.response.data.message || err.response.data.error);
            }
        };
        getFloors();
    }, [props]);

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    }

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div id={'reserve-floors'} className={'mx-auto'}>
                <div className={'greeting'}>
                    <Link to={'/'}>Home</Link>
                    &nbsp;&gt;&nbsp;
                    {currentOffice.current.nickname ? currentOffice.current.nickname : currentOffice.current.officeName} ({currentOffice.current.officeNumber})
                </div>
                <section>
                    <h3 className={'mb-3'}>Reserve a workspace</h3>
                    <span className={'lead'}>Select a floor</span>
                    {floors.map((floor) => (
                        <Link key={floor.id} className={'reserve-floor'} to={{pathname: '/reserve-space', state: {office: currentOffice.current, floor: floor, selectedDates: selectedDates.current, atMaxCapacity: floor.currentCapacity >= 100, userReservations: props.location.state.userReservations, maxDate: props.location.state.maxDate, selectedHours: props.location.state.selectedHours}}} onClick={(e) => {if (!floor.hasMap) e.preventDefault()}}>
                            <div className={'card my-2 ' + (!floor.hasMap ? 'disabled' : '')}>
                                <div className={'row no-gutters'}>
                                    <div className={'col-4'}>
                                        <div className={'card-body py-3'}>
                                            <h6 className={'small text-muted mb-2'}>Floor</h6>
                                            <p className={'card-text'}><strong>{ floor.nickname ? floor.nickname : floor.floorNumber }</strong></p>
                                        </div>
                                    </div>
                                    <div className={'col-4'}>
                                        <div className={'card-body py-3'}>
                                            <h6 className={'text-center small text-muted mb-2'}>Active Seats</h6>
                                            <p className={'card-text text-center'}>{ floor.activeSpaceCount ? floor.activeSpaceCount : 0 }</p>
                                        </div>
                                    </div>
                                    <div className={'col-4'}>
                                        <div className={'card-body py-3'}>
                                            <h6 className={'text-center small text-muted mb-2'}>Capacity Filled</h6>
                                            <p className={'card-text text-center'}>{ floor.currentCapacity ? floor.currentCapacity : 0 } %</p>
                                        </div>
                                    </div>
                                </div>
                                { floor.currentCapacity >= 100 ?
                                    <div className={'row no-gutters'}>
                                        <div className={'col-12 p-1'}>
                                            <p className={'small card-text text-center'} style={{color: 'var(--error-text)'}} >{Constants.FLOOR_MAX_CAPACITY_WARNING}</p>
                                        </div>
                                    </div>
                                    : null }
                            </div>
                        </Link>))}
                </section>
                <div className={'section-divider'}></div>
            </div>
        </React.Fragment>
    )
}

export default ReserveFloors;