import React from 'react';
import {Link} from 'react-router-dom';
import calendar_image from '../../images/ireserve_logo.png';
import {TextField} from '@material-ui/core';
import * as DateUtils from '../../utilities/DateUtils';
import ReservationHours from './ReservationHours';
import caution_image from '../../images/caution.png';
import * as Constants from '../../utilities/Constants';
import AccesibilityIcon from '../../images/accessibility.png';

function ReserveFilters({selectedDates, setSelectedDates, selectedHours, hideReserveDropdown, hasAccessibility, setHasAccessibility, currentOffice, currentFloor, ...props}) {
    return (
        <section>
            <h3>Reserve a workspace</h3>
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={'small text-muted'}>Office</div>
                        {currentOffice.current.nickname ? currentOffice.current.nickname : currentOffice.current.officeName}
                    </div>
                    <div className={'col'}>
                        <div className={'small text-muted'}>Floor</div>
                        {currentFloor.current.nickname ? currentFloor.current.nickname : currentFloor.current.floorNumber}
                        <Link to={{pathname: '/reserve-calendar', state: {office: currentOffice.current, selectedDates: selectedDates, userReservations: props.userReservations, selectedHours: selectedHours}}} title={'Edit reservation dates'}>
                            <img src={calendar_image} alt={'calendar'} className={'ml-2 pl-sm-2'} height={'18px'} style={{verticalAlign: 'text-top'}}/>
                        </Link>
                    </div>
                </div>
                <div className={'row pt-2'}>
                    <div className={'col'}>
                        <div className={'small text-muted'}>Date(s)</div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <TextField type={'date'} id={'start-date'}
                                           value={DateUtils.getYMDDate(selectedDates[0])}
                                           InputProps={{
                                               className: 'date-field',
                                               disableUnderline: true
                                           }}
                                           inputProps={{
                                               min: DateUtils.getYMDDate(new Date()),
                                               max: props.location.state.maxDate
                                           }}
                                           onChange={(event) => {hideReserveDropdown(); setSelectedDates(DateUtils.changeStartDate(event.target.value, selectedDates[1]));}}
                                />
                                <span className={'px-sm-3 px-1 pt-1'}>to</span>
                                <TextField type={'date'} id={'end-date'}
                                           value={DateUtils.getYMDDate(selectedDates[1])}
                                           InputProps={{
                                               className: 'date-field',
                                               disableUnderline: true
                                           }}
                                           inputProps={{
                                               min: DateUtils.getYMDDate(new Date()),
                                               max: props.location.state.maxDate
                                           }}
                                           onChange={(event) => {hideReserveDropdown(); setSelectedDates(DateUtils.changeEndDate(event.target.value, selectedDates[0]));}}
                                />
                            </div>
                        </div>
                        <div className={'pt-2'}>
                            <ReservationHours hours={selectedHours} changeHours={props.setSelectedHours}/>
                        </div>
                    </div>
                </div>
                <div className={'row d-flex align-items-center py-2 py-3-sm'}>
                    { props.atMaxCapacity ? <h6 style={{color: 'var(--error-text)'}} className={'text-center w-100'}><strong>{Constants.FLOOR_MAX_CAPACITY_WARNING}</strong></h6> :
                        <><div className={'col d-none d-sm-block'}>Available Seat(s): {props.availableSpaceCount}</div>
                            <div className={'col text-right d-none d-sm-block'}><label className={'multi-select-label pr-1'}>Multi-Select</label><input ref={props.multiSelectCheckbox} className={'mb-1'} type={'checkbox'} onChange={props.toggleMultiSelect} /></div></>
                    }
                </div>
                <div id={'reserve-space-legend'} className={'row py-1 py-sm-3'}>
                    <div className={'col'}>
                        <div className={'row flex-column flex-sm-row'}>
                            <div className={'col px-5 px-sm-3 py-1 py-sm-0'}>
                                <div className={`d-inline-block align-middle legend mine ${hasAccessibility ? 'accessible' : ''}`}></div>
                                <div className={'small pl-2 d-inline align-middle'}>Your Reservation</div>
                            </div>
                            <div className={'col px-5 px-sm-3 py-1 py-sm-0'}>
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <div className={`d-inline-block align-middle legend available ${hasAccessibility ? 'accessible' : ''}`}></div>
                                        <div className={'small pl-2 d-inline align-middle'}>Available</div>
                                    </div>
                                </div>
                                <div className={'row pt-1 pt-sm-0 d-none d-sm-block'}>
                                    <div className={'col'}>
                                        <div className={'d-inline-block'}><img src={caution_image} alt={'Partial Availability Indicator'} height={'16px'} className={'mr-1 mt-1 align-text-bottom'}/></div>
                                        <div className={'small d-inline font-italic'}>Partial Availability</div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col px-5 px-sm-3 py-1 py-sm-0'}>
                                <div className={`d-inline-block align-middle legend unavailable ${hasAccessibility ? 'accessible' : ''}`}></div>
                                <div className={'small pl-2 d-inline align-middle'}>Unavailable</div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-3 col-sm-1 p-0 mr-1 align-self-center'}>
                        <button className={`circle-button ${!hasAccessibility ? 'btn btn--secondary' : 'btn btn--dark'}`} onClick={() => setHasAccessibility(!hasAccessibility)}>
                            <img className={!hasAccessibility ? 'accessibility-icon' : 'accessibility-icon-active'} src={AccesibilityIcon} alt={'Toggle Enhanced Visibility Mode'} title={'Enhance seat visibility by enabling this setting, which adjusts seat colors for improved accessibility.'}/>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ReserveFilters;