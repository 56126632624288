import React, {useCallback, useEffect, useRef, useState} from 'react';
import {fabric} from 'fabric';
import {Link} from 'react-router-dom';
import AxiosRequest from '../../../utilities/AxiosRequest';
import Notification from '../../standard/Notification';
import * as Constants from '../../../utilities/Constants';
import * as FloorMapUtils from '../../../utilities/FloorMapUtils';
import * as NeighborhoodUtils from '../../../utilities/NeighborhoodUtils';
import caret_up_image from '../../../images/caret_up.png';
import caret_down_image from '../../../images/caret_down.png';
import question_image from '../../../images/question_circle.png';

const DEFAULT_SEAT_MAP_JSON = {angle: 0, height: '', left: '', scaleX: 1, scaleY: 1, top: '', width: ''};

function FloorMap(props) {
    let canvas = useRef();
    const canvasContainer = useRef();
    const currentFloor = props.location.state.floor;
    const currentOffice = props.location.state.office;
    const editNeighborhoodSelect = useRef();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEditingNeighborhood, setIsEditingNeighborhood] = useState(false);
    const [isNeighborhoodEditOpen, setIsNeighborhoodEditOpen] = useState(false);
    const [mapError, setMapError] = useState(false);
    let mapImage = useRef();
    const [neighborhoods, setNeighborhoods] = useState(currentFloor.neighborhoodList ? currentFloor.neighborhoodList : []);
    const neighborhoodUrl = '/api/v1/neighborhoods';
    let seats = useRef();
    const spaceUrl = '/api/v1/spaces/';

    const formatSelection = (selection) => {
        selection.borderColor = Constants.LIBERTY_YELLOW;
        selection.cornerColor = Constants.LIBERTY_YELLOW;
        selection.cornerSize = 8;
        selection.transparentCorners = false;
    };

    const saveSeat = useCallback(async (fabricSeat) => {
        resetNotifications();

        const seatMapJson = {
            angle: fabricSeat.angle ? fabricSeat.angle : 0,
            height: fabricSeat.getScaledHeight(),
            left: fabricSeat.left,
            scaleX: 1,
            scaleY: 1,
            top: fabricSeat.top,
            width: fabricSeat.getScaledWidth()
        };
        let currentSeat = seats.current.find(s => s.id === fabricSeat.id);
        currentSeat.spaceMapJson = JSON.stringify(seatMapJson);

        try {
            const response = await AxiosRequest.put(spaceUrl + fabricSeat.id, currentSeat);
            if (response.status !== 200) {
                setError(true);
                setErrorMessage('An error occurred saving a seat');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred saving a seat');
        }
    }, [seats]);

    const deactivateSeat = useCallback(async (id, canvasObject) => {
        try {
            resetNotifications();
            const newSeats = [...seats.current];
            const editIndex = newSeats.findIndex((space => space.id === id));
            newSeats[editIndex].isClientActive = false;
            const response = await AxiosRequest.put(spaceUrl + id, newSeats[editIndex]);
            if (response.status === 200) {
                seats.current = newSeats;
                canvas.current.remove(canvasObject);
            } else {
                setError(true);
                setErrorMessage('An error occurred deactivating a seat');
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred deactivating a seat');
        }
    }, [seats]);

    useEffect(() => {
        resetNotifications();

        canvas.current = new fabric.Canvas('floor-map', {
            altActionKey: 'none',
            height: canvasContainer.current.clientHeight,
            hoverCursor: 'pointer',
            includeDefaultValues: false,
            selection: true,
            selectionKey: ['ctrlKey', 'shiftKey'],
            width: canvasContainer.current.clientWidth
        });

        const setActions = () => {
            canvas.current.on({
                'mouse:down': (options) => {
                    if (!canvas.current.getActiveObject()) {
                        const e = options.e;
                        canvas.current.selection = false;
                        canvas.current.isDragging = true;
                        canvas.current.lastPosX = e.clientX;
                        canvas.current.lastPosY = e.clientY;
                    }
                },
                'mouse:up': () => {
                    if (canvas.current.isDragging) {
                        canvas.current.setViewportTransform(canvas.current.viewportTransform);
                        canvas.current.selection = true;
                        canvas.current.isDragging = false;
                    }
                },
                'mouse:move': (options) => {
                    if (canvas.current.isDragging) {
                        FloorMapUtils.dragCanvas(canvas.current, options.e);
                    }
                },
                'selection:updated': () => {
                    // Custom styles when multiple seats are selected
                    formatSelection(canvas.current.getActiveObject());
                },
                'object:modified': (options) => {
                    switch (options.target.type) {
                        case 'group':
                            saveSeat(options.target);
                            break;
                        case 'activeSelection':
                            // Neighborhoods are handled separately
                            if (options.target.getObjects('polygon').concat(options.target.getObjects('rect')).length === 0) {
                                // Loop through selected objects and save each one by making it active
                                options.target.getObjects().forEach((seat) => {
                                    canvas.current.setActiveObject(seat);
                                    saveSeat(seat);
                                });
                                // Reset active selection
                                const newActiveSelection = new fabric.ActiveSelection(options.target.getObjects(), {canvas: canvas.current});
                                formatSelection(newActiveSelection);
                                canvas.current.setActiveObject(newActiveSelection);
                            }
                            break;
                        default:
                            // Do nothing
                    }
                },
                'mouse:wheel': (options) => {
                    if (options.e.ctrlKey) {
                        options.e.preventDefault();
                        // WheelEvent.deltaY represents vertical scroll amount
                        if (options.e.deltaY < 0) {
                            FloorMapUtils.zoomIn(canvas.current);
                        } else {
                            FloorMapUtils.zoomOut(canvas.current);
                        }
                    }
                }
            });
        };
        setActions();

        // Some browsers' default behavior for Backspace is to navigate to previous page
        // window is used to prevent this behavior regardless of what has the focus on the page
        // With the addition of neighborhoods, text boxes need to be exempted
        const preventBackspace = (e) => {
            if (e.code === 'Backspace' && e.target.nodeName.toLowerCase() !== 'input' && e.target.nodeName.toLowerCase() !== 'textarea') {
                e.preventDefault();
            }
        };
        window.addEventListener('keydown', preventBackspace);

        const deactivateSpaceKeypress = (e) => {
            if (e.code === 'Delete' || e.code === 'Backspace') {
                if (canvas.current?.getActiveObject()) {
                    switch (canvas.current.getActiveObject().type) {
                        case 'group':
                            deactivateSeat(canvas.current.getActiveObject().id, canvas.current.getActiveObject());
                            break;
                        case 'activeSelection':
                            // Neighborhoods are handled separately
                            const activeObject = canvas.current.getActiveObject();
                            if (activeObject.getObjects('polygon').concat(activeObject.getObjects('rect')).length === 0) {
                                activeObject.getObjects().forEach((seat) => {
                                    canvas.current.setActiveObject(seat);
                                    deactivateSeat(seat.id, canvas.current.getActiveObject());
                                });
                            }
                            break;
                        default:
                            // Do nothing
                    }
                }
            }
        };
        document.addEventListener('keydown', deactivateSpaceKeypress);

        const setCanvasBackground = async () => {
            try {
                const response = await AxiosRequest.get('/api/v1/floors/' + currentFloor.id + '/maps');
                fabric.Image.fromURL(response.data, (floorMap) => {
                    mapImage.current = floorMap;
                    FloorMapUtils.resizeCanvas(canvas.current, canvas.current.width, mapImage.current);
                });
            } catch (err) {
                setError(true);
                setMapError(true);
                setErrorMessage('An error occurred retrieving the floor map image')
            }
        };
        setCanvasBackground();

        const setUpSeats = async () => {
            try {
                const response = await AxiosRequest.get('/api/v1/offices/' + currentOffice.id + '/floors/' + currentFloor.id + '/spaces');
                if (response.status === 200) {
                    seats.current = response.data;
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving seats for floor map');
                }
                let unplacedSeatsLeft = 20;
                let unplacedSeatsTop = 100;
                const activeSeats = seats.current.filter(s => s.isClientActive);
                for (let i = 0; i < activeSeats.length; i++) {
                    let seatMapJson;
                    if (activeSeats[i].spaceMapJson !== null) {
                        seatMapJson = JSON.parse(activeSeats[i].spaceMapJson);
                    } else {
                        seatMapJson = DEFAULT_SEAT_MAP_JSON;
                        seatMapJson.left = unplacedSeatsLeft;
                        seatMapJson.top = unplacedSeatsTop;
                    }

                    const seatGroup = FloorMapUtils.createFabricSeat(false, activeSeats[i].nickname ? activeSeats[i].nickname : activeSeats[i].spaceNumber, seatMapJson, activeSeats[i].id);
                    canvas.current.add(seatGroup);
                    if (activeSeats[i].spaceMapJson === null) {
                        unplacedSeatsLeft += seatGroup.width + 10;
                        if (canvas.current.width - (seatGroup.left + seatGroup.width) < 0) {
                            unplacedSeatsTop += seatGroup.height + 10;
                            unplacedSeatsLeft = 20;
                        }
                    }
                }
            } catch (err) {
                setError(true);
                setMapError(true);
                setErrorMessage('An error occurred retrieving and setting up the floor map');
            }
        };
        setUpSeats();

        const windowResize = () => {
            const newWindowWidth = canvasContainer.current.clientWidth;
            canvas.current.setWidth(newWindowWidth);
            FloorMapUtils.resizeCanvas(canvas.current, newWindowWidth, mapImage.current);
        };
        window.addEventListener('resize', windowResize);

        return () => {
            window.removeEventListener('resize', windowResize);
            window.removeEventListener('keydown', preventBackspace);
            document.removeEventListener('keydown', deactivateSpaceKeypress)
        }
    }, [currentFloor, currentOffice, seats, saveSeat, deactivateSeat]);

    useEffect(() => {
        if (canvas.current.getObjects('polygon').concat(canvas.current.getObjects('rect')).length === 0) {
            neighborhoods.forEach((n) => {
                NeighborhoodUtils.createFabricNeighborhood(canvas.current, n.neighborhoodName, null, n.id, JSON.parse(n.neighborhoodMapJson));
            });
        }
    }, [neighborhoods]);

    const resetNotifications = () => {
        setError(false);
        setMapError(false);
        setErrorMessage('');
    }

    const createNeighborhood = () => {
        NeighborhoodUtils.createFabricNeighborhood(canvas.current,
            document.getElementById('create-neighborhood-text').value,
            document.getElementById('create-neighborhood-points').value);

        // Clear neighborhood selection, highlight edit button to provide "direction"
        editNeighborhoodSelect.current.selectedIndex = 0;
        document.getElementById('edit-neighborhood-button').focus();

        editNeighborhood();
    };

    const editNeighborhood = () => {
        let neighborhoodToEdit, neighborhoodTextToEdit;

        let neighborhoodId = editNeighborhoodSelect.current.selectedOptions[0].value;
        const fabricNeighborhoods = canvas.current.getObjects('polygon').concat(canvas.current.getObjects('rect'));
        for (let fabricNeighborhood of fabricNeighborhoods) {
            if (fabricNeighborhood.id === +neighborhoodId) {
                neighborhoodToEdit = fabricNeighborhood;
                neighborhoodTextToEdit = canvas.current.getObjects('textbox').find(tb => tb.id === +neighborhoodId);
                break;
            }
        }

        if (neighborhoodToEdit) {
            if (!isEditingNeighborhood) {
                setIsEditingNeighborhood(true);

                // Disable all seats and enable the polygon and textbox being edited
                canvas.current.getObjects('group').forEach(obj => obj.selectable = false);
                neighborhoodToEdit.selectable = true;
                neighborhoodTextToEdit.selectable = true;
                neighborhoodTextToEdit.editable = true;

                // Create custom controls to allow 8-point polygon modification, format polygon for editing
                if (neighborhoodToEdit.points?.length === 8) {
                    neighborhoodToEdit.controls = NeighborhoodUtils.createPolygonControls(neighborhoodToEdit.points);
                    neighborhoodToEdit.cornerStyle = 'circle';
                    neighborhoodToEdit.hasBorders = false;
                    neighborhoodToEdit.hoverCursor = 'pointer';
                }

                // Bring the polygon and text to the front for easy selecting
                canvas.current.bringToFront(neighborhoodToEdit);
                canvas.current.bringToFront(neighborhoodTextToEdit);

                // Select the polygon and text so the admin knows those are editable
                const newActiveSelection = new fabric.ActiveSelection([neighborhoodToEdit, neighborhoodTextToEdit], {canvas: canvas.current});
                formatSelection(newActiveSelection);
                canvas.current.setActiveObject(newActiveSelection);
            } else {
                setIsEditingNeighborhood(false);

                if (neighborhoodTextToEdit.neighborhoodName !== neighborhoodTextToEdit.text) {
                    neighborhoodToEdit.neighborhoodName =
                        neighborhoodTextToEdit.neighborhoodName =
                            neighborhoodTextToEdit.text;
                }

                // Deselect objects for accurate property values
                canvas.current.discardActiveObject();

                // Replace custom controls with default ones, reset polygon formatting
                neighborhoodToEdit.controls = fabric.Object.prototype.controls;
                neighborhoodToEdit.cornerStyle = 'rect';
                neighborhoodToEdit.hasBorders = true;
                neighborhoodToEdit.hoverCursor = 'default';

                saveNeighborhood(neighborhoodToEdit, neighborhoodTextToEdit);

                // Send the polygon and text to the back
                canvas.current.sendToBack(neighborhoodTextToEdit);
                canvas.current.sendToBack(neighborhoodToEdit);

                // Re-enable all seats, lock neighborhood polygon and text
                canvas.current.getObjects('group').forEach(obj => obj.selectable = true);
                neighborhoodToEdit.selectable = false;
                neighborhoodTextToEdit.selectable = false;
                neighborhoodTextToEdit.editable = false;
            }
        } else {
            alert('Please select a neighborhood to edit');
            return;
        }

        canvas.current.requestRenderAll();
    };

    const saveNeighborhood = async (neighborhoodToEdit, neighborhoodTextToEdit) => {
        resetNotifications();

        try {
            let neighborhoodMapJson = {
                'polygon': {
                    angle: neighborhoodToEdit.angle,
                    height: neighborhoodToEdit.height,
                    left: neighborhoodToEdit.left,
                    points: neighborhoodToEdit.points,
                    scaleX: neighborhoodToEdit.scaleX,
                    scaleY: neighborhoodToEdit.scaleY,
                    top: neighborhoodToEdit.top,
                    width: neighborhoodToEdit.width
                },
                'textbox': {
                    angle: neighborhoodTextToEdit.angle,
                    height: neighborhoodTextToEdit.height,
                    left: neighborhoodTextToEdit.left,
                    scaleX: neighborhoodTextToEdit.scaleX,
                    scaleY: neighborhoodTextToEdit.scaleY,
                    top: neighborhoodTextToEdit.top,
                    width: neighborhoodTextToEdit.width
                }
            };

            // If neighborhood does not contain a valid ID, it is a new neighborhood
            let currentNeighborhood, response;
            if (neighborhoodToEdit.id) {
                const neighborhoodIndex = neighborhoods.findIndex(n => n.id === neighborhoodToEdit.id);
                const newNeighborhoods = [...neighborhoods];
                newNeighborhoods[neighborhoodIndex].neighborhoodName = neighborhoodTextToEdit.text;
                newNeighborhoods[neighborhoodIndex].neighborhoodMapJson = JSON.stringify(neighborhoodMapJson);
                response = await AxiosRequest.put(`${neighborhoodUrl}/${neighborhoodToEdit.id}`, newNeighborhoods[neighborhoodIndex]);
                if (response.status === 200) {
                    setNeighborhoods(newNeighborhoods);
                    currentFloor.neighborhoodList = newNeighborhoods;
                } else {
                    setError(true);
                    setErrorMessage('An error occurred saving a neighborhood');
                }
            } else {
                currentNeighborhood = {
                    neighborhoodName: neighborhoodTextToEdit.text,
                    floorId: currentFloor.id,
                    neighborhoodMapJson: JSON.stringify(neighborhoodMapJson)
                };
                response = await AxiosRequest.post(neighborhoodUrl, currentNeighborhood);
                if (response.status === 201) {
                    const newNeighborhoods = [...neighborhoods, response.data];
                    setNeighborhoods(newNeighborhoods);
                    currentFloor.neighborhoodList = newNeighborhoods;
                    neighborhoodToEdit.id = neighborhoodTextToEdit.id = response.data.id;
                } else {
                    setError(true);
                    setErrorMessage('An error occurred saving a neighborhood');
                }
            }
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred saving a neighborhood');
        }
    };

    const deleteNeighborhood = async () => {
        canvas.current.discardActiveObject();

        resetNotifications();

        try {
            const selectedNeighborhoodId = editNeighborhoodSelect.current.selectedOptions[0].value;
            if (selectedNeighborhoodId) {
                const response = await AxiosRequest.destroy(`${neighborhoodUrl}/${selectedNeighborhoodId}`);
                if (response.status === 204) {
                    const newNeighborhoods = neighborhoods.filter(n => n.id !== +selectedNeighborhoodId);
                    setNeighborhoods(newNeighborhoods);
                    currentFloor.neighborhoodList = newNeighborhoods;
                } else {
                    setError(true);
                    setErrorMessage('An error occurred deleting a neighborhood');
                }
            }
            NeighborhoodUtils.removeNeighborhoodFromCanvas(canvas.current, +selectedNeighborhoodId);

            // Re-enable all seats
            canvas.current.getObjects('group').forEach(obj => obj.selectable = true);

            setIsEditingNeighborhood(false);
        } catch (err) {
            setError(true);
            setErrorMessage('An error occurred deleting a neighborhood');
        }
    };

    const cancelEditNeighborhood = () => {
        canvas.current.discardActiveObject();

        const selectedNeighborhoodId = editNeighborhoodSelect.current.selectedOptions[0].value;
        NeighborhoodUtils.removeNeighborhoodFromCanvas(canvas.current, +selectedNeighborhoodId);

        // If neighborhood exists from database, replace it with original JSON
        if (+selectedNeighborhoodId) {
            const selectedNeighborhood = neighborhoods.find(n => n.id === +selectedNeighborhoodId);
            NeighborhoodUtils.createFabricNeighborhood(canvas.current, selectedNeighborhood.neighborhoodName, null, selectedNeighborhood.id, JSON.parse(selectedNeighborhood.neighborhoodMapJson));
        }

        // Re-enable all seats
        canvas.current.getObjects('group').forEach(obj => obj.selectable = true);

        setIsEditingNeighborhood(false);
    };

    return (
        <React.Fragment>
            <Notification notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}</Notification>
            <div className={'greeting'}>
                <Link to={'/admin'}>Home</Link> &gt; <Link to={'/admin/manage-office-choice'}>Offices</Link>
                &nbsp;&gt;&nbsp;
                <Link to={{pathname: '/admin/manage-offices', state: {isIntl: currentOffice.officeCountry === 'US' ? false : true}}}>
                    {currentOffice.officeCountry === 'US' ? 'US' : 'International'} Offices
                </Link>
                &nbsp;&gt;&nbsp;
                <Link to={{pathname: '/admin/manage-floors', state: {office: currentOffice, floor: currentFloor}}}>
                    {currentOffice.nickname ? currentOffice.nickname : currentOffice.officeName} ({currentOffice.officeNumber})
                </Link>
                &nbsp;&gt;&nbsp;
                <Link to={{pathname: '/admin/manage-spaces', state: {office: currentOffice, floor: currentFloor}}}>
                    {currentFloor.nickname ? currentFloor.nickname : currentFloor.floorNumber}
                </Link>
            </div>
            <section>
                <h1>Floor Map
                    <img src={question_image} alt={'help icon'} height={'24px'} style={{verticalAlign: 'super'}}
                         title={'To add a seat: Activate it from the seat list\nTo deactivate a seat: Click on a seat and press the delete or backspace key\nTo select multiple seats: Hold the shift or control key while clicking seats to add/remove'}/>
                </h1>
                <div id={'floor-map-actions'} className={'pb-2'}>
                    <button className={'btn btn--secondary mr-1'} title={'ctrl + mouse wheel down'} onClick={() => FloorMapUtils.zoomOut(canvas.current)}>Zoom Out</button>
                    <button className={'btn btn--secondary mr-1'} title={'ctrl + mouse wheel up'} onClick={() => FloorMapUtils.zoomIn(canvas.current)}>Zoom In</button>
                    <button className={'btn btn--primary'} data-toggle={'collapse'} data-target={'#floor-map-neighborhoods'} onClick={() => setIsNeighborhoodEditOpen(!isNeighborhoodEditOpen)}>Edit Neighborhoods <img src={isNeighborhoodEditOpen ? caret_up_image : caret_down_image} alt={'collapse'} height={'10px'} className={'ml-1'}/></button>
                </div>
                <div id={'floor-map-neighborhoods'} className={'py-2 collapse'}>
                    <form className={'form d-inline-block mr-4'} onSubmit={(event) => {event.preventDefault(); createNeighborhood(); event.currentTarget.reset();}}>
                        <input type={'text'} required id={'create-neighborhood-text'} placeholder={'Neighborhood Name'} disabled={isEditingNeighborhood}/>
                        <select id={'create-neighborhood-points'} defaultValue={8} disabled={isEditingNeighborhood}>
                            <option value={4}>Rectangle</option>
                            <option value={8}>Polygon</option>
                        </select>
                        <input type={'submit'} id={'create-neighborhood-button'} className={'btn btn--secondary mr-4 mb-1'} value={'Create'} disabled={isEditingNeighborhood}/>
                    </form>
                    <form className={'form d-inline-block'} onSubmit={(event) => event.preventDefault()}>
                        <select ref={editNeighborhoodSelect} disabled={isEditingNeighborhood} required>
                            <option value={''}>Select a Neighborhood</option>
                            {neighborhoods
                                .map(
                                    (neighborhood, index) =>
                                        <option key={index} value={neighborhood.id}>{neighborhood.neighborhoodName}</option>
                                )
                            }
                        </select>
                        <input type={'button'} id={'edit-neighborhood-button'} className={'btn btn--primary mb-1 mr-1'} value={isEditingNeighborhood ? 'Save' : 'Edit'} onClick={() => editNeighborhood()}/>
                        <input type={'button'} id={'cancel-edit-neighborhood-button'} className={'btn btn--secondary mb-1 mr-1'} value={'Cancel'} disabled={!isEditingNeighborhood} onClick={() => cancelEditNeighborhood()}/>
                        <input type={'button'} id={'delete-neighborhood-button'} className={'btn btn--secondary mb-1'} value={'Delete'} disabled={!isEditingNeighborhood} onClick={() => deleteNeighborhood()}/>
                    </form>
                </div>
                <div id={'floor-map-container'} ref={canvasContainer} className={mapError ? 'd-none' : ''}>
                    <canvas id={'floor-map'} ref={canvas} style={{border: '1px solid ' + Constants.LIBERTY_DARK_GRAY}} />
                </div>
            </section>
        </React.Fragment>
    );
}

export default FloorMap;