import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import AxiosRequest from '../../utilities/AxiosRequest';
import {fabric} from 'fabric';
import * as FloorMapUtils from '../../utilities/FloorMapUtils';
import {Link} from 'react-router-dom';
import UserContext from '../../context/UserContext';
import Notification from '../standard/Notification';
import * as NeighborhoodUtils from '../../utilities/NeighborhoodUtils';
import GraphUtils from '../../utilities/GraphUtils';
import * as Constants from '../../utilities/Constants';
import BehalfDrawer from './BehalfDrawer';
import ReserveFilters from './ReserveFilters';
import ReserveMenu from './ReserveMenu';
import * as DateUtils from '../../utilities/DateUtils';
import caution_image from '../../images/caution.png';
import Textbox from '../standard/Textbox';
import plus from '../../images/plus.png';
import minus from '../../images/minus.png';
import {createSpaceCalendarInvite} from '../../utilities/EmailCalendarUtils';

function ReserveSpace(props) {
    const [atMaxCapacity, setAtMaxCapacity] = useState(props.location.state.atMaxCapacity);
    const [availableSpaceCount, setAvailableSpaceCount] = useState(props.location.state.floor.availableSpaceCount);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    let canvas = useRef();
    const canvasContainer = useRef();
    const [createdReservation, setCreatedReservation] = useState(null);
    const currentFloor = useRef(props.location.state.floor);
    const currentOffice = useRef(props.location.state.office);
    let dragViewportStart = useRef();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const firstRender = useRef(true);
    const [hasAccessibility, setHasAccessibility] = useState(false);
    const [isBehalfDrawerMinimized, setIsBehalfDrawerMinimized] = useState(false);
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    let mapImage = useRef();
    const [multiBehalfReservationList, setMultiBehalfReservationList] = useState([]);
    const [multiSeats, setMultiSeats] = useState([]);
    let multiSeatsRef = useRef([]);
    const multiSelectCheckbox = useRef();
    const [reservations, setReservations] = useState([]);
    let reserveDropdown = useRef();
    const [saveSuccess, setSaveSuccess] = useState(false);
    let seats = useRef();
    const [selectedDates, setSelectedDates] = useState(props.location.state.selectedDates);
    const [selectedHours, setSelectedHours] = useState(props.location.state.selectedHours);
    const [selectedSeat, setSelectedSeat] = useState(null);
    const [selectedSeatAssignedUsers, setSelectedSeatAssignedUsers] = useState([]);
    let touchZoomStart = useRef(1);
    let user = useRef(useContext(UserContext));
    const [userReservations, setUserReservations] = useState(props.location.state.userReservations);

    // Remove reserve dropdown from canvas
    const hideReserveDropdown = () => {
        reserveDropdown.current.style.visibility = 'hidden';
    };

    const resetMap = () => {
        // Resize the map to fit the container and then move the viewport back
        FloorMapUtils.resizeCanvas(canvas.current, canvasContainer.current.clientWidth, mapImage.current);
        let newViewport = canvas.current.viewportTransform;
        newViewport[4] = 0;
        newViewport[5] = 0;
        canvas.current.setViewportTransform(newViewport);
    };

    const getReservations = useCallback(async () => {
        let response = await AxiosRequest.get(`/api/v1/floors/${currentFloor.current.id}/reservations?startDate=${DateUtils.getYMDDate(selectedDates[0])}&endDate=${DateUtils.getYMDDate(selectedDates[1])}&startHour=${selectedHours[0]}&endHour=${selectedHours[1]}`);
        if (response.status === 200) {
            const sortedReservations = response.data.sort((r1, r2) => new Date(r1.reservationDate) - new Date(r2.reservationDate));
            setReservations(sortedReservations);
            for (let i = 0; i < seats.current.length; i++) {
                if (seats.current[i].spaceMapJson !== null) {
                    seats.current[i].reservations = sortedReservations.filter(r => r.spaceId === seats.current[i].id);
                    let fabricSeatGroup = canvas.current.getObjects('group').find(seat => seat.id === seats.current[i].id);
                    FloorMapUtils.formatFabricSeat(fabricSeatGroup, selectedDates, selectedHours, seats.current[i], user.current, canvas.current, hasAccessibility);
                    fabricSeatGroup.dirty = true;
                }
            }
            canvas.current.requestRenderAll();

            // Refresh floor capacity
            response = await AxiosRequest.get(`/api/v1/floors/${currentFloor.current.id}?startDate=${DateUtils.getYMDDate(selectedDates[0])}&endDate=${DateUtils.getYMDDate(selectedDates[1])}&startHour=${selectedHours[0]}&endHour=${selectedHours[1]}`);
            if (response.status === 200) {
                currentFloor.current = response.data;
                setAtMaxCapacity(response.data.currentCapacity >= 100);
                setAvailableSpaceCount(Math.min(response.data.availableSpaceCount, seats.current?.filter(s => s.isFullyAvailable).length));
            }
        }
    }, [selectedDates, selectedHours, hasAccessibility]);

    useEffect(() => {
        resetErrorNotifications();

        // Once Fabric has loaded the canvas (getObjects is a Fabric method), there is no need to do it again
        if (typeof canvas.current.getObjects !== 'function') {
            canvas.current = new fabric.Canvas('floor-map', {
                allowTouchScrolling: false,
                height: canvasContainer.current.clientHeight,
                hoverCursor: 'pointer',
                selection: false,
                width: canvasContainer.current.clientWidth
            });

            canvas.current.on({
                'mouse:down:before': (options) => {
                    // Disable dragging if there are two fingers
                    // Also get starting zoom number
                    if (options.e.touches && options.e.touches.length > 1) {
                        canvas.current.isTouchDragging = false;
                        touchZoomStart.current = canvas.current.getZoom();
                    }
                },
                'mouse:down': (options) => {
                    hideReserveDropdown();

                    // Save start of viewportTransform for determining if the canvas has moved
                    dragViewportStart.current = [canvas.current.viewportTransform[4], canvas.current.viewportTransform[5]];

                    if (options.e.type.startsWith('mouse')) {
                        canvas.current.isDragging = true;
                        canvas.current.lastPosX = options.e.clientX;
                        canvas.current.lastPosY = options.e.clientY;
                    }
                },
                'mouse:move': (options) => {
                    if (options.e.type.startsWith('mouse')) {
                        if (canvas.current.isDragging) {
                            FloorMapUtils.dragCanvas(canvas.current, options.e);
                        }
                    }
                },
                'mouse:wheel': (options) => {
                    if (options.e.ctrlKey) {
                        options.e.preventDefault();
                        // WheelEvent.deltaY represents vertical scroll amount
                        if (options.e.deltaY < 0) {
                            FloorMapUtils.zoomIn(canvas.current);
                        } else {
                            FloorMapUtils.zoomOut(canvas.current);
                        }
                    }
                },
                'touch:gesture': (options) => {
                    if (options.e.touches.length > 1) {
                        const scaledZoom = touchZoomStart.current * options.self.scale;
                        const pointBetweenFingers = new fabric.Point(options.self.x, options.self.y);
                        canvas.current.zoomToPoint(pointBetweenFingers, scaledZoom);
                    }
                },
                'touch:drag': (options) => {
                    if ((options.e.touches && options.e.touches.length === 1) || canvas.current.isTouchDragging) {
                        if (options.self.state === 'down') {
                            canvas.current.isTouchDragging = true;
                            canvas.current.lastPosX = options.self.x;
                            canvas.current.lastPosY = options.self.y;
                        } else if (options.self.state === 'move') {
                            if (canvas.current.isTouchDragging) {
                                let vpt = canvas.current.viewportTransform;
                                vpt[4] += options.self.x - canvas.current.lastPosX;
                                vpt[5] += options.self.y - canvas.current.lastPosY;
                                canvas.current.requestRenderAll();
                                canvas.current.lastPosX = options.self.x;
                                canvas.current.lastPosY = options.self.y;
                            }
                        } else if (options.self.state === 'up') {
                            if (canvas.current.isTouchDragging) {
                                canvas.current.setViewportTransform(canvas.current.viewportTransform);
                                canvas.current.isTouchDragging = false;
                            }
                        }
                    }
                }
            });

            const setCanvasBackground = async () => {
                if (currentFloor.current.hasMap) {
                    try {
                        const response = await AxiosRequest.get('/api/v1/floors/' + currentFloor.current.id + '/maps');
                        if (response.status === 200) {
                            fabric.Image.fromURL(response.data, (floorMap) => {
                                mapImage.current = floorMap;
                                FloorMapUtils.resizeCanvas(canvas.current, canvas.current.width, mapImage.current);
                            }, {objectCaching: false});
                        } else {
                            setError(true);
                            setErrorMessage('An error occurred loading the floor');
                        }
                    } catch (err) {
                        setError(true);
                        setErrorMessage('An error occurred loading the floor');
                    }
                } else {
                    canvasContainer.current.hidden = true;
                }
            };
            setCanvasBackground();

            const setUpSeats = async () => {
                try {
                    const response = await AxiosRequest.get('/api/v1/offices/' + currentOffice.current.id + '/floors/' + currentFloor.current.id + '/spaces');
                    if (response.status === 200) {
                        seats.current = response.data.filter((s) => s.isClientActive);
                    } else {
                        setError(true);
                        setErrorMessage('An error occurred loading the floor');
                    }
                    for (let i = 0; i < seats.current.length; i++) {
                        if (seats.current[i].spaceMapJson !== null) {
                            const seatGroup = FloorMapUtils.createFabricSeat(true, seats.current[i].nickname ? seats.current[i].nickname : seats.current[i].spaceNumber, JSON.parse(seats.current[i].spaceMapJson), seats.current[i].id);
                            canvas.current.add(seatGroup);
                        }
                    }
                    getReservations();
                } catch (err) {
                    setError(true);
                    setErrorMessage('An error occurred loading the floor');
                }
            };
            setUpSeats();

            const loadNeighborhoods = () => {
                currentFloor.current.neighborhoodList.forEach((n) => {
                    NeighborhoodUtils.createFabricNeighborhood(canvas.current, n.neighborhoodName, null, n.id, JSON.parse(n.neighborhoodMapJson));
                });
            };
            loadNeighborhoods();
        }

        const windowResize = () => {
            const newWindowWidth = canvasContainer.current.clientWidth;
            canvas.current.setWidth(newWindowWidth);
            FloorMapUtils.resizeCanvas(canvas.current, newWindowWidth, mapImage.current);
        };
        window.addEventListener('resize', windowResize);

        return () => {
            window.removeEventListener('resize', windowResize);
        }
    },[getReservations]);

    useEffect(() => {
        const getAssignedUsers = async (assignedSeat) => {
            let assignedUsers = await GraphUtils.getAssignedSeatUsers(assignedSeat.assignedUserIds);
            setSelectedSeatAssignedUsers(assignedUsers);
        };

        canvas.current.__eventListeners['mouse:up'] = [];
        canvas.current.on({'mouse:up': (options) => {
            if (options.e.type.startsWith('mouse')) {
                if (canvas.current.isDragging) {
                    canvas.current.setViewportTransform(canvas.current.viewportTransform);
                    canvas.current.isDragging = false;
                }
            }
            if (!FloorMapUtils.didCanvasDrag(canvas.current, dragViewportStart.current) && canvas.current.findTarget(options)) {
                const mousePointer = canvas.current.getPointer(options);
                const fabricObject = canvas.current.findTarget(options);
                if (fabricObject.type === 'group' && !fabricObject.partialAvailabilityId) {
                    const seat = seats.current.find(seat => seat.id === fabricObject.id);
                    if (!isMultiSelect) {
                        setSelectedSeat(seat);
                        setSelectedSeatAssignedUsers([]);
                        if (seat.assignedUserIds) {
                            getAssignedUsers(seat);
                        }
                        // Move dropdown to where the mouse clicked and show it
                        //  * canvas.current._offset is the location of the canvas on the page
                        //  * canvas.current.viewportTransform[4/5] is the left/top edge of the map image within the canvas
                        //  * the point on the canvas has to be adjusted for the zoom level <-- confusing as hell
                        const dropdownLeft = canvas.current._offset.left + (mousePointer.x * canvas.current.getZoom()) + canvas.current.viewportTransform[4];
                        reserveDropdown.current.style.left = dropdownLeft + 'px';
                        reserveDropdown.current.style.top = (canvas.current._offset.top + (mousePointer.y * canvas.current.getZoom()) + canvas.current.viewportTransform[5]) + 'px';
                        // Fit dropdown to window:
                        //  * if the computed width extends beyond the right edge of the window, shift left to show full dropdown
                        //  * because the dropdown is visibility:hidden it changes the window.innerWidth so we have to
                        //    use the canvas container for window width
                        const dropdownRight = dropdownLeft + Math.round(window.getComputedStyle(reserveDropdown.current)['width'].slice(0, -2));
                        if (dropdownRight > canvasContainer.current.clientWidth) {
                            reserveDropdown.current.style.left = (dropdownLeft - (dropdownRight - canvasContainer.current.clientWidth)) + 'px';
                        }
                        reserveDropdown.current.style.visibility = 'visible';
                    } else {
                        const existingSeat = multiSeatsRef.current.filter((multiSeat) => multiSeat.id === seat.id);
                        if (existingSeat.length > 0) {
                            multiSeatsRef.current = multiSeatsRef.current.filter((multiSeat) => multiSeat.id !== seat.id);
                            setMultiSeats(multiSeatsRef.current);

                            fabricObject.getObjects('rect').forEach((rectangle) => {
                                rectangle.stroke = Constants.LIBERTY_DARK_GRAY;
                                rectangle.strokeWidth = 1;
                            });
                            fabricObject.dirty = true;
                            canvas.current.requestRenderAll();

                            setMultiBehalfReservationList(multiBehalfReservationList.filter((reservation) => reservation.spaceId !== seat.id));
                        } else if (seat.isFullyAvailable && multiSeatsRef.current.length < availableSpaceCount) {
                            multiSeatsRef.current = [...multiSeatsRef.current, seat];
                            setMultiSeats(multiSeatsRef.current);

                            fabricObject.getObjects('rect').forEach((rectangle) => {
                                rectangle.stroke = Constants.LIBERTY_YELLOW;
                                rectangle.strokeWidth = 5;
                            });
                            fabricObject.dirty = true;
                            canvas.current.requestRenderAll();
                        }
                    }
                }
            }
        }});
    }, [isMultiSelect, availableSpaceCount, multiBehalfReservationList]);

    const resetErrorNotifications = () => {
        setError(false);
        setErrorMessage('');
    }

    const toggleMultiSelect = () => {
        hideReserveDropdown();
        if (isMultiSelect) { // Toggling off
            if (multiSeatsRef.current.length > 0) {
                if (window.confirm('Selected seats will be lost, are you sure you wish to continue?')) {
                    multiSeats.forEach((seat) => removeMultiSeat(seat.id));
                    setIsMultiSelect(false);
                    setMultiSeats([]);
                    multiSeatsRef.current = [];
                    multiSelectCheckbox.current.checked = false;
                } else {
                    multiSelectCheckbox.current.checked = true;
                }
            } else {
                setIsMultiSelect(false);
                setMultiSeats([]);
                multiSelectCheckbox.current.checked = false;
            }
            setMultiBehalfReservationList([]);
        } else {
            setIsMultiSelect(true)
            if (isBehalfDrawerMinimized) { // If drawer state is minimized when user toggles functionality back on, reset
                setIsBehalfDrawerMinimized(false);
            }
            multiSelectCheckbox.current.checked = true;
        }
    }

    const minimizeDrawer = () => {
        setIsBehalfDrawerMinimized(!isBehalfDrawerMinimized);
    }

    const removeMultiSeat = (seatId) => {
        const updatedMultiSeats = multiSeatsRef.current.filter((seat) => seat.id !== seatId);
        let matchingSeat = canvas.current.getObjects('group').find(seat => seat.id === seatId);
        matchingSeat.getObjects('rect').forEach((rectangle) => {
            rectangle.stroke = Constants.LIBERTY_DARK_GRAY;
            rectangle.strokeWidth = 1;
        });
        matchingSeat.dirty = true;
        canvas.current.requestRenderAll();

        multiSeatsRef.current = updatedMultiSeats;
        setMultiSeats(updatedMultiSeats);
    }

    const updateMultiSeatReservedForName = (seatId, displayName) => {
        multiSeatsRef.current = multiSeatsRef.current.map(seat =>
             seat.id === seatId ? {...seat, reservedForName: displayName} : seat
        );
        setMultiSeats(multiSeatsRef.current);
    }

    useEffect(() => {
        const refreshReservations = async () => {
            try {
                // Update user with floor eligibility
                const response = await AxiosRequest.get(`/api/v1/users/${user.current.id}?endDate=${DateUtils.getYMDDate(selectedDates[1])}&floorId=${currentFloor.current.id}`);
                if (response.status === 200) {
                    user.current = response.data;
                } else {
                    setError(true);
                    setErrorMessage('An error occurred retrieving user');
                }
                // Refresh reservation list
                setReservations(null);
                if (!firstRender.current) {
                    getReservations();
                } else {
                    firstRender.current = false;
                }
            } catch (error) {
                setError(true);
                setErrorMessage(error?.response?.data?.message || error?.response?.data?.error || 'An unexpected error occurred');
            }
        };
        refreshReservations();
    }, [selectedDates, getReservations]);

    const searchReservations = (textboxEvent) => {
        const displayText = textboxEvent.target.value.trim();
        const fabricGroups = canvas.current.getObjects('group');
        // Match all words in any order using lookahead regular expression
        // Example: /(?=.*Carlos)(?=.*Guerr)/gi
        const regexp = new RegExp('(?=.*' + displayText.split(/\s|,/).join(')(?=.*') + ')', 'gi');
        // Loop through space IDs to enable searching seat reservations with more than one employee (shared)
        const reservationSpaces = new Set(reservations.map(r => r.spaceId));
        reservationSpaces.forEach(id => {
            let fabricSeatGroup = fabricGroups.find(g => g.id === id);
            // Reservations for inactive seats will blow things up
            if (fabricSeatGroup) {
                let fabricSeatText = fabricSeatGroup.getObjects('textbox')[0];
                const reservationNames = new Set(reservations.filter(r => r.spaceId === id).map(r => r.employeeName));
                let doesMatch = false;
                reservationNames.forEach(name => {
                    // Check for non-space value and regex (can't leverage built-in Textbox functionality because it uses onBlur)
                    if (displayText.replace(' ', '').length > 0 && Array.isArray(name.match(regexp))) {
                        doesMatch = true;
                    }
                });
                fabricSeatText.set('textBackgroundColor', doesMatch ? Constants.LIBERTY_GREEN : null);
                fabricSeatGroup.dirty = true;
            }
        });
        canvas.current.requestRenderAll();
    };

    const clearSearch = () => {
        const fabricGroups = canvas.current.getObjects('group');
        fabricGroups.forEach(group => {
            let fabricSeatText = group.getObjects('textbox')[0];
            fabricSeatText.set('textBackgroundColor', null);
            group.dirty = true;
        });
        canvas.current.requestRenderAll();
    };

    return(
        <React.Fragment>
            <Notification id={'reserve-success-notification'} notificationType={'success'} dismissable={true} dismissText={'Click to close'} active={saveSuccess} handleDismiss={() => setSaveSuccess(false)}>Workspace successfully reserved<br/><Link to={'/reservation-dashboard'}>View Upcoming Reservations</Link><br/><span className={'link'} style={{fontSize: '16px'}} onClick={createdReservation !== null ? () => createSpaceCalendarInvite(...createdReservation, (currentFloor.current.nickname !== null && currentFloor.current.nickname !== undefined ? currentFloor.current.nickname : currentFloor.current.floorNumber)) : null}>Download Calendar Invite</span></Notification>
            <Notification id={'cancel-success-notification'} notificationType={'success'} dismissable={true} dismissText={'Click to close'} active={cancelSuccess} handleDismiss={() => setCancelSuccess(false)}>Reservation successfully cancelled</Notification>
            <Notification id={'reserve-error-notification'} notificationType={'error'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetErrorNotifications}>{errorMessage}</Notification>
            <BehalfDrawer selectedSeats={multiSeats} multiSeatsRef={multiSeatsRef} removeSelectedSeat={removeMultiSeat} updateReservedFor={updateMultiSeatReservedForName} isMultiSelect={isMultiSelect} dates={[DateUtils.getYMDDate(selectedDates[0]), DateUtils.getYMDDate(selectedDates[1])]} handleMinimize={minimizeDrawer}
                          isMinimized={isBehalfDrawerMinimized} availableSpaceCount={availableSpaceCount} selectedHours={selectedHours} setSaveSuccess={setSaveSuccess} setError={setError} setErrorMessage={setErrorMessage} resetErrorNotifications={resetErrorNotifications} toggleMultiSelect={toggleMultiSelect}
                          getReservations={getReservations} currentOffice={currentOffice} userReservations={userReservations} setUserReservations={setUserReservations} multiBehalfReservationList={multiBehalfReservationList} setMultiBehalfReservationList={setMultiBehalfReservationList}/>
            <div id={'reserve-space'} className={'mx-auto'}>
                <div className={'greeting'}>
                    <Link to={'/'}>Home</Link>
                    &nbsp;&gt;&nbsp;
                    <Link to={{pathname: '/reserve-floors', state: {office: currentOffice.current, selectedDates: selectedDates, userReservations: userReservations, selectedHours: selectedHours}}}>{currentOffice.current.nickname ? currentOffice.current.nickname : currentOffice.current.officeName} ({currentOffice.current.officeNumber})</Link>
                    &nbsp;&gt;&nbsp;{currentFloor.current.nickname ? currentFloor.current.nickname + ' (' + currentFloor.current.floorNumber + ')' : currentFloor.current.floorNumber}
                </div>
                <ReserveFilters selectedDates={selectedDates} setSelectedDates={setSelectedDates} selectedHours={selectedHours} setSelectedHours={setSelectedHours} hideReserveDropdown={hideReserveDropdown} hasAccessibility={hasAccessibility} setHasAccessibility={setHasAccessibility}
                                currentOffice={currentOffice} currentFloor={currentFloor} atMaxCapacity={atMaxCapacity} userReservations={userReservations} availableSpaceCount={availableSpaceCount} multiSelectCheckbox={multiSelectCheckbox} toggleMultiSelect={toggleMultiSelect} {...props}/>
            </div>
            <div id={'reserve-map-actions'} className={'row mx-4 my-2 flex-column flex-sm-row'}>
                <div className={'col pl-sm-0 pt-2 pt-sm-0 align-self-center text-center text-sm-left order-2 order-sm-1'}>
                    <button className={'btn btn--secondary mr-2 d-none d-sm-inline-block circle-button'} title={'Zoom Out: CTRL + mouse wheel down on map'} onClick={() => FloorMapUtils.zoomOut(canvas.current)}><img src={minus} width={30} style={{padding: '0 8px 5px 8px'}} alt={'Zoom Out: CTRL + mouse wheel down on map'}/></button>
                    <button className={'btn btn--secondary mr-2 d-none d-sm-inline-block circle-button'} title={'Zoom In: CTRL + mouse wheel up on map'} onClick={() => FloorMapUtils.zoomIn(canvas.current)}><img src={plus} width={30} style={{padding: '0 8px 5px 8px'}} alt={'Zoom In: CTRL + mouse wheel up on map'}/></button>
                    <button className={'btn btn--secondary'} onClick={() => resetMap()}>Reset Map</button>
                </div>
                <div className={'col col-sm-5 col-md-4 col-lg-3 pr-sm-0 order-1 order-sm-2'} style={{marginBottom: '-8px'}}>
                    <Textbox id={'reservation-search'} placeholderText={'Search for a colleague on the map'} changeMethod={searchReservations} isDisabled={!reservations || reservations.length === 0} allowAll={true} clearMethod={clearSearch} />
                </div>
            </div>
            <div id={'reserve-map-container'} ref={canvasContainer} className={'mx-4 mb-4'} style={{backgroundColor: 'var(--white)'}}>
                <canvas id={'floor-map'} ref={canvas} style={{border: '1px solid ' + Constants.LIBERTY_DARK_GRAY}} />
            </div>
            <UserContext.Provider value={user.current}>
                <ReserveMenu hideReserveDropdown={hideReserveDropdown} resetErrorNotifications={resetErrorNotifications} selectedDates={selectedDates} selectedSeat={selectedSeat} selectedHours={selectedHours} currentOffice={currentOffice} userReservations={userReservations}
                             setUserReservations={setUserReservations} setSaveSuccess={setSaveSuccess} setCreatedReservation={setCreatedReservation} getReservations={getReservations} setError={setError} setErrorMessage={setErrorMessage} setCancelSuccess={setCancelSuccess} reserveDropdown={reserveDropdown}
                             atMaxCapacity={atMaxCapacity} selectedSeatAssignedUsers={selectedSeatAssignedUsers}/>
            </UserContext.Provider>
            <div id={'partial-available-image-container'} style={{display: 'none'}}><img id={'partial-available-image'} src={caution_image} alt={'Partial Availability Indicator'}/></div>
        </React.Fragment>
    );
}

export default ReserveSpace;