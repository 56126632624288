import axios from 'axios';
import MsalAuthToken from './MsalAuthToken';
const instance = axios.create();

async function get(url) {
    await assignDefaultHeader();
    return instance.get(url);
}

async function put(url, data, headers) {
    await assignDefaultHeader();
    return instance.put(url, data, headers);
}

async function post(url, data, headers) {
    await assignDefaultHeader();
    return instance.post(url, data, headers);
}

async function destroy(url, headers) {
    await assignDefaultHeader();
    return instance.delete(url, headers);
}

// Adds a valid access_token as an Authorization bearer token header to requests
async function assignDefaultHeader() {
    const appToken = await MsalAuthToken.getAppToken();
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + appToken;
}
const AxiosRequest = {
    get,
    post,
    put,
    destroy
};

export default AxiosRequest;