import React, {useEffect, useState, useContext} from 'react';
import './BehalfDrawer.css';
import Drawer from '@material-ui/core/Drawer';
import UserSearch from '../standard/UserSearch';
import {List, ListItem, ListItemText} from '@material-ui/core';
import UserContext from '../../context/UserContext';
import trash_logo from '../../images/trash_logo.png';
import caret_right from '../../images/caret_right.png';
import close_circle from '../../images/close_circle.png';
import GraphUtils from '../../utilities/GraphUtils';
import AxiosRequest from '../../utilities/AxiosRequest';

function BehalfDrawer({handleMinimize, isMinimized, isMultiSelect, selectedSeats, selectedHours, setError, setErrorMessage, toggleMultiSelect, userReservations, multiBehalfReservationList, setMultiBehalfReservationList, ...props}) {
    const [assignedUsers, setAssignedUsers] = useState({});
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const user = useContext(UserContext);

    const setOnBehalfOfReservations = (userId, displayName, spaceId) => {
        if (userId && userId.length > 0) {
            const newReservation = {
                employeeId: userId,
                spaceId: spaceId,
                reservationDateGroup: props.dates,
                reserverId: user.id,
                reservationStartHour: selectedHours[0],
                reservationEndHour: selectedHours[1]
            };
            props.updateReservedFor(spaceId, displayName);
            setMultiBehalfReservationList([...multiBehalfReservationList, newReservation]);
        } else {
            setMultiBehalfReservationList(multiBehalfReservationList.filter((reservation) => reservation.spaceId !== spaceId));
        }
    };

    const removeSelectedSeat = (seatId) => {
        setMultiBehalfReservationList(multiBehalfReservationList.filter((reservation) => reservation.spaceId !== seatId));
        props.removeSelectedSeat(seatId);
    };

    useEffect(() => {
        if (multiBehalfReservationList.length !== selectedSeats.length) {
            setSubmitButtonDisabled(true);
        } else {
            setSubmitButtonDisabled(false);
        }
    }, [multiBehalfReservationList.length, selectedSeats.length])

    useEffect(() => {
        const getAssignedUsers = async(seat) => {
            let assignedSeatUsers = await GraphUtils.getAssignedSeatUsers(seat.assignedUserIds);
            setAssignedUsers({...assignedUsers, [seat.id]: assignedSeatUsers});
        };

        for (const seat of selectedSeats) {
            if (seat.assignedUserIds && !assignedUsers[seat.id])  {
                getAssignedUsers(seat);
            }
        }
    }, [selectedSeats, assignedUsers]);

    const addMultiReservations = async(reservationList) => {
        props.resetErrorNotifications();
        try {
            let response = await AxiosRequest.post('/api/v1/reservations', reservationList);
            if (response.status === 201) {
                props.setSaveSuccess(true);

                let reservationErrors = [];
                selectedSeats.forEach(seat => {
                    let matchingReservation = response.data.find(reservation => reservation[0].spaceId === seat.id);
                    if (matchingReservation) {
                        if (matchingReservation[0].employeeId === user.employeeId) {
                            matchingReservation.forEach((reservation, index) => {
                                matchingReservation[index] = {...reservation, spaceName: seat?.nickname ? seat?.nickname : seat?.spaceNumber, officeName: props.currentOffice.current.displayName}
                            });
                            props.setUserReservations([...userReservations, ...matchingReservation]);
                        }
                        removeSelectedSeat(seat.id);
                    } else {
                        // reservation failed for that seat, add to error notification
                        reservationErrors = [...reservationErrors, {seat: seat.displayName, user: seat.reservedForName}];
                        removeSelectedSeat(seat.id);
                    }
                });

                props.multiSeatsRef.current = [];
                toggleMultiSelect();
                props.getReservations();
                if (reservationErrors.length > 0) {
                    let errorMessage = 'The following reservations failed to create: ';
                    reservationErrors.forEach((err, index, arr) => {
                        errorMessage = errorMessage + err.seat + ' for ' + err.user;
                        errorMessage = index === arr.length - 1 ? errorMessage : errorMessage + ', ';
                    });
                    setError(true);
                    setErrorMessage(errorMessage);
                }
            } else {
                setError(true);
                setErrorMessage('There was a problem creating reservations');
            }
        } catch (e) {
            setError(true);
            setErrorMessage(e.response.data.message || e.response.data.error);
        }
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        setSubmitButtonDisabled(true);
        await addMultiReservations(multiBehalfReservationList);
        setMultiBehalfReservationList([]);
    };

    return (
        <div className={'d-none d-md-block'}>
            <div className={'drawer-tab'} style={{display: isMultiSelect && isMinimized ? undefined : 'none'}} onClick={handleMinimize}>Multi-Select</div>
            <Drawer anchor={'right'} variant={'persistent'} open={isMultiSelect && !isMinimized} PaperProps={{style: {width: '450px', borderLeft: '2px solid rgba(0, 0, 0, 0.29)'}}}>
                <div className={'container h-100 d-flex p-0'}>
                    <div className={'row no-gutters'}>
                        <List className={'p-0 col-12'}>
                            <div className={'row no-gutters drawer-header'}>
                                <div className={'col p-1'}><button className={'drawer-control-minimize'} title={'Minimize Panel'} onClick={() => handleMinimize()}><img alt={'Minimize Panel'} src={caret_right}/><img alt={'Minimize Panel'} src={caret_right}/></button></div>
                                <div className={'col text-right p-1'}><button className={'drawer-control-close'} title={'Close Multi-select'} onClick={() => toggleMultiSelect()}><img alt={'Close Panel'} src={close_circle}/></button></div>
                            </div>
                            <div className={'row no-gutters drawer-header'}>
                                <h3 className={'text-center col-12'}>Multi-Select</h3>
                            </div>
                            <div className={'row no-gutters drawer-header'}>
                                {selectedSeats.length > 0 ? <span className={'pt-2 pb-3 text-center col-12'}>Selected <b>{selectedSeats.length}</b> of <b>{props.availableSpaceCount}</b> available seats</span>
                                    : <span className={'pt-2 pb-3 font-weight-bold text-center col-12'}>Click available seats on this map to add them to the list</span>}
                            </div>
                            <div className={'row no-gutters'}>
                                {selectedSeats?.map((seat, index) => (
                                    <ListItem key={seat.spaceNumber}>
                                        <div className={'col-3 p-1'}><ListItemText primary={seat.spaceNumber} /></div>
                                        {seat.assignedUserIds ?
                                            <div className={'col-8 p-1'}><select className={'multi-behalf-assigned m-0 mb-1 w-100 h-100'} name={'multiBehalfAssigned'} onChange={(val) => {
                                                let user = assignedUsers[seat.id].filter((assignedUser) => assignedUser.employeeId.toUpperCase() === val.target.value);
                                                setOnBehalfOfReservations(val.target.value, user[0]?.displayName, seat.id)
                                            }}>
                                                <option value={''}>Select an Assigned User</option>
                                                {assignedUsers[seat.id]?.length > 0 ?
                                                    assignedUsers[seat.id].map(
                                                        (user, index) => <option key={index} value={user.employeeId.toUpperCase()} >{user.displayName}</option>
                                                    )
                                                    : null}
                                            </select></div>
                                            : <div className={'col-8 p-1'}><UserSearch placeholderText={'Enter employee\'s last name or ID...'} setSearchResults={(selected) => setOnBehalfOfReservations(selected[0]?.employeeId?.toUpperCase(), selected[0]?.displayName, seat.id)} /></div> }
                                        <div className={'col-1 p-1'}><button className='btn logo-btn' title={'Remove seat selection'} onClick={() => removeSelectedSeat(seat.id)}><img alt={'Trash Logo'} src={trash_logo}/></button></div>
                                    </ListItem>
                                ))}
                            </div>
                            {selectedSeats.length > 0 ? <div className={'row no-gutters'}><div className={'col-12 text-center'}><input type={'button'} className={'btn btn--secondary mt-2 mb-1'} value={'Reserve'} onClick={handleSubmit} disabled={submitButtonDisabled} title={submitButtonDisabled ? 'A user must be selected for each seat prior to reserving' : undefined} /></div></div> : null }
                        </List>
                        <div className={'col align-self-end text-center font-italic py-2 drawer-footer'}>Click (>>) at the top left to minimize this panel, or (x) to close.</div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default BehalfDrawer;