import React, {useState} from 'react';
import MaterialTable from '@material-table/core';
import trash_logo from '../../images/trash_logo.png';
import AddGroupMember from './AddGroupMember';
import CheckmarkSVG from '../../images/CheckmarkSVG';
import caret_up_image from '../../images/caret_up.png';
import caret_down_image from '../../images/caret_down.png';
import red_close_circle from '../../images/red_close_circle.png';
import group_leader from '../../images/star.png';
import Toggle from '../standard/Toggle';

function Group({addGroupMember, removeGroup, removeGroupMember, group, owner, index, startCollapsed, user, userGroupAllOptIn, saveGroupOptIn}) {
    const [isGroupCollapsed, setIsGroupCollapsed] = useState(startCollapsed);
    const [saveSuccess, setSaveSuccess] = useState(false);

    return (
        <React.Fragment>
            { index === 0 && !owner ? null : <hr/> }
            <div className={'row py-2'}>
                <div className={'col-auto pt-1'} data-toggle={'collapse'} data-target={`#group-${group.groupId}`} onClick={() => setIsGroupCollapsed(!isGroupCollapsed)} title={'Expand/collapse this group'}>
                    <button className={'collapse-caret'}><img src={isGroupCollapsed ? caret_up_image : caret_down_image} alt={'collapse'} height={'14px'} className={'ml-1'}/></button>
                    <span className={(owner ? '' : 'mb-0') + ' h5 pl-2'} style={{cursor: 'pointer'}}>{group.groupName}</span>
                </div>
                <div className={'col-4 pl-0'}>
                    { owner ? <button className={'btn btn--secondary'} onClick={() => {if (window.confirm('Delete group?')) removeGroup(group.groupId)}}>Delete</button>
                        : null }
                </div>
            </div>
            <div id={`group-${group.groupId}`} className={'collapse pb-2' + (isGroupCollapsed ? '' : ' show')}>
                <div className='table table-responsive table-striped'>
                    <MaterialTable
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                backgroundColor: 'var(--liberty-teal)',
                                fontFamily: 'Guardian Sans, Roboto, Arial, sans-serif',
                                fontSize: '16px',
                                fontWeight: '700'
                            },
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 25, 50, 100],
                            paging: true,
                            search: true,
                            showTitle: false,
                            sorting: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: 'No members have been added to this group.'
                            },
                        }}
                        columns={[
                            {title: 'Name', field: 'memberName',
                                render: (member) => member.memberName === group.ownerName ? <span>{member.memberName}<img alt={'Group owner'} title={'Group owner'} src={group_leader} className={'ml-1 mb-1'} style={{height: '15px'}}/></span> : member.memberName},
                            {title: 'Opt-In Status', field: 'hasOptedIn', align: 'center',
                                render: (member) => <div className={'row no-gutters'}>
                                    <div className={'col-2 col-sm-3 px-0'}></div>
                                    {member.memberId === user.id ?
                                        <>
                                            <div className={'col-4 px-0'}>
                                                <Toggle label={''} defaultChecked={member.hasOptedIn} toggleValue={(checked) => {setSaveSuccess(false); saveGroupOptIn(checked, group, setSaveSuccess)}} isDisabled={userGroupAllOptIn} title={member.hasOptedIn ? 'Opted in' : 'Not opted in'}/>
                                            </div>
                                            <div className={'col-auto pl-1 pl-sm-0 pr-0'} id={'notifications'}>
                                                { saveSuccess ? <span className={'success-fade'}><CheckmarkSVG width={19} height={19} title={'Saved successfully'}/><span className={'success-message pl-1 small'}>Saved</span></span>
                                                    : null }
                                            </div>
                                        </>
                                         :
                                        member.hasOptedIn ? <div className={'col-4'}><CheckmarkSVG width={28} height={28} title={'Opted in'}/></div>
                                        : <div className={'col-4'}><img title={'Not opted in'} alt={'Not opted in'} src={red_close_circle} height={28} width={28}/></div> }
                                </div>
                            },
                            {title: 'Remove', sorting: false, searchable: false, align: 'center', hidden: !owner,
                                render: (member) => <button className={'btn logo-btn'} onClick={() => {if (window.confirm('Delete group member?')) removeGroupMember(group.groupId, member.memberId)}}><img alt={'Remove Group Member'} src={trash_logo}/></button>}
                        ]}
                        data={group && group.groupMembers ? group.groupMembers : []}
                        components={{
                            Toolbar: () => (
                                <div className={'row p-0 m-0 mt-1'}>
                                    { owner ? <div className={'col-12 p-0 m-0'}><AddGroupMember addGroupMember={addGroupMember} groupId={group.groupId}/></div> : null }
                                </div>
                            ),
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Group;