import React, {useCallback, useEffect, useRef, useState} from 'react';
import './BehalfDrawer.css';
import Calendar from 'react-calendar';
import AxiosRequest from '../../utilities/AxiosRequest';
import * as DateUtils from '../../utilities/DateUtils';
import close_circle from '../../images/close_circle.png';
import Notification from '../standard/Notification';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {createSpaceCalendarInvite} from '../../utilities/EmailCalendarUtils';

function QuickReserve({user, isMinimized, minimizeMethod, refreshReservationListMethod, userReservationDates, userHistory}) {
    let createdReservation = useRef();
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [notificationType, setNotificationType] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedSpace, setSelectedSpace] = useState(null);
    const [showNotification, setShowNotification] = useState(false);

    // Method used by calendar to disable a date
    const disableDates = ({date, view}) => {
        // Disable calendar until user selects a space
        if (!selectedSpace) {
            return true;
        }
        let reservedDates = userReservationDates;
        reservedDates = reservedDates.concat(selectedSpace.reservedDates);
        return reservedDates.find(d => {
            let reservedDate = DateUtils.parseDate(d);
            reservedDate.setHours(0, 0, 0, 0);
            return reservedDate.getTime() === date.getTime();
        });
    };

    const getNextSelectableDate = useCallback((reservedDates) => {
        let nextSelectedDate = selectedDate ? selectedDate : new Date();
        while (reservedDates?.find(d => d === DateUtils.getYMDDate(nextSelectedDate))) {
            const dayAfterSelection = nextSelectedDate.getDate() + 1;
            nextSelectedDate.setDate(dayAfterSelection);
        }
        if (nextSelectedDate >= DateUtils.getOfficeMaxDate(selectedSpace?.maxOfficeReservationDays)) {
            return null;
        }
        return nextSelectedDate;
    }, [selectedDate, selectedSpace?.maxOfficeReservationDays]);

    useEffect(() => {
        setSelectedDate(getNextSelectableDate(userReservationDates));
    }, [getNextSelectableDate, userReservationDates]);

    const selectSpace = (newSpace) => {
        setSelectedSpace(newSpace);
        setSelectedDate(getNextSelectableDate(newSpace.reservedDates));
    };

    const addReservation = async () => {
        const reservationDate = DateUtils.getYMDDate(selectedDate);
        const newReservation = [{
            employeeId: user.employeeId,
            spaceId: selectedSpace.spaceId,
            reservationDateGroup: [reservationDate, reservationDate],
            reserverId: user.id,
            reservationStartHour: 7,
            reservationEndHour: 17
        }];
        try {
            let response = await AxiosRequest.post('/api/v1/reservations', newReservation);
            if (response.status === 201) {
                // Refresh reservations for selected space
                const refreshedSelectedSpace = selectedSpace;
                refreshedSelectedSpace.reservedDates.push(reservationDate);
                setSelectedSpace(refreshedSelectedSpace);
                // Save success message
                createdReservation.current = {...response.data[0][0], spaceName: selectedSpace.nickname ? selectedSpace.nickname : selectedSpace.spaceNumber, officeName: selectedSpace.officeName};
                setNotificationMessage(`Workspace ${selectedSpace.nickname ? selectedSpace.nickname : selectedSpace.spaceNumber} successfully reserved for ${DateUtils.getLocaleDateFromString(reservationDate)}`);
                setNotificationType('success');
                setShowNotification(true);
                // Refresh reservations on homepage
                refreshReservationListMethod();
            } else {
                setNotificationMessage(response.data.message || response.data.error);
                setNotificationType('error');
                setShowNotification(true);
            }
        } catch (error) {
            setNotificationMessage(error.response.data.message || error.response.data.error || error.message);
            setNotificationType('error');
            setShowNotification(true);
        }
    };

    return (
        <div id={'quick-reserve'}>
            <Notification id={'quick-reserve-notification'} notificationType={notificationType} dismissable={true} dismissText={'Click to close'} active={showNotification} handleDismiss={() => {setShowNotification(false)}}>{notificationMessage}{notificationType === 'success' ? (<><br/><Link to={'/reservation-dashboard'}>View Upcoming Reservations</Link><br/><span className={'link'} style={{fontSize: '16px'}} onClick={() => createSpaceCalendarInvite(createdReservation.current, selectedSpace.floorName)}>Download Calendar Invite</span></>) : null}</Notification>
            <Modal id={'quick-reserve-modal'} show={!isMinimized} centered={true} backdrop={true} fullscreen={'md-down'} onHide={() => {setSelectedDate(null); setSelectedSpace(null); minimizeMethod();}}>
                <div className={'d-flex p-0 border-fix'}>
                    <div className={'row no-gutters border-fix'}>
                        <div className={'col-12 border-fix'}>
                            <div className={'row no-gutters border-fix'} style={{backgroundColor: 'var(--liberty-atmospheric-white)'}}>
                                <div className={'col'}>
                                    <div className={'row no-gutters'}>
                                        <div className={'col-1'}>&nbsp;</div>
                                        <h3 className={'col mt-2 text-center'}>Quick Reserve</h3>
                                        <div className={'col-1 pr-1 text-right'}>
                                            <button className={'drawer-control-close'} title={'Minimize Panel'} onClick={() => minimizeMethod()}>
                                                <img alt={'Minimize Panel'} src={close_circle}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={'row no-gutters'}>
                                        <div className={'col font-weight-bold text-center'}>Reserve a space from your recent history for a single day</div>
                                    </div>
                                    <div className={'row no-gutters'}>
                                        <div className={'col pt-2 pb-3 small text-center'}>Unavailable future dates already have a reservation</div>
                                    </div>
                                </div>
                            </div>
                            <div className={'row no-gutters'}>
                                <div className={'col p-3'}>
                                    <select id={'recent-spaces'} className={'mx-0 w-100'}
                                            onChange={(e) => selectSpace(userHistory.find(s => s.spaceId === parseInt(e.target.value)))}>
                                        {!selectedSpace ? <option value={''}>Select a space</option> : null}
                                        {userHistory?.map((space, index) => (
                                            <option key={index} value={space.spaceId}>
                                                {space.nickname ? space.nickname : space.spaceNumber} ({space.officeName},
                                                Floor: {space.floorName})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className={'row no-gutters'}>
                                <div className={'col px-3'}>
                                    <Calendar calendarType={'US'}
                                              className={'w-100'}
                                              minDate={new Date()}
                                              maxDate={DateUtils.getOfficeMaxDate(selectedSpace?.maxOfficeReservationDays)}
                                              nextLabel={''}
                                              next2Label={null}
                                              onChange={setSelectedDate}
                                              onClickDay={setSelectedDate}
                                              prevLabel={''}
                                              prev2Label={null}
                                              selectRange={false}
                                              tileDisabled={disableDates}
                                              value={selectedDate}
                                    />
                                </div>
                            </div>
                            <div className={'row no-gutters p-3'}>
                                <div className={'col pr-2 text-right'}>
                                    <button className={'btn btn--primary w-75'} onClick={() => addReservation()} disabled={!selectedDate || !selectedSpace} title={!selectedDate || !selectedSpace ? 'Please select a date and space' : 'Click to reserve space for date'}>Reserve
                                    </button>
                                </div>
                                <div className={'col pl-2'}>
                                    <button className={'btn btn--secondary w-75'} onClick={() => {setSelectedDate(null); setSelectedSpace(null); minimizeMethod();}} title={'Close dialog'}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default QuickReserve;
