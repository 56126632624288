import * as DateUtils from './DateUtils';

/**
 * Returns a reservation list with reservations that overlap with the given hours
 * @param reservationList List of reservations for a day
 * @param selectedHours Hours to determine overlap for
 * @returns {*[]} List of overlapping reservations
 */
function getOverlappingReservations(reservationList, selectedHours) {
    let filteredReservations = [];
    let filteredDayReservations = reservationList.filter(r => {
        // whole day reservations
        if (r.reservationStartHour === 7 && r.reservationEndHour === 17) {
            return true;
        }
        // whole reservation is within selected span
        if (r.reservationStartHour >= selectedHours[0] && r.reservationEndHour <= selectedHours[1]) {
            return true;
        }
        // reservation starts before selected start and ends before selected end but after selected start
        if (r.reservationStartHour < selectedHours[0] && r.reservationEndHour <= selectedHours[1] && r.reservationEndHour > selectedHours[0]) {
            return true;
        }
        // reservation ends after selected end and starts after selected start but before selected end
        if (r.reservationEndHour > selectedHours[1] && r.reservationStartHour >= selectedHours[0] && r.reservationStartHour < selectedHours[1]) {
            return true;
        }
        // reservation start is between selected span
        if (r.reservationStartHour > selectedHours[0] && r.reservationStartHour < selectedHours[1]) {
            return true;
        }
        // reservation end is between selected span
        if (r.reservationEndHour < selectedHours[1] && r.reservationEndHour > selectedHours[0]) {
            return true;
        }
        // whole selected span is within reservation span
        if (selectedHours[0] >= r.reservationStartHour && selectedHours[1] <= r.reservationEndHour) {
            return true;
        }
        return false;
    });
    filteredReservations = filteredReservations.concat(filteredDayReservations);
    return filteredReservations;
}

/**
 * Returns a reservation list with reservations that overlap with the given hours
 * Calls getOverlappingReservations for each day between start and end dates and concats into one array
 * @param reservationList List of reservations for provided span of days
 * @param startDate Start date for reservations
 * @param endDate End date for reservations
 * @param selectedHours Hours to determine overlap for
 * @returns {*[]} List of overlapping reservations
 */
function getOverlappingReservationsForSpan(reservationList, startDate, endDate, selectedHours) {
    let daysToReserve = [];
    for (let d = new Date(startDate.valueOf()); d <= endDate; d.setDate(d.getDate() + 1)) {
        daysToReserve.push(DateUtils.getYMDDate(d));
    }
    let filteredReservations = []
    for (let i = 0; i < daysToReserve.length; i++) {
        let dayReservations = reservationList.filter(r => r.reservationDate === daysToReserve[i]);
        filteredReservations = filteredReservations.concat(getOverlappingReservations(dayReservations, selectedHours));
    }
    return filteredReservations;
}

export {
    getOverlappingReservations,
    getOverlappingReservationsForSpan
}