import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './ReserveCalendar.css';
import ReservationList from './ReservationList';
import UserContext from '../../context/UserContext';
import Notification from '../standard/Notification';
import * as DateUtils from '../../utilities/DateUtils';
import ReservationHours from './ReservationHours';
import {getOverlappingReservationsForSpan} from '../../utilities/ReservationUtils';

function ReserveCalendar(props) {
    const [cancel, setCancel] = useState(false);
    const currentOffice = useRef(props.location.state.office);
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedDates, setSelectedDates] = useState(props.location.state.selectedDates);
    const [selectedHours, setSelectedHours] = useState(props.location.state.selectedHours || [7, 17]);
    const [userReservations, setUserReservations] = useState(props.location.state.userReservations);

    const cancelReserve = () => {
        setCancel(true);
    };

    const existingReservationNotification = (hasExistingReservation) => {
        if (hasExistingReservation) {
            setError(true);
            setErrorMessage('You have existing reservation(s) in the selected date range. Please cancel your current reservation(s) below before creating a new one.');
        }
    }

    function updateReservations(reservations) {
        setUserReservations(reservations);
    }

    const selectDate = (value) => {
        resetNotifications();
        let hasExistingReservation = false;
        if (Array.isArray(value)) {
            value[1].setHours(0,0,0,0);
            setSelectedDates(value);
            hasExistingReservation = getOverlappingReservationsForSpan(userReservations, value[0], value[1], selectedHours).length !== 0;
        } else {
            setSelectedDates([value, value]);
            hasExistingReservation = getOverlappingReservationsForSpan(userReservations, value, value, selectedHours).length !== 0;
        }
        setDisabled(false);
        existingReservationNotification(hasExistingReservation);
    };

    const resetNotifications = () => {
        setError(false);
        setErrorMessage('');
    }

    const updateHours = useCallback((hours) => {
        resetNotifications();
        setSelectedHours(hours);
        if (selectedDates?.length) {
            existingReservationNotification(getOverlappingReservationsForSpan(userReservations, selectedDates[0], selectedDates[1], hours).length !== 0);
        }
    }, [selectedDates, userReservations]);


    useEffect(() => {
        if (selectedDates?.length) setDisabled(false);
    }, [selectedDates]);

    return (
        cancel ?
            <Redirect to={'/'}/>
            :
            <div id={'reserve-calendar'} className={'mx-auto'}>
                <Notification id={'error-notification'} notificationType={'info'} dismissable={true} dismissText={'Click to close'} active={error} handleDismiss={resetNotifications}>{errorMessage}<Link to={'/'}> View Existing Reservations</Link></Notification>
                <div className={'greeting'}>
                    <Link to={'/'}>Home</Link>
                    &nbsp;&gt;&nbsp;
                    {currentOffice.current.nickname ? currentOffice.current.nickname : currentOffice.current.officeName} ({currentOffice.current.officeNumber})
                </div>
                <section>
                    <h3>Liberty Mutual Reservations</h3>
                    <span className={'lead'}>Select the day(s) that you need a workspace</span>
                    <Calendar calendarType={'US'}
                              className={'my-3 w-100'}
                              minDate={new Date()}
                              maxDate={DateUtils.getMaxDate(currentOffice.current)}
                              nextLabel={''}
                              next2Label={null}
                              onChange={selectDate}
                              onClickDay={selectDate}
                              prevLabel={''}
                              prev2Label={null}
                              selectRange={true}
                              value={selectedDates}
                    />
                    <div className={'text-center pb-3'}>
                        <ReservationHours hours={selectedHours} changeHours={updateHours}/>
                    </div>
                    <div id={'reserve-actions'} className={'container-fluid px-0'}>
                        <div className={'row no-gutters mb-2 justify-content-center'}>
                            <div className={'col-sm-3 pr-1'}>
                                <Link to={{pathname: '/reserve-floors', state: {office: currentOffice.current, selectedDates: selectedDates, userReservations: userReservations, maxDate: DateUtils.getYMDDate(DateUtils.getMaxDate(currentOffice.current)), selectedHours: selectedHours}}}>
                                    <button id={'next-reserve-button'} className={'btn btn--primary w-100'} disabled={disabled}>Next</button>
                                </Link>
                            </div>
                            <div className={'col-sm-3 pl-1'}>
                                <input type={'button'} id={'cancel-reserve-button'} className={'btn btn--secondary w-100'} value={'Cancel'} onClick={cancelReserve}/>
                            </div>
                        </div>
                        <div className={'row no-gutters justify-content-end d-sm-none'}>
                            <div className={'col-2'}>
                                <button id={'cancel-reserve-link'} onClick={cancelReserve}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={'section-divider'}></div>
                <section>
                    <UserContext.Consumer>
                        {(user) => (
                            <ReservationList user={user} reservations={userReservations} onChange={updateReservations} listTitle={'Upcoming Reservations'}/>
                        )}
                    </UserContext.Consumer>
                </section>
                <div className={'section-divider'}></div>
            </div>
    );
}

export default ReserveCalendar;